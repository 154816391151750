import { DefaultAPIInstance } from "@/api";

export const pageDataAPI = {
  async get(options) {
    const { url='/', params={ date: null, from: null, to: null, countryId: null, branchId: null }, token=null } = options

    try {
      if (!token) {
        throw new Error('token had not been passed')
      }
  
      const response = await DefaultAPIInstance.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          date: params.date,
          from: params.from,
          to: params.to,
          country: params.countryId,
          branch: params.branchId,
        },
      });

      const data = response.data
      return data

    } catch (error) {
      if (error.response.data.message === "Unauthenticated." && error.response.status > 399 && error.response.status < 404) {
        throw new Error('Unauthenticated')
      }
      else {
        console.error(error)
        return error.response.data
      }

    }
  }
}