<template>
    <div v-if="inversion" :title="tooltip" :class="['increase', 'inversion', {'increase_active': success}]">
        <span 
            :class="['increase__value', {'increase__value_active': success}]">{{ value }}%</span>
        <svg 
            class="increase__arrow up"
            v-if="success" width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 0.233643L0 4.73364H9L4.5 0.233643Z"/>
        </svg>
        <svg
            class="increase__arrow down" 
            v-else width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 4.73361L9 0.233606L0 0.233606L4.5 4.73361Z"/>
        </svg>
    </div>
    <div v-else :title="tooltip" :class="['increase', {'increase_active': success}]">
        <span 
            :class="['increase__value', {'increase__value_active': success}]">{{ value }}%</span>
        <svg 
            class="increase__arrow up"
            v-if="success" width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 0.233643L0 4.73364H9L4.5 0.233643Z"/>
        </svg>
        <svg
            class="increase__arrow down" 
            v-else width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 4.73361L9 0.233606L0 0.233606L4.5 4.73361Z"/>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'increase-value',
    props: ['success', 'value', 'tooltip', 'inversion']
}
</script>

<style lang="scss" scoped>
.increase {
    width: min-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-family: 'Mail Sans Roman';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 100%;
    padding: 5px 5px;
    border-radius: 3px;
    transition: .3s all;
    background: #FBEFF0;
    &_active {
        background: #EAF9F4;
        transition: .3s all;
    }
    &__value {
        color: #FF325C;
        transition: .3s all;
        &_active {
            color: #24DB61;
            transition: .3s all;
        }
    }
    &__arrow {
        transition: .3s all;
        & path {
            transition: .3s all;
        }
    }
}

.increase__arrow {
    &.up {
        & path {
            fill: #24DB61;
        }
    }
    &.down {
        & path {
            fill: #FF325C;
        }
    }
}

.increase.inversion {
    background: #EAF9F4;

    &.increase_active {
        background: #FBEFF0;
    }

    & .increase__value {
        color: #24DB61;
        &_active {
            color: #FF325C;
        }
    }

    & .increase__arrow {
        &.up {
            & path {
                fill: #FF325C;
            }
        }
        &.down {
            & path {
                fill: #24DB61;
            }
        }
    }
}

.dark-mode {
    .increase {
        background: rgba(255, 50, 92, .5);
        &_active {
            background: rgba(36, 219, 97, 0.5);
        }
        & .increase__value {
            color: #fff;
            &_active {
                color: #fff;
            }
        }
        &__arrow {
            & path {
                fill: #fff;
            }
        }
    }

    .increase.inversion {
        background: rgba(36, 219, 97, 0.5);

        &.increase_active {
            background: rgba(255, 50, 92, .5);
        }

        & .increase__arrow {
            & path {
                fill: #fff;
            }
        }
    }
}
</style>