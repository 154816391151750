export default {
  state: () => ({
    darkMode: Number(localStorage.getItem('dark-mode')) ?? 0,
  }),

  getters: {
    getDarkMode: (state) => state.darkMode,
  },

  mutations: {
    setDarkMode(state, payload) {
      localStorage.setItem('dark-mode', payload)
      state.darkMode = payload
    },
  },
}