<template>
  <div :class="[ 'my-date-picker', theme, { custom: isCustomDate } ]" @click.capture="handleClick">
    <!-- :open="isOpen" -->
    <date-picker 
      v-model="time" 
      :editable="false"
      :lang="lang"
      range
      :format="'DD.MM.YY'"
      :clearable="false"
      @change="handleChange"
      @confirm="handleConfirm"
      @clear="handleClear"
      @focus="handleFocus"
      :popup-class="theme"
    >
      <template v-slot:icon-calendar>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.3335 5.49992H5.50016V4.58325H3.66683V7.33325H18.3335V4.58325H16.5002V5.49992H14.6668V4.58325H7.3335V5.49992ZM18.3335 9.16658H3.66683V18.3333H18.3335V9.16658ZM14.6668 2.74992H7.3335V1.83325H5.50016V2.74992H3.66683C2.65431 2.74992 1.8335 3.57073 1.8335 4.58325V18.3333C1.8335 19.3458 2.65431 20.1666 3.66683 20.1666H18.3335C19.346 20.1666 20.1668 19.3458 20.1668 18.3333V4.58325C20.1668 3.57073 19.346 2.74992 18.3335 2.74992H16.5002V1.83325H14.6668V2.74992ZM6.41683 12.8333V10.9999H8.25016V12.8333H6.41683ZM10.0835 12.8333H11.9168V10.9999H10.0835V12.8333ZM15.5835 12.8333H13.7502V10.9999H15.5835V12.8333ZM6.41683 14.6666V16.4999H8.25016V14.6666H6.41683ZM11.9168 16.4999H10.0835V14.6666H11.9168V16.4999Z" fill="#E83D46"/>
        </svg>
      </template>
      <template v-slot:icon-clear>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.2929L10 13.5858L7.70713 11.2929L6.29291 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.2929Z" fill="#E83D46"/>
        </svg>
      </template>
    </date-picker>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import formatDate from "@/assets/script/formatDate";

export default {
  name: 'my-date-picker',
  props: ['time'],
  components: { DatePicker },
  data() {
    return {
      isOpen: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
          weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        },
        monthBeforeYear: false,
      },
    }
  },
  computed: {
    theme() {
      const darkMode = this.$store.getters['getDarkMode']
      return darkMode ? 'dark' : 'light'
    },
    isCustomDate() {
      return !!this.$store.getters['getCurrentSelector']
    }
  },
  methods: {
    async handleChange(dates) {
      console.log('handleChange')
      const { fromFormatted, toFormatted } = formatDate(dates)
      const pageAPInames = this.$store.getters['getPageAPInames']
      const url = pageAPInames[this.$route.name]
      this.$store.commit('setGlobalFilter', null)
      this.$store.commit('setSelector', 1)
      this.$store.commit('setTimeRange', [new Date(fromFormatted), new Date(toFormatted)])

      await this.$store.dispatch('fetchMainData', { url: '/page/' + url, from: fromFormatted, to: toFormatted })
    },
    handleConfirm() {
      // console.log('handleConfirm')
    },
    handleClear(e) {
      // console.log('handleClear')
    },
    handleFocus(e) {
      // console.log('handleFocus')
    },
    handleClick(e) {
      // console.log('handleClick')
      // console.log(e)
    },
  }
}
</script>
<style lang='scss' scoped>

.my-date-picker.custom {
  
  &:deep() {
    & .mx-input-wrapper {
      background-color: #F9A620;
    }
    & .mx-input {
      background-color: #F9A620;
      color: #FFFFFF;
    }

    & svg path {
      fill: #FFFFFF;
    }
  }
}

.my-date-picker.custom.dark {
  
  &:deep() {
    & .mx-input-wrapper {
      background-color: #F9A620;
    }
    & .mx-input {
      background-color: #F9A620;
      color: #FFFFFF;
    }

    & svg path {
      fill: #FFFFFF;
    }
  }
}

.my-date-picker {
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.07));
  max-width: 320px;
  width: 320px;

  @media (max-width: 743px) {
    width: 50px;
    height: 50px;
  }

  &:deep() {
    & .mx-datepicker-range {
      width: 100%;
    }
    & .mx-input-wrapper {
      height: 50px;
      display: flex;
      background: #fff;
      border-radius: 10px;
      justify-content: space-between;
    }

    & .mx-input {
      font-family: 'Mail Sans Roman';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 155%;
      color: #262424;
      height: 100%;
      max-width: 250px;
      border: none;
      border-radius: inherit;
      cursor: pointer;

      @media (max-width: 743px) {
        display: none;
      }
    }
  }
}

:global(.mx-icon-calendar) {
  right: 15px;
  font-size: 18px;
  @media (max-width: 743px) {
    right: unset;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
:global(.mx-icon-clear) {
  right: 15px;
  font-size: 18px;
}

:global(.mx-datepicker-main) {
    font: 14px/1.5 "Mail Sans Roman","Helvetica Neue",Helvetica,Arial,"Microsoft Yahei",sans-serif;
    color: #000000;
    transition: 0.3s background-color;
    background-color: #fff;
    border: 1px solid #e8e8e8;
}

:global(.mx-btn) {
  color: #000000;
}
:global(.mx-btn.mx-btn-text.mx-btn-icon-double-left:hover) {
  color: rgba(200, 62, 71, 0.8);
  border-color: rgba(200, 62, 71, 0.8);
}
:global(.mx-btn.mx-btn-text.mx-btn-icon-left:hover) {
  color: rgba(200, 62, 71, 0.8);
  border-color: rgba(200, 62, 71, 0.8);
}
:global(.mx-btn.mx-btn-text.mx-btn-icon-double-right:hover) {
  color: rgba(200, 62, 71, 0.8);
  border-color: rgba(200, 62, 71, 0.8);
}
:global(.mx-btn.mx-btn-text.mx-btn-icon-right:hover) {
  color: rgba(200, 62, 71, 0.8);
  border-color: rgba(200, 62, 71, 0.8);
}
:global(.mx-btn.mx-btn-text.mx-btn-current-month:hover) {
  color: rgba(200, 62, 71, 0.8);
}
:global(.mx-btn.mx-btn-text.mx-btn-current-year:hover) {
  color: rgba(200, 62, 71, 0.8);
}
:global(.mx-table.mx-table-date thead th) {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #A7A7A7;
  // display: flex;
  // align-items: center;
  // text-align: center;
}
:global(.mx-datepicker-popup) {
  border-radius: 10px;
  margin-top: 5px;
}
:global(.mx-datepicker-main.mx-datepicker-popup .cell:hover) {
  transition: 0.3s background-color;
  background-color: transparent;
  border: 1px solid #C83E47;
  border-radius: 100%;
}
:global(.mx-datepicker-main.mx-datepicker-popup .cell.today) {
  color: unset;
  font-weight: 700;
}
:global(.mx-datepicker-main.mx-datepicker-popup .cell.active) {
  background: #C83E47;
  color: #fff;
  border-radius: 100%;
  border: 1px solid #C83E47;
  margin: 0;
  font-weight: 500;
}
:global(.mx-table .mx-table-date .cell.active:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:global(.mx-table .mx-table-date .cell.active:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:global(.mx-datepicker-main.mx-datepicker-popup .cell.hover-in-range) {
  margin: 0;
  background: rgba(200, 62, 71, 0.3);
  color: #fff;
  border-radius: 0%;
  border: none;
  font-weight: 500;
}
:global(.mx-datepicker-main.mx-datepicker-popup .cell.in-range) {
  margin: 0;
  background: rgba(200, 62, 71, 0.3);
  color: #fff;
  border-radius: 0%;
  border: none;
  font-weight: 500;
}

.my-date-picker.dark {
  &:deep() {
    & .mx-input-wrapper {
      transition: 0.3s background-color;
      background-color: #262424;
    }
    & .mx-input {
      transition: 0.3s background-color;
      color: #ffffff;
      background-color: #262424;
    }
  }
}

:global(.mx-datepicker-popup.dark) {
  transition: 0.3s background-color;
  background-color: #262424;
  border: 1px solid #262424;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  color: #ffffff;
}
:global(.mx-datepicker-popup.dark .mx-btn) {
  color: #ffffff;
}
</style>