<template>
    <div class="page-titles">
        <app-title :title="title" level="1"></app-title>
        <div class="page-titles__filter">
            <img src="@/assets/icons/pages/time-history.svg" alt="Time">
            <span>{{ date ? date : filter }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-titles',
    props: ['title', 'date'],
    computed: {
        filter() {
            return this.$store.getters.getGlobalFilter
        }
    }
}
</script>

<style lang="scss" scoped>
.page-titles {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 40px;
    @media (max-width: 1350px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
    }
    & h1 {
        line-height: 1;
    }
    &__filter {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 155%;
        color: #626262;
        display: flex;
        gap: 10px;
        align-items: center;
    }
}
</style>