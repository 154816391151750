<template>
  <div :class="['chart', { dark: theme === 'dark' }]">
    <div class="chart__wrapper" v-if="!isMobile">
      <div class="side-block">
        <div class="block" v-for="(item, i) of data" :class="{ last: i + 1 === data.length }">
          <p class="block__filial">{{ item.name }}</p>
          <div class="block__fact">
            <div :style="{ background: legendColors[2] }" class="legend__color"></div>
            <p class="block__fact-text">{{ sumFormatted(item.fact.value) }}</p>
          </div>
          <div class="block__plan">
            <div :style="{ background: legendColors[0] }" class="legend__color"></div>
            <p class="block__plan-text">{{ sumFormatted(item.plan.value) }}</p>
          </div>
        </div>
        <div class="legend">
          <div class="legend__item">
            <div :style="{ background: legendColors[2] }" class="legend__color"></div>
            <span>- факт</span>
          </div>
          <div class="legend__item">
            <div :style="{ background: legendColors[0] }" class="legend__color"></div>
            <span>- план</span>
          </div>
        </div>
      </div>
      <div class="chart__chart" ref="chart-chart">
        <div class="canvas__wrapper" ref="canvas-wrapper">
          <canvas class="canvas" ref="canvas"></canvas>
          <div class="chart__bars" v-for="(item, index) of data">
            <div class="chart__bar"
              :style="{
                background: `linear-gradient(90deg, ${barColors[0][0]} 0%, ${barColors[0][1]} 50.52%)`,
                width: calcWidth(item.plan.value) + '%'
              }"
            >
              <span class="chart__bar-percent">100%</span>
            </div>
            <div class="chart__bar" v-show="item.plan.value > 0"
              :style="{
                background: `linear-gradient(90deg, ${barColors[1][0]} 1.54%, ${barColors[1][1]} 96.34%)`,
                width: calcWidth(item.fact.value) + '%'
              }"
            >
              <span class="chart__bar-percent">{{ factPercent(index) }}</span>
            </div>
          </div>
          <div class="chart__notification" v-if="isEmptyData">
            <p class="notification">Нет данных</p>
          </div>
        </div>
        <div class="axis-x" ref="horizontalBar" v-if="formattedValues">
          <span class="axis-x__item" v-for="dig of formattedValues">{{ dig }}</span>
        </div>
      </div>
    </div>
    <BarChartMobile
      v-else
      :data="data"
      :legendColors="legendColors"
      :barColors="barColors"
      :counter="counter"
      :isEmpty="isEmptyData"
      :theme="theme"
    />
  </div>
</template>
<script>
import BarChartMobile from './BarChartMobile.vue';
import spacesInDigit from '@/assets/script/spacesInDigit';
export default {
  name: 'BarChart',
  components: { BarChartMobile },
  props: {
    data: { type: Array, requried: false, default: null },
    counter: { type: String, requried: false, default: '' },
    theme: {
      type: String,
      required: false,
      default: 'light',
      validator: function (value) {
        return ['light', 'dark'].includes(value)
      }
    },
  },
  data() {
    return {
      legendColors: ['#E83D46', '#DADADA', '#F9A620', '#005FA7', '#FD2D91', '#00BFB9'],
      barColors: [
        ['#9D121A', '#E83C46'],
        ['#DD9622', '#F9A620'],

      ],
      isInit: false,
      canvas: {
        canvas: null,
        ctx: null,
      },
      isMobile: false,
    }
  },
  watch: {
    theme() {
      this.initCanvas()
    },
  },
  mounted() {
    this.isInit = true
    this.initCanvas()
    this.checkResolution()
    window.addEventListener('resize', this.checkResolution)
    window.addEventListener('resize', this.initCanvas)
  },
  destroyed() {
    window.removeEventListener('resize', this.checkResolution)
    window.removeEventListener('resize', this.initCanvas)
  },
  computed: {
    isEmptyData() {
      return this.data === null || this.data.length === 0
    },
    maxAxisValue() {
      if (this.isEmptyData) {
        return null
      }
      else {
        const allValues = []
        this.data.forEach(item => {
          allValues.push(item.fact.value)
          allValues.push(item.plan.value)
        })
        const maxValue = Math.max(...allValues)
        return maxValue
      }
    },
    maxAxisValueRounded() {
      return this.roundUp(this.maxAxisValue, 5)
    },
    formattedValues() {
      const maxValue = this.maxAxisValue

      if (maxValue !== null) {
        let axisValues = this.getAdjustedRange(maxValue, 6)
        if (String(Math.ceil(maxValue)).length > 4) {
          axisValues = axisValues.map(value => this.replaceThousands(value))
        }
        return axisValues
      }
      else {
        return null
      }

    },
  },
  methods: {
    spacesInDigit,
    sumFormatted(sum) {
      if (!sum) return 'Нет данных'

      if (this.counter === '$') {
          return `${this.counter} ` + spacesInDigit(sum)
      }
      else {
          return spacesInDigit(sum) + ` ${this.counter}`
      }
    },
    factPercent(index) {
      const fact = this.data[index].fact.value
      const plan = this.data[index].plan.value

      if (!plan) return 'Нет данных'

      const percent = (fact * 100) / plan

      if (fact > plan) {
        const calcPercent = percent - 100
        return '+' + calcPercent.toFixed(2) + '%'
      }
      else {
        const calcPercent = 100 - percent
        return '-' + calcPercent.toFixed(2) + '%'
      }
    },
    checkResolution() {
      innerWidth < 1350 ? this.isMobile = true : this.isMobile = false
    },
    initCanvas() {
      const canvas = this.$refs.canvas
      this.canvas.canvas = canvas
      this.canvas.ctx = canvas.getContext('2d')
      this.setCanvasDimensions(this.canvas.canvas)
      this.drawVerticalLines()
      this.drawHorizontalLines()
    },
    setCanvasDimensions(canvas) {
      const canvasStyle = getComputedStyle(canvas)
      const DPI_WIDTH = parseFloat(canvasStyle.width) * 2
      const DPI_HEIGHT = parseFloat(canvasStyle.height) * 2
      canvas.width = DPI_WIDTH
      canvas.height = DPI_HEIGHT
      this.canvas.DPI_WIDTH = DPI_WIDTH
      this.canvas.DPI_HEIGHT = DPI_HEIGHT
    },
    drawVerticalLines() {
      const axisX = this.getCoordsAxisX()
      const ctx = this.canvas.ctx

      for (let i = 0; i < axisX.length; i++) {
        const startY = this.canvas.DPI_HEIGHT
        const endY = startY - this.canvas.DPI_HEIGHT
        ctx.lineWidth = 2
        ctx.strokeStyle = this.theme === 'light' ? '#EDEDED' : '#575757'
        ctx.setLineDash([14, 14])
        ctx.moveTo(axisX[i] * 2, startY)
        ctx.lineTo(axisX[i] * 2, endY)
        ctx.stroke()
      }

      ctx.closePath()
    },
    drawHorizontalLines() {
      const ctx = this.canvas.ctx
      const chartBar = document.querySelector('.chart__bars')
      if (!chartBar) return null
      const barHeight = parseFloat(getComputedStyle(chartBar).height)
      const barMargin = parseFloat(getComputedStyle(chartBar).marginBottom)
      let prevLinePos = 0

      for (let i = 1; i < this.data.length; i++) {
        let y = 0

        if (i === 0) {
          y = barHeight * 2
        }
        else {
          y = barHeight * i * 2 + barMargin * (i - 1) * 2
        }

        ctx.lineWidth = 2
        ctx.strokeStyle = this.theme === 'light' ? '#EDEDED' : '#575757'
        ctx.setLineDash([14, 14])
        ctx.moveTo(0, y)
        ctx.lineTo(this.canvas.DPI_WIDTH, y)
        ctx.stroke()
      }
      ctx.closePath()
    },
    getCoordsAxisX() {
      const axisX = []
      const horizontalBar = this.$refs.horizontalBar
      if (!horizontalBar) return 0
      const horizontalBarItemsWidth = this.horizontalBarItemsWidth()
      const horizontalBarChildren = horizontalBar.children
      const wrapper = this.$refs['canvas-wrapper']
      const wrapperCoords = wrapper.getBoundingClientRect()

      for (let i = 0; i < horizontalBarChildren.length; i++) {
        const itemLeft = horizontalBarChildren[i].getBoundingClientRect().left
        const itemWidth = horizontalBarItemsWidth[i]
        const coordX = (itemLeft + itemWidth / 2) - wrapperCoords.left
        axisX.push(coordX)
      }

      return axisX
    },
    horizontalBarItemsWidth() {
      const horizontalBar = this.$refs.horizontalBar
      if (horizontalBar === null || horizontalBar === undefined) {
        return ['no data', 'no data','no data','no data',]
      }
      const isHorizontalBarHidden = getComputedStyle(horizontalBar).display === 'none'

      // TODO: поменять if
      if (isHorizontalBarHidden) {
        const horizontalBarLength = horizontalBar.children.length
        const canvasWidth = parseFloat(getComputedStyle(this.$refs.canvas).width)
        const itemWidth = canvasWidth / horizontalBarLength
        return Array(horizontalBarLength).fill(itemWidth, 0, horizontalBarLength)
      }
      else {
        return Array.from(horizontalBar.children).map(item => {
          return parseFloat(getComputedStyle(item).width).toFixed(2)
        })
      }
    },
    calcWidth(value) {
      return (value / this.maxAxisValueRounded * 100).toFixed(2)
    },
    getAdjustedRange(maxValue, div) {
      const step = maxValue / div
      const arr = []

      for (let i = 1; i <= div; i++) {
        const value = this.roundUp(step * i, 5)
        arr.push(value)
      }

      return [0, ...arr]
    },
    roundUp(number, precision) {
      const numberLength = String(Math.ceil(number)).length
      let zeroLength = 0
      let precisionFormatted = precision

      if (numberLength > 3) {
        zeroLength = numberLength - 2
        precisionFormatted += Array(zeroLength).fill(0).join('')
      }
      else {
        zeroLength = numberLength - 1
        precisionFormatted += Array(zeroLength).fill(0).join('')
      }

      return Math.ceil(number / precisionFormatted) * precisionFormatted
    },
    replaceThousands(number) {
      if (isNaN(number)) throw new Error(number + " is not a Number!");
      let num = Math.trunc(number)

      let digitСapacity = {
        "0": "",
        "1": "k",
        "2": "kk",
        "3": "kkk",
      };

      let thousands = Math.floor((("" + num).length - 1) / 3);

      let coef = 1000 ** thousands;

      if (digitСapacity[thousands] > 2) {
        return (num / coef).toFixed(1) + digitСapacity[thousands]
      } else {
        return Math.trunc((num / coef).toFixed(1)) + digitСapacity[thousands]
      }
    },
  },
}
</script>
<style lang='scss' scoped>
.chart {

  &__wrapper {
    display: flex;
  }

  &__chart {
    flex-grow: 1;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 0px 5px 5px 0px;
    padding: 30px 30px 15px;
    display: flex;
    flex-direction: column;
  }

  &__bars {
    position: relative;
    z-index: 1;
    height: 105px;

    &:not(:last-child) {
      // margin-bottom: 22px;
      margin-bottom: 18px;
    }
  }

  &__bar {
    min-width: min-content;
    width: 0%;
    height: 30px;
    background-color: gray;
    border-radius: 100px;
    transition: all 0.3s ease;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 9px;

    &:not(:last-child) {
      margin-bottom: 11px;
    }
  }

  &__bar-percent {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #FFFFFF;
  }

  &__notification {
    position: relative;
    width: 100%;
    height: 100%;

    & .notification {
      font-size: 34px;
    }
  }
}

.chart.dark {
  & * {
    transition: 0.3s background-color;
  }
  & .side-block {
    background-color: #262424;
    border-color: rgba(72, 68, 68, 0.5);

    & .block {
      color: #FFFFFF;
    }

    & .legend__item {
      color: #A7A7A7;
    }
  }

  & .chart__chart {
    background-color: #262424;
  }
}

.side-block {
  padding: 30px 20px 20px;
  background: #FFFFFF;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 5px 0px 0px 5px;
  max-width: 280px;
  min-width: 240px;
  border-right: 1px solid rgba($color: #D3D3D3, $alpha: 0.5);
}

div.block:last-child {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  margin-bottom: 18px;
}

.block {
  min-height: 75px;
  height: 105px;
  font-family: 'Mail Sans Roman';
  color: #262424;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  margin-bottom: 18px;

  &.last {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
  }

  &__filial {
    font-weight: 600;
    font-size: 16px;
    line-height: 155%;
  }

  &__fact {
    display: flex;
    gap: 7px;
    align-items: center;
    flex-shrink: 0;

    &-text {
      font-weight: 600;
      font-size: 27px;
      line-height: 122%;
    }
  }

  &__plan {
    display: flex;
    gap: 7px;
    align-items: center;
    flex-shrink: 0;

    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 155%;
    }
  }
}

.legend {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 60px;

  &__item {
    font-family: 'Mail Sans Roman';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #626262;
    display: flex;
    gap: 5px;
    transition: .3s all;
  }

  &__color {
    border-radius: 100px;
    width: 11px;
    height: 11px;
  }
}

.axis-x {
  // display: grid;
  // grid-template-columns: repeat( auto-fit, minmax(30px, 1fr) );
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 155%;
  text-align: center;
  color: #A7A7A7;

  &__item {
  }
}

.canvas__wrapper {
  flex-grow: 1;
  position: relative;

  & .canvas {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.thump {
  width: 3px;
  height: 35px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: red;
}
</style>
