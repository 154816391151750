export default {
    state: () => ({
        donutChartColors: [['#BD2C34', '#E83D46'], "#F9A620", "#000000", "#005FA7", "#808080", "#00BFB9", "#FB2D90", "#6700A6", "#3CD34B", "#0F6D00"],
        graphChartColors: ['#E83D46', "#00000054", "#F9A620", "#005FA7", "#00BFB9", "#FD2D91"],
        graphInfo: {
            data: [
                [85865,38000,25000,87553,70000,10000,169985,15000,15433,5500,3000,387544],
            ],
            labels: [
                {
                    label: 'Янв',
                    fullLabel: 'Январь'
                },
                {
                    label: 'Фев',
                    fullLabel: 'Февраль'
                },
                {
                    label: 'Март',
                    fullLabel: 'Март'
                },
                {
                    label: 'Апр',
                    fullLabel: 'Апрель'
                },
                {
                    label: 'Май',
                    fullLabel: 'Май'
                },
                {
                    label: 'Июн',
                    fullLabel: 'Июнь'
                },
                {
                    label: 'Июл',
                    fullLabel: 'Июль'
                },
                {
                    label: 'Авг',
                    fullLabel: 'Август'
                },
                {
                    label: 'Сен',
                    fullLabel: 'Сентябрь'
                },
                {
                    label: 'Окт',
                    fullLabel: 'Октябрь'
                },
                {
                    label: 'Ноя',
                    fullLabel: 'Ноябрь'
                },
                {
                    label: 'Дек',
                    fullLabel: 'Декабрь'
                }
            ],
            year: 2022,
            counter: 'шт.'
        }
    }),
    getters: {
        getGraphInfo(state) {
            return state.graphInfo
        }
    }
}