<template>
  <div :class="['chart__mobile', { dark: theme === 'dark' }]">
    <div class="side-block">
      <div class="block">
        <p class="block__filial">{{ item?.name }}</p>
        <div class="block__fact">
            <div :style="{ background: legendColors[2] }" class="legend__color"></div>
            <p class="block__fact-text">{{ sumFormatted(item?.fact.value) }}</p>
          </div>
          <div class="block__plan">
            <div :style="{ background: legendColors[0] }" class="legend__color"></div>
            <p class="block__plan-text">{{ sumFormatted(item?.plan.value) }}</p>
          </div>
      </div>
      <div class="legend">
        <div class="legend__item">
          <div :style="{ background: legendColors[2] }" class="legend__color"></div>
          <span>- факт</span>
        </div>
        <div class="legend__item">
          <div :style="{ background: legendColors[0] }" class="legend__color"></div>
          <span>- план</span>
        </div>
      </div>
    </div>
    <div class="chart__chart" ref="chart-chart">
      <div class="canvas__wrapper" ref="canvas-wrapper">
        <canvas class="canvas" ref="canvas"></canvas>
        <div class="chart__bars" v-if="item">
          <div class="chart__bar" :style="{
            background: `linear-gradient(90deg, ${barColors[0][0]} 0%, ${barColors[0][1]} 50.52%)`,
            width: calcWidth(item.plan.value) + '%'
          }">
            <span class="chart__bar-percent">100%</span>
          </div>
          <div class="chart__bar" :style="{
            background: `linear-gradient(90deg, ${barColors[1][0]} 1.54%, ${barColors[1][1]} 96.34%)`,
            width: calcWidth(item.fact.value) + '%'
          }">
            <span class="chart__bar-percent">{{ planPercent() }}</span>
          </div>
        </div>
        <p class="notification" v-else-if="isEmptyData">Нет данных</p>
      </div>
      <div class="axis-x" ref="horizontalBar" v-if="formattedValues">
        <span class="axis-x__item" v-for="dig of formattedValues">{{ dig }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import spacesInDigit from '@/assets/script/spacesInDigit';

export default {
  name: 'BarChartMobileBlock',
  props: {
    item: { type: Object, requried: true, default: null },
    counter: { type: String, requried: false, default: '' },
    legendColors: { type: Array, requried: true, default: null },
    barColors: { type: Array, requried: true, default: null },
    theme: {
      type: String,
      required: false,
      default: 'light',
      validator: function (value) {
        return ['light', 'dark'].includes(value)
      }
    },
  },
  data() {
    return {
      canvas: {
        canvas: null,
        ctx: null,
      },
    }
  },
  mounted() {
    this.initCanvas()
    window.addEventListener('resize', this.initCanvas)
  },
  destroyed() {
    window.removeEventListener('resize', this.initCanvas)
  },
  watch: {
    theme() {
      this.initCanvas()
    }
  },
  computed: {
    isEmptyData() {
      return this.item === null
    },
    maxAxisValue() {
      if (this.isEmptyData) return null
      return Math.max(this.item.fact.value, this.item.plan.value)
    },
    formattedValues() {
      if (this.isEmptyData) return null
      const maxValue = this.maxAxisValue
      let axisValues = this.getAdjustedRange(maxValue, 6)
      if (String(Math.ceil(maxValue)).length > 4) {
        axisValues = axisValues.map(value => this.replaceThousands(value))
      }
      return axisValues
    },
    maxAxisValueRounded() {
      if (this.isEmptyData) return null
      return this.roundUp(this.maxAxisValue, 5)
    },
  },
  methods: {
    spacesInDigit,
    sumFormatted(sum) {
      if (!sum) return 'Нет данных'

      if (this.counter === '$') {
          return `${this.counter} ` + spacesInDigit(sum)
      }
      else {
          return spacesInDigit(sum) + ` ${this.counter}`
      }
    },
    planPercent() {
      // const percent = this.item.plan.percent
      const fact = this.item.fact.value
      const plan = this.item.plan.value

      if (!plan) return 'Нет данных'

      const percent = (fact * 100) / plan

      if (fact > plan) {
        const calcPercent = percent - 100
        return '+' + calcPercent.toFixed(2) + '%'
      }
      else {
        const calcPercent = 100 - percent
        return '-' + calcPercent.toFixed(2) + '%'
      }
    },

    factPercent(index) {
      const fact = this.data[index].fact.value
      const plan = this.data[index].plan.value

      if (!plan) return 'Нет данных'

      const percent = (fact * 100) / plan

      if (fact > plan) {
        const calcPercent = percent - 100
        return '+' + calcPercent.toFixed(2) + '%'
      }
      else {
        const calcPercent = 100 - percent
        return '-' + calcPercent.toFixed(2) + '%'
      }
    },
    initCanvas() {
      const canvas = this.$refs.canvas
      this.canvas.canvas = canvas
      this.canvas.ctx = canvas.getContext('2d')
      this.setCanvasDimensions(this.canvas.canvas)
      if (this.isEmptyData) return
      this.drawVerticalLines()
    },
    setCanvasDimensions(canvas) {
      const canvasStyle = getComputedStyle(canvas)
      const DPI_WIDTH = parseFloat(canvasStyle.width) * 2
      const DPI_HEIGHT = parseFloat(canvasStyle.height) * 2
      canvas.width = DPI_WIDTH
      canvas.height = DPI_HEIGHT
      this.canvas.DPI_WIDTH = DPI_WIDTH
      this.canvas.DPI_HEIGHT = DPI_HEIGHT
    },
    drawVerticalLines() {
      const axisX = this.getCoordsAxisX()
      const ctx = this.canvas.ctx

      for (let i = 0; i < axisX.length; i++) {
        const startY = this.canvas.DPI_HEIGHT
        const endY = startY - this.canvas.DPI_HEIGHT
        ctx.lineWidth = 2
        ctx.strokeStyle = this.theme === 'light' ? '#EDEDED' : '#575757'
        ctx.setLineDash([14, 14])
        ctx.moveTo(axisX[i] * 2, startY)
        ctx.lineTo(axisX[i] * 2, endY)
        ctx.stroke()
      }

      ctx.closePath()
    },
    getCoordsAxisX() {
      const axisX = []
      const horizontalBarItemsWidth = this.horizontalBarItemsWidth()
      const horizontalBar = this.$refs.horizontalBar
      if (!horizontalBar) return 0
      const horizontalBarChildren = horizontalBar.children
      const wrapper = this.$refs['canvas-wrapper']
      const wrapperCoords = wrapper.getBoundingClientRect()

      for (let i = 0; i < horizontalBarChildren.length; i++) {
        const itemLeft = horizontalBarChildren[i].getBoundingClientRect().left
        const itemWidth = horizontalBarItemsWidth[i]
        const coordX = (itemLeft + itemWidth / 2) - wrapperCoords.left
        axisX.push(coordX)
      }

      return axisX
    },
    horizontalBarItemsWidth() {
      const horizontalBar = this.$refs.horizontalBar
      if (horizontalBar === null || horizontalBar === undefined) return
      const isHorizontalBarHidden = getComputedStyle(horizontalBar).display === 'none'

      // TODO: поменять if
      if (isHorizontalBarHidden) {
        const horizontalBarLength = horizontalBar.children.length
        const canvasWidth = parseFloat(getComputedStyle(this.$refs.canvas).width)
        const itemWidth = canvasWidth / horizontalBarLength
        return Array(horizontalBarLength).fill(itemWidth, 0, horizontalBarLength)
      }
      else {
        return Array.from(horizontalBar.children).map(item => {
          return parseFloat(getComputedStyle(item).width).toFixed(2)
        })
      }
    },
    calcWidth(value) {
      return (value / this.maxAxisValueRounded * 100).toFixed(2)
    },
    getAdjustedRange(maxValue, div) {
      const step = maxValue / div
      const arr = []

      for (let i = 1; i <= div; i++) {
        const value = this.roundUp(step * i, 5)
        arr.push(value)
      }

      return [0, ...arr]
    },
    roundUp(number, precision) {
      const numberLength = String(Math.ceil(number)).length
      let zeroLength = 0
      let precisionFormatted = precision

      if (numberLength > 3) {
        zeroLength = numberLength - 2
        precisionFormatted += Array(zeroLength).fill(0).join('')
      }
      else {
        zeroLength = numberLength - 1
        precisionFormatted += Array(zeroLength).fill(0).join('')
      }

      return Math.ceil(number / precisionFormatted) * precisionFormatted
    },
    replaceThousands(number) {
      if (isNaN(number)) throw new Error(number + " is not a Number!");
      let num = Math.trunc(number)

      let digitСapacity = {
        "0": "",
        "1": "k",
        "2": "kk",
        "3": "kkk",
      };

      let thousands = Math.floor((("" + num).length - 1) / 3);

      let coef = 1000 ** thousands;

      if (digitСapacity[thousands] > 2) {
        return (num / coef).toFixed(1) + digitСapacity[thousands]
      } else {
        return Math.trunc((num / coef).toFixed(1)) + digitСapacity[thousands]
      }
    },
  },
}
</script>
<style lang='scss' scoped>
.chart {
  &__chart {
    flex-grow: 1;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgb(0 0 0 / 7%);
    border-radius: 0px 0px 5px 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;

    @media (max-width: 500px) {
      gap: 5px;
    }
  }

  &__bars {
    z-index: 1;
    height: min-content;
    transform: translate(0px, -50%);
    top: 50%;
    position: absolute;
    width: 100%;
  }

  &__bar {
    min-width: min-content;
    width: 0%;
    height: 30px;
    background-color: gray;
    border-radius: 100px;
    transition: all 0.3s ease;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 9px;

    &:not(:last-child) {
      margin-bottom: 11px;
    }
  }

  &__bar-percent {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #FFFFFF;
  }
}

.chart__mobile {
}

.chart__mobile.dark {
  & * {
    transition: 0.3s background-color;
  }
  & .side-block {
    background-color: #262424;
    border-color: rgba(72, 68, 68, 0.5);

    & .block {
      color: #FFFFFF;
    }

    & .legend__item {
      color: #A7A7A7;
    }
  }

  & .chart__chart {
    background-color: #262424;
  }
}

.side-block {
  min-width: 240px;
  padding: 20px 30px 20px 20px;
  background: #FFFFFF;
  box-shadow: 0px 5px 20px rgb(0 0 0 / 7%);
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid rgba(211, 211, 211, 0.5);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.block {
  // min-height: 75px;
  min-height: 105px;
  font-family: 'Mail Sans Roman';
  color: #262424;

  &.last {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
  }

  &__filial {
    font-weight: 600;
    font-size: 16px;
    line-height: 155%;
  }

  &__fact {
    display: flex;
    gap: 7px;
    align-items: center;
    flex-shrink: 0;

    &-text {
      font-weight: 600;
      font-size: 32px;
      line-height: 122%;
    }
  }

  &__plan {
    display: flex;
    gap: 7px;
    align-items: center;
    flex-shrink: 0;

    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 155%;
    }
  }
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  &__item {
    font-family: 'Mail Sans Roman';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #626262;
    display: flex;
    gap: 5px;
    transition: .3s all;

    & span {
      white-space: nowrap;
    }
  }

  &__color {
    border-radius: 100px;
    width: 11px;
    height: 11px;
  }
}

.axis-x {
  // display: grid;
  // grid-template-columns: repeat( auto-fit, minmax(30px, 1fr) );
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 155%;
  text-align: center;
  color: #A7A7A7;

  &__item {
    &:not(:first-child) {
      @media (max-width: 500px) {
        transform: rotate(270deg);
      }
    }
  }
}

.canvas__wrapper {
  flex-grow: 1;
  position: relative;
  min-height: 110px;

  & .canvas {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  & .notification {
    font-size: max(24px, 3vw);
  }
}
</style>
