<template>
    <div class="opened-filials">
        <div class="opened-filials__block">
            <app-title title="Кол-во открытых филиалов" level="5" class="opened-filials__title" :nbsp="false"></app-title>
            <div class="opened-filials__info-container">
                <!-- {{ opened.length }} -->
                {{ openedBranches.count }}
                <div class="opened-filials__flags">
                    <img v-for="flag in openedBranches.flags" :src="flag" alt="Flag" :key="flag.countryName">
                </div>
            </div>
        </div>
        <div class="opened-filials__block" v-if="buildingBranches.count > 0">
            <app-title title="Кол-во строящихся филиалов" level="5" class="opened-filials__title" :nbsp="false"></app-title>
            <div class="opened-filials__info-container">
                {{ buildingBranches.count }}
                <div class="opened-filials__flags">
                    <img v-for="flag in buildingBranches.flags" :src="flag" alt="Flag" :key="flag.countryName">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'opened-filials',
    props: ['opened', 'building'],
    data() {
        return {
            flags: {
                Россия: require('@/assets/icons/ru.png'),
                Казахстан: require('@/assets/icons/kz.png'),
                Узбекистан: require('@/assets/icons/uz.png'),
            }
        }
    },
    computed: {
        openedBranches() {
            const openedBranches = this.$store.getters['getOpenedBranches']
            openedBranches.flags = []

            for (const key of Object.keys(this.flags)) {
                openedBranches.countries.includes(key) ? openedBranches.flags.push(this.flags[key]) : ''
            }

            return openedBranches
        },
        buildingBranches() {
            const buildingBranches = this.$store.getters['getBuildingBranches']
            buildingBranches.flags = []

            for (const key of Object.keys(this.flags)) {
                buildingBranches.countries.includes(key) ? buildingBranches.flags.push(this.flags[key]) : ''
            }

            return buildingBranches
        },
    }
}
</script>

<style lang="scss" scoped>
.opened-filials {
    width: 240px;
    background: #fff;
    border-radius: 10px;
    transition: .3s all;
    & h5 {
        line-height: 1.4;
    }
    @media(max-width: 1350px) {
        max-width: 340px;
        width: 100%;
    }
    &__block {
        display: flex;
        gap: 15px;
        justify-content: space-between;
        padding: 15px 20px;
        transition: .3s all;
        border-bottom: 1px solid #ccc;
        &:last-child {
            border-bottom: none;
        }
        @media(max-width: 1350px) {
            padding: 15px 20px 15px 10px;
            border-bottom: none;
            border-right: 1px solid #ccc;
            width: 100%;
            &:last-child {
                padding: 15px 10px 15px 20px;
                border-right: none;
            }
        }
    }
    &__title {
        max-width: 100px;
        & span {
            width: 100%;
        }
        @media(max-width: 1350px) {
            max-width: 60px;
        }
    }
    &__info-container {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        transition: .3s all;
        font-weight: 600;
        font-size: 38px;
        line-height: 100%;
        color: #262424;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-end;
        & img {
            width: 20px;
            height: 14px;
            font-size: 1px;
        }
        @media(max-width: 1350px) {
            justify-content: flex-start;
            & img {
                width: 17px;
                height: 12px;
            }
        }
    }
    &__flags {
        display: flex;
        flex-wrap: wrap;
        gap: 9px;
        @media(max-width: 1350px) {
            gap: 6px;
        }
    }
}

.dark-mode {
    .opened-filials {
        background: #262424;
        &__block {
            border-color: rgba(204, 204, 204, .5);
            @media(max-width: 1350px) {
                border-color: rgba(204, 204, 204, .5);
            }
        }
        &__info-container {
            color: #fff;
        }
    }
}
</style>
