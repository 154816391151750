<template>
    <div class="speedometer flex-grow-1">
        <app-title level="5" :title="title"></app-title>
        <div class="speedometer__inner" v-if="error.code === null">
            <div class="speedometer__graph">
                <div class="speedometer__arc-container">
                    <div class="speedometer__arc">
                        <canvas ref="canvas-speed" width="200" height="106"></canvas>
<!--                        <img-->
<!--                        src="@/assets/icons/charts/speedometer_g-r.svg"-->
<!--                        :style="{transform: `rotate(${speedometerRotation}deg)`}"-->
<!--                        alt="">-->
<!--                        <img-->
<!--                        v-if="false"-->
<!--                        src="@/assets/icons/charts/speedometer_g-r.svg"-->
<!--                        :style="{transform: `rotate(${speedometerRotation}deg)`}"-->
<!--                        alt="">-->
                    </div>
                    <div v-if="alarm" class="speedometer__danger">!</div>
                </div>
                <div class="speedometer__dots">
                    <!-- :style="{transform: `rotate(${speedometerRotation}deg)`}" -->
                    <img src="@/assets/icons/charts/speedometer_dots.svg" alt="">
                </div>
            </div>
            <div class="speedometer__values" v-if="!pepperoni">
                <span>{{ minmaxPrefix }} Min: {{ min }}{{ minmaxPostfix }}</span>
                <span>{{ minmaxPrefix }} Max: {{ max }}{{ minmaxPostfix }}</span>
            </div>
        </div>
        <div class="speedometer__inner" v-else>
            <div class="speedometer__graph">
                <div class="speedometer__arc-container">
                    <div class="speedometer__arc">
                        <canvas ref="canvas-speed" width="200" height="106"></canvas>
                    </div>
                    <div v-if="alarm" class="speedometer__danger">!</div>
                </div>
                <div class="speedometer__dots">
                    <!-- :style="{transform: `rotate(${speedometerRotation}deg)`}" -->
                    <img src="@/assets/icons/charts/speedometer_dots.svg" alt="">
                </div>
            </div>
            <div class="speedometer__values" v-if="!pepperoni && error.code === 1">
                <span>{{ minmaxPrefix }} Min: {{ min }}{{ minmaxPostfix }}</span>
                <span>{{ minmaxPrefix }} Max: {{ max }}{{ minmaxPostfix }}</span>
            </div>
        </div>
        <div class="speedometer__bottom" v-if="error.code === null">
            <app-title level="5" title="Текущее значение" v-if="!pepperoni"></app-title>
            <div :class="{'speedometer__pepperoni': pepperoni}">
                <div class="speedometer__current-value">{{ minmaxPrefix }} {{ current }} {{ minmaxPostfix }}</div>
                <increase-value
                    class="speedometer__increase"
                    :success="increase.success"
                    :value="increase.increase"
                    :inversion="increase.inversion">
                </increase-value>
            </div>
        </div>
        <div class="speedometer__bottom" v-else>
            <app-title class="speedometer__bottom-title" level="5" title="Текущее значение" v-if="!pepperoni && error.code === 1"></app-title>
            <p class="speedometer__error">
                {{ error.text }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'speedometer-chart',
    // props: ['title', 'current', 'increase', 'min', 'max', 'pepperoni', 'minmaxPrefix', 'minmaxPostfix', 'colorReverse', 'alarm'],
    props: {
        title: {},
        current: {},
        increase: {},
        min: {},
        max: {},
        pepperoni: {},
        minmaxPrefix: {},
        minmaxPostfix: { type: String, required: false, default: '%' },
        colorReverse: {},
        alarm: {},
        error: { type: Object, default: function() {
            return {
                text: null,
                code: null,
            }
        } },
        theme: { 
            type: String, 
            required: false, 
            default: 'light',
            validator: function (value) {
                return ['light', 'dark'].includes(value)
            }
        },
    },
    data() {
        return {
            curAngle: Math.PI,
            colors: {
                // green: '#24DB61',
                green: '#05fa57',
                yellow: '#F9A620',
                red: '#E83C46',
                white: '#FFFFFF',
            }
        }
    },
    computed: {
        stepAngle() {
            return (this.endAngle - this.startAngle) / 60
        },
        speedometerRotation() {
            return ((this.current - this.min) * 100) / (this.max - this.min);
        },
        canvas() {
            return this.$refs["canvas-speed"];
        },
        ctx() {
            return this.$refs["canvas-speed"].getContext('2d')
        },
        startAngle() {
            return Math.PI;
        },
        endAngle() {
            const degs = 180 * this.speedometerRotation / 100 + 180
            return this.degsToRads(degs);
        },
        gradient() {
            // const gradient = this.ctx.createConicGradient(this.startAngle, 0, 200);
            const gradient = this.ctx.createLinearGradient(this.startAngle, 0, this.canvas.width, this.canvas.height)

            // if (this.colorReverse) {
            //     gradient.addColorStop(0, '#E83C46');
            //     gradient.addColorStop(.25, '#24DB61');
            //     gradient.addColorStop(.5, '#F9A620');
            //     gradient.addColorStop(1, '#E83C46');
            // } else {
            //     gradient.addColorStop(0, '#24DB61');
            //     gradient.addColorStop(.25, '#E83C46');
            //     gradient.addColorStop(.5, '#F9A620');
            //     gradient.addColorStop(1, '#24DB61'); 
            // }

            // gradient.addColorStop(0.16, this.colors.green);
            // gradient.addColorStop(0.33, this.colors.yellow);
            // gradient.addColorStop(0.5, this.colors.red); 
            // gradient.addColorStop(0.66, this.colors.white); 
            // gradient.addColorStop(0.83, this.colors.yellow); 
            // gradient.addColorStop(1, this.colors.green); 

            gradient.addColorStop(0, this.colors.green);
            gradient.addColorStop(0.5, this.colors.yellow);
            gradient.addColorStop(1, this.colors.red);


            return gradient;
        }
    },
    methods: {
        initCanvas() {
            if (this.current > this.min) {
                this.animate()
            }
            else if (this.current === null) {
                this.drawArc(this.startAngle, Math.PI * 2, 'lightgrey')
            }
        },
        degsToRads(degs) {
            return degs * (Math.PI / 180);
        },
        drawArc(start, end, strokeStyle) {
            this.ctx.save()
            this.ctx.strokeStyle = strokeStyle;
            this.ctx.beginPath();
            this.ctx.lineWidth = 20;
            this.ctx.lineCap = 'round';
            this.ctx.arc(100, 100, 90, start, end);

            this.ctx.stroke();
            this.ctx.restore();
        },
        drawSpeed(start, end, cur) {
            if (this.theme === 'dark') {
                this.drawArc(start, Math.PI * 2, '#2C2C2C');
            } else {
                this.drawArc(start, Math.PI * 2, 'lightgrey');
            }
            if (!this.error.code) {
                this.drawArc(start, cur, this.gradient);
            }
        },
        animate() {
            const draw = (t) => {
                // console.log('draw', this.curAngle)
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                this.drawSpeed(this.startAngle, this.endAngle, this.curAngle);
                this.curAngle = this.curAngle + this.stepAngle;
                if (this.curAngle < this.endAngle) {
                    // console.log(this.curAngle)
                    requestAnimationFrame(draw);
                }
            }
            requestAnimationFrame((t) => {
                draw(t)

            })
            // this.drawSpeed(this.startAngle, this.endAngle, this.curAngle);
        }
    },
    mounted() {
        if (!this.canvas) return
        this.initCanvas()
    },
    watch: {
        current() {
            this.animate()
        },
        theme() {
            if (!this.canvas) return
            this.initCanvas()
        }
    }
}
</script>

<style lang="scss" scoped>
.speedometer {
    width: 100%;
    height: min-content;
    padding: 14px 20px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    transition: .3s all;
    &__pepperoni {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, -14px);
    }
    &__graph {
        overflow: hidden;
        position: relative;
    }
    &__danger {
        position: absolute;
        left: 50%;
        bottom: 0%;
        transform: translate(-50%, -7px);
        width: 55px;
        height: 55px;
        border-radius: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 14.0476px rgba(0, 0, 0, 0.1);
        font-style: normal;
        font-weight: 600;
        font-family: "Mail Sans Roman";
        font-size: 44.9524px;
        line-height: 100%;
        color: #E83D46;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s all;
    }
    &__inner {
        width: 100%;
        margin: 20px auto 0;
        position: relative;
        overflow: hidden;
        width: 200px;
    }
    &__arc {
        position: absolute;
        top: 0;
        overflow: hidden;
        left: 0;
        & img {
            transform: rotate(-45deg);
            transform-origin: 50% 100%;
        }
    }
    &__arc-container {
        overflow: hidden;
        width: 200px;
        height: 106px;
        border-radius: 100px 100px 0 0;
        border-top: 20px solid #F6E2E3;
        border-left: 20px solid #F6E2E3;
        border-right: 20px solid #F6E2E3;
    }
    &__dots {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        display:block;
        width: 140px;
        height: 140px;
        overflow: hidden;


    }
    &__values {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        color: #262424;
        transition: .3s all;
    }
    &__current-value {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 120%;
        color: #262424;
        transition: .3s all;
    }
    &__bottom {
        &:has(.speedometer__error) {
            padding: 50px 0px 35px;
        }
        &:has(.speedometer__bottom-title) {
            padding: 10px 0px 35px;

            & .speedometer__error {
                text-align: start;
            }
        }
    }
    &__error {
        text-align: center;
        // font-family: 'Commissioner';
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        color: #262424;
    }
}

.dark-mode {
    .speedometer {
        background: #262424;
        &__danger {
            background: #ccc;
            color: #E83D46;
        }
        &__values {
            color: #fff;
        }
        &__current-value {
            color: #fff;
        }
        &__dots {
            & img {
                transform: rotate(180deg);
            }
        }
        &__arc-container {
            border-top: 20px solid #2C2C2C;
            border-left: 20px solid #2C2C2C;
            border-right: 20px solid #2C2C2C;
        }
    }
}
</style>
