<template>
    <div class="header">
        <div class="header__left">
            <span class="header__left-title desktop">Валюта:</span>
            <div class="header__currency">
                <span class="header__currency-symbol">{{ currency.label }}</span>
                <span :class="['header__currency-code', { uzs: currency?.name?.toLowerCase() === 'uzs' } ]">{{ currency.name }}</span>
            </div>
        </div>
        <div class="header__right">
            <div class="header__toggler">
                <button @click="toggleFilter(0)"
                    :class="['header__toggler-item', { 'header__toggler-item_active': activeFilter === 0 }]">Дн</button>
                <button @click="toggleFilter(1)"
                    :class="['header__toggler-item', { 'header__toggler-item_active': activeFilter === 1 }]">Нед</button>
                <button @click="toggleFilter(2)"
                    :class="['header__toggler-item', { 'header__toggler-item_active': activeFilter === 2 }]">Мес</button>
                <button @click="toggleFilter(3)"
                    :class="['header__toggler-item', { 'header__toggler-item_active': activeFilter === 3 }]">Кв</button>
                <button @click="toggleFilter(4)"
                    :class="['header__toggler-item', { 'header__toggler-item_active': activeFilter === 4 }]">Гд</button>
                <button @click="toggleFilter(5)"
                    :class="['header__toggler-item', { 'header__toggler-item_active': activeFilter === 5 }]">Все</button>
            </div>
            <!-- <calendar-block></calendar-block> -->
            <my-date-picker :time="time"></my-date-picker>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'app-header',
    data() {
        return {
        };
    },
    methods: {
        async toggleFilter(i) {
            this.activeFilter = i;
            this.$store.commit('toggleFilter', i)
            const pageAPInames = this.$store.getters['getPageAPInames']
            const url = pageAPInames[this.$route.name]
            // this.showReload();
            await this.$store.dispatch('fetchMainData', { url: '/page/' + url });
            // this.hideReload();

        },
        showReload() {
            document.querySelector(".preload-date").classList.add("preload-date--show");
        },
        hideReload() {
            document.querySelector(".preload-date").classList.remove("preload-date--show");
        },
    },
    computed: {
        time() {
            return this.$store.getters['getTimeRange']
        },
        activeFilter: {
            get() {
                return this.$store.state.headerStore.globalFilter
            },
            set(val) {
                return val
            }
        },
        currency() {
            const currencySimbol = this.$store.getters['getCurrencySimbol']
            const currencyCode = this.$store.getters['getCurrencyCode']

            return {
                label: currencySimbol,
                name: currencyCode
            }

            // if (this.$store.state.headerStore.selectedFilial === null)
            //     return {
            //         label: '$',
            //         name: 'USD'
            //     }
            // else
            //     return {
            //         label: 'P',
            //         name: 'RUB'
            //     }
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    background: #FAF6EE;
    border-bottom: 1px solid #E4DFD5;
    height: 90px;
    position: fixed;
    width: calc(100vw - 240px);
    z-index: 101;
    top: 0;
    left: 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    transition: .3s all;

    @media (max-width: 1350px) {
        height: 70px;
        top: unset;
        bottom: 0;
        width: 100vw;
        left: 0;
        border-bottom: none;
        background: #FFFFFF;
        padding: 10px 8px;
        width: 100vw;
        gap: 6px;
        justify-content: flex-start;
    }

    @media (max-width: 743px) {
        gap: 5px;
    }

    &__left {
        display: flex;
        align-items: center;
        gap: 30px;

        // & span {
        //     font-family: 'Mail Sans Roman';
        //     font-style: normal;
        //     font-weight: 400;
        //     font-size: 16px;
        //     line-height: 155%;
        //     color: #626262;
        //     transition: .3s all;
        // }
    }

    &__left-title {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 155%;
        color: #626262;
        transition: .3s all;
    }

    &__currency {
        width: 109px;
        height: 50px;
        background: #FFFFFF;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
        display: flex;
        padding: 0px 14px;
        align-items: center;
        gap: 16px;
        transition: .3s all;
        
        @media(max-width: 1350px) {
            width: 100%;
            max-width: 70px;
            min-width: 50px;
            font-size: 12px;
            padding: 6px;
            gap: 2px;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    
    &__currency-symbol {
        color: #E83D46;
        font-size: 20px;
        @media (max-width: 1350px) {
            font-size: 18px;
        }
    }

    &__currency-code {
        font-size: 14px;
        @media (max-width: 1350px) {
            font-size: 12px;
        }

        &.uzs {
            padding-top: 5px;

            @media (max-width: 355px) {
                padding-top: 0px;
            }
        }
    }

    &__right {
        display: flex;
        gap: 30px;

        @media (max-width: 1350px) {
            justify-content: space-between;
            gap: 8px;
        }

        @media (max-width: 743px) {
            gap: 5px;
        }
    }

    &__toggler {
        background: #FFFFFF;
        border-radius: 10px;
        filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.07));
        width: auto;
        display: flex;
        justify-content: flex-start;
        height: 50px;
        transition: .3s all;

        @media (max-width: 1350px) {
            width: 224px;
        }

        @media (max-width: 375px) {
            width: 200px;
        }
    }

    &__toggler-item {
        padding: 18px 13px;
        border: none;
        cursor: pointer;
        background: transparent;
        color: #626262;
        transition: .3s all;
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        transition: .3s all;
        color: #626262;

        &_active {
            color: #FFFFFF;
            background: #F9A620;
        }

        &:first-of-type {
            border-radius: 10px 0px 0px 10px;
        }

        &:last-of-type {
            border-radius: 0px 10px 10px 0px;
        }

        @media (max-width: 1350px) {
            padding: 15px 6px;
            // font-size: 12px;
            flex-grow: 1;
        }

        @media (max-width: 743px) {
            // max-width: 25px;
        }
    }
}

.dark-mode {
    .header {
        background: #2C2C2C;
        border-bottom: 1px solid #4A4A4A;

        @media (max-width: 1350px) {
            background: #2C2C2C;
        }

        &__left {
            & span {
                color: #ECECEC;
            }
        }

        &__currency {
            background: #262424;
            color: #fff;

            & span {
                color: #E83D46;
            }
        }

        &__toggler {
            background: #262424;
        }

        &__toggler-item {
            color: #A7A7A7;

            &_active {
                color: #FFFFFF;
                background: #F9A620;
            }
        }
    }
}
</style>
