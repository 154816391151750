<template>
    <div :class="['menu', {'menu_opened': menuOpened}]">
        <div class="menu__top">
            <router-link to="/general" @click.native="scrollToTop" class="menu__logo">
                <img src="@/assets/icons/logo_white.svg" alt="Logo">
            </router-link>
            <filial-selector v-if="countries" :data="countries" />
            <div
                :class="['menu__burger', 'mobile', {'menu__burger_active': menuOpened}]"
                @click="toggleMenu">
                <div class="menu__burger-line"></div>
            </div>
            <div class="menu__links desktop">

                <router-link @click.native="scrollToTop" active-class="menu__link_active" to="/general" class="menu__link">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/general.svg" alt="Общая сводка"></div>
                    <div class="menu__link-name">Общая сводка</div>
                </router-link>
                <router-link v-if="permissions.plan" @click.native="scrollToTop" active-class="menu__link_active" to="/plan-fact" exact class="menu__link">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/plan.svg" alt="План / Факт "></div>
                    <div class="menu__link-name">План / Факт </div>
                </router-link>
                <router-link v-if="permissions.opu" @click.native="scrollToTop" active-class="menu__link_active" to="/opu" exact class="menu__link">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/opu.svg" alt="ОПУ"></div>
                    <div class="menu__link-name">ОПУ</div>
                </router-link>

                <button @click="toggleSubmenu(0)" class="menu__link" v-if="isReportsShown">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/reports.svg" alt="Отчёты"></div>
                    <div class="menu__link-name">Отчёты</div>
                    <div :class="['menu__submenu', {'menu__submenu_active': openedSubmenu === 0}]">
                        <div class="menu__submenu-inner">
                            <div class="menu__submenu-links">
                                <router-link v-if="permissions.events" @click.native="scrollToTop" active-class="menu__submenu-link_active" exact to="/events-log" class="menu__submenu-link">Журнал событий</router-link>
                                <router-link v-if="permissions.deletes" @click.native="scrollToTop" active-class="menu__submenu-link_active" exact to="/delete-review" class="menu__submenu-link">Отчет по удалениям</router-link>
                                <router-link v-if="permissions['write-offs']" @click.native="scrollToTop" active-class="menu__submenu-link_active" exact to="/report" class="menu__submenu-link">Отчёт по списаниям</router-link>
                            </div>
                        </div>
                    </div>
                </button>

                <router-link v-if="permissions.deviations" @click.native="scrollToTop" active-class="menu__link_active" to="/deviations" exact class="menu__link">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/deviations.svg" alt="Отклонения"></div>
                    <div class="menu__link-name">Отклонения</div>
                </router-link>

                <!-- TODO: раскомментить -->
                <button @click="toggleSubmenu(1)" class="menu__link" v-if="isAnalyticsShown">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/analytics.svg" alt="Аналитика заказа"></div>
                    <div class="menu__link-name">Аналитика заказа</div>
                    <div :class="['menu__submenu', {'menu__submenu_active': openedSubmenu === 1}]">
                        <div class="menu__submenu-inner">
                            <div class="menu__submenu-links">
                                <router-link @click.native="scrollToTop" active-class="menu__submenu-link_active" exact to="/dashboard" class="menu__submenu-link">Дашборд</router-link>
                                <router-link v-if="permissions['top-orders']" @click.native="scrollToTop" active-class="menu__submenu-link_active" exact to="/top-orders" class="menu__submenu-link">ТОП заказов</router-link>
                                <router-link @click.native="scrollToTop" active-class="menu__submenu-link_active" exact to="/refusals" class="menu__submenu-link">Отказы</router-link>
                                <!-- <router-link @click.native="scrollToTop" active-class="menu__submenu-link_active" exact to="/weather-conditions" class="menu__submenu-link">Погодные условия</router-link> -->
                                <router-link @click.native="scrollToTop" active-class="menu__submenu-link_active" exact to="/client-base" class="menu__submenu-link">Клиентская база</router-link>
                            </div>
                        </div>
                    </div>
                </button>

                <!-- <router-link @click.native="scrollToTop" active-class="menu__link_active" to="/kit" class="menu__link">
                  <div class="menu__link-icon"><img src="@/assets/icons/menu/general.svg" alt="KIT"></div>
                  <div class="menu__link-name">Components</div>
                </router-link> -->
            </div>
        </div>
        <div class="menu__bottom desktop">
            <div class="menu__admin-link admin-link" v-if="userInfo.admin_url">
                <a :href="userInfo.admin_url" target="_blank" class="menu__dark-mode-text admin-link__text">
                    <img src="@/assets/icons/menu/admin_logo.svg" alt="">
                    <span>Панель администратора</span>
                </a>
            </div>
            <div class="menu__dark-mode">
                <div class="menu__dark-mode-text">
                    <img src="@/assets/icons/menu/darkmode.svg" alt="DarkMode">
                    <span>Ночной режим</span>
                </div>
                <app-toggler @toggle="provideDarkMode" :activate="!!darkMode"></app-toggler>
            </div>
            <div class="menu__account">
                <div class="menu__account-info">
                    <!-- <div class="menu__account-icon"><img src="@/assets/icons/menu/account.png" alt="User"></div> -->
                    <div class="menu__account-icon" v-if="userInfo.photo">
                        <img :src="userInfo.photo" alt="User">
                    </div>
                    <div class="menu__account-icon" v-else>
                        <img src="@/assets/icons/menu/roni-logo_round.png" alt="User"> 
                    </div>
                    <!-- <div class="menu__account-name">Алексей<br>Журавлев</div> -->
                    <div class="menu__account-main">
                        <div class="menu__account-name">
                            <template v-for="name of userInfo.name.split(' ')">
                                {{ name }}<br>
                            </template>
                        </div>
                        <p class="menu__account-position" v-if="userInfo.position">{{ userInfo.position }}</p>
                    </div>
                </div>
                <button @click="logout" class="menu__log-out"><img src="@/assets/icons/menu/log-out.svg" alt="Log Out"></button>
            </div>
        </div>
        <div :class="['menu__mobile', 'mobile', {'menu__mobile_active': menuOpened}]">
            <div class="menu__links">
                <router-link @click.native="toggleMenu" active-class="menu__link_active" to="/general" class="menu__link">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/general.svg" alt="Общая сводка"></div>
                    <div class="menu__link-name">Общая сводка</div>
                </router-link>
                <router-link v-if="permissions.plan" @click.native="toggleMenu" active-class="menu__link_active" to="/plan-fact" exact class="menu__link">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/plan.svg" alt="План / Факт "></div>
                    <div class="menu__link-name">План / Факт </div>
                </router-link>
                <router-link v-if="permissions.opu" @click.native="toggleMenu" active-class="menu__link_active" to="/opu" exact class="menu__link">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/opu.svg" alt="ОПУ"></div>
                    <div class="menu__link-name">ОПУ</div>
                </router-link>
                <button @click="toggleSubmenu(0)" class="menu__link" v-if="isReportsShown">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/reports.svg" alt="Отчёты"></div>
                    <div class="menu__link-name">Отчёты</div>
                </button>
                <router-link v-if="permissions.deviations" @click.native="toggleMenu" active-class="menu__link_active" to="/deviations" exact class="menu__link">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/deviations.svg" alt="Отклонения"></div>
                    <div class="menu__link-name">Отклонения</div>
                </router-link>
                <!-- TODO: удалить -->
                <!-- <router-link v-if="permissions['top-orders']" @click.native="toggleMenu" active-class="menu__link_active" to="/top-orders" exact class="menu__link">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/analytics.svg" alt="Аналитика заказа"></div>
                    <div class="menu__link-name">ТОП заказов</div>
                </router-link> -->
                <!-- TODO: раскомментить -->
                <button @click="toggleSubmenu(1)" class="menu__link" v-if="isAnalyticsShown">
                    <div class="menu__link-icon"><img src="@/assets/icons/menu/analytics.svg" alt="Аналитика заказа"></div>
                    <div class="menu__link-name">Аналитика заказа</div>
                </button>
            </div>
            <div class="menu__bottom">
                <div class="menu__admin-link admin-link" v-if="userInfo.admin_url">
                    <a :href="userInfo.admin_url" target="_blank" class="menu__dark-mode-text admin-link__text">
                        <img src="@/assets/icons/menu/admin_logo.svg" alt="">
                        <span>Панель администратора</span>
                    </a>
                </div>
                <div class="menu__dark-mode">
                    <div class="menu__dark-mode-text">
                        <img src="@/assets/icons/menu/darkmode.svg" alt="DarkMode">
                        <span>Ночной режим</span>
                    </div>
                    <app-toggler @toggle="provideDarkMode" :activate="!!darkMode"></app-toggler>
                </div>
                <div class="menu__account">
                    <div class="menu__account-info">
                        <!-- <div class="menu__account-icon"><img src="@/assets/icons/menu/account.png" alt="User"></div> -->
                        <div class="menu__account-icon" v-if="userInfo.photo">
                            <img :src="userInfo.photo" alt="User">
                        </div>
                        <div class="menu__account-icon" v-else>
                            <img src="@/assets/icons/menu/roni-logo_round.png" alt="User"> 
                        </div>
                        <!-- <div class="menu__account-name">Алексей<br>Журавлев</div> -->
                        <div class="menu__account-main">
                            <div class="menu__account-name">
                                <template v-for="name of userInfo.name.split(' ')">
                                    {{ name }}<br>
                                </template>
                            </div>
                            <p class="menu__account-position" v-if="userInfo.position">{{ userInfo.position }}</p>
                        </div>
                    </div>
                    <button @click="logout" class="menu__log-out"><img src="@/assets/icons/menu/log-out.svg" alt="Log Out"></button>
                </div>
            </div>
        </div>
        <div :class="['menu__submenu-mobile mobile', {'menu__submenu-mobile_active': openedSubmenu !== null}]">
            <div class="menu__submenu-inner">
                <div @click="openedSubmenu = null" class="menu__submenu-back">
                    <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="15.7074" width="22.2136" height="4" transform="rotate(-45 0 15.7074)" fill="#262424"/>
                        <rect x="0.0245361" y="15.6829" width="22.1789" height="4" transform="rotate(-45 0.0245361 15.6829)" fill="#262424"/>
                        <rect x="2.86719" y="12.9" width="22.1587" height="4" transform="rotate(45 2.86719 12.9)" fill="#262424"/>
                    </svg>
                </div>
                <div class="menu__submenu-links" v-if="openedSubmenu === 1">
                    <router-link @click.native="toggleMenu" active-class="menu__submenu-link_active" exact to="/dashboard" class="menu__submenu-link">Дашборд</router-link>
                    <router-link @click.native="toggleMenu" active-class="menu__submenu-link_active" exact to="/top-orders" class="menu__submenu-link">ТОП заказов</router-link>
                    <router-link @click.native="toggleMenu" active-class="menu__submenu-link_active" exact to="/refusals" class="menu__submenu-link">Отказы</router-link>
                    <!-- <router-link @click.native="toggleMenu" active-class="menu__submenu-link_active" exact to="/weather-conditions" class="menu__submenu-link">Погодные условия</router-link> -->
                    <router-link @click.native="toggleMenu" active-class="menu__submenu-link_active" exact to="/client-base" class="menu__submenu-link">Клиентская база</router-link>
                </div>
                <div class="menu__submenu-links" v-if="openedSubmenu === 0">
                    <router-link v-if="permissions.events" @click.native="toggleMenu" active-class="menu__submenu-link_active" exact to="/events-log" class="menu__submenu-link">Журнал событий</router-link>
                    <router-link v-if="permissions.deletes" @click.native="toggleMenu" active-class="menu__submenu-link_active" exact to="/delete-review" class="menu__submenu-link">Отчет по удалениям</router-link>
                    <router-link v-if="permissions['write-offs']" @click.native="toggleMenu" active-class="menu__submenu-link_active" exact to="/report" class="menu__submenu-link">Отчёт по списаниям</router-link>
                </div>
            </div>
        </div>
        <div :class="['menu__submenu-overlay desktop', {'menu__submenu-overlay_active': openedSubmenu !== null}]" @click.self="openedSubmenu = null"></div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import FilialSelector from "@/components/buttons/FilialSelector";
export default {
    name: 'app-menu',
    components: {FilialSelector},
    emits: ['toggleDarkMode', 'toggleMenu'],
    props: {
        darkMode: { type: Number, required: true },
        countries: { type: Array, required: true },
    },
    data() {
        return {
            menuOpened: false,
            // darkMode: localStorage.getItem('dark-mode') === '1' ? true : false,
            // darkMode: !!Number(localStorage.getItem('dark-mode')),
            openedSubmenu: null,
            // branches: null,
        }
    },
    async beforeCreate() {
        // await this.$store.dispatch('fetchBranches')
        // this.branches = this.$store.getters['getBranches']
        await this.$store.dispatch('fetchPermissions')
    },
    methods: {
        toggleSubmenu(idx) {
            if (this.openedSubmenu === idx)
                this.openedSubmenu = null;
            else
                this.openedSubmenu = idx
        },
        provideDarkMode({ active }) {
            this.$emit('toggleDarkMode', active)
        },
        ...mapMutations({
            toggleFilial: 'toggleFilial'
        }),
        toggleMenu() {
            this.menuOpened = !this.menuOpened
            if (!this.menuOpened) this.openedSubmenu = null;
            this.$emit('toggleMenu', this.menuOpened)
            this.scrollToTop()
        },
        scrollToTop() {
            window.scrollTo(0,0)
        },
        logout() {
            // this.$store.commit('deleteToken')
            this.$store.dispatch('onLogout')
            this.$router.push('/').catch(() => {})
        },
    },
    computed: {
        ...mapGetters({
            // filials: 'getFilials',
            selectedFilial: 'getSelectedFilial',
            userInfo: 'getUser',
            permissions: 'getPermissions',
        }),
        // branches() {
        //     return this.$store.getters['getBranches']
        // },
        // darkMode() {
        //     return !!Number(localStorage.getItem('dark-mode'))
        // }
        isReportsShown() {
            return (this.permissions.events 
            || this.permissions.deletes 
            || this.permissions['write-offs'])
        },
        isAnalyticsShown() {
            return (
            this.permissions.dashboard 
            // || this.permissions.weather
            // || this.permissions.clients
            // || this.permissions.failures
            || this.permissions['top-orders']
            )
        }
    }
}
</script>

<style lang="scss" scoped>
.menu {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 240px;
    min-height: 650px;
    background: #E83D46;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 999;
    transition: .3s all;
    @media (max-width: 1350px) {
        /* display: none; */
        width: 100vw;
        height: 70px;
        min-height: auto;
        &_opened {
            height: 100vh;
        }
    }
    &__submenu-overlay {
        width: calc(100vw - 240px);
        background: rgba(0, 0, 0, 0.33);
        position: absolute;
        transition: .3s opacity;
        height: 100vh;
        opacity: 0;
        pointer-events: none;
        top: 0;
        left: 240px;
        z-index: 999;
        &_active {
            opacity: 1;
            pointer-events: all;
        }
    }
    &__submenu {
        position: absolute;
        top: 0;
        height: 100vh;
        left: 0;
        width: 240px;
        pointer-events: none;
        cursor: default;
        transform: translateX(240px);
        &_active {
            pointer-events: all;
            & .menu__submenu-inner {
                width: 240px;
            }
            & .menu__submenu-links {
                opacity: 1;
                transform: none;
            }
        }
    }
    &__submenu-mobile {
        position: absolute;
        left: -100%;
        top: 70px;
        width: 100vw;
        height: calc(100vh - 70px);
        transition: .3s all;
        z-index: 999999;
        &_active {
            left: 0;
        }
    }
    &__submenu-inner {
        background: #fff;
        width: 0;
        height: 200vh;
        position: absolute;
        transition: .3s all;
        top: -100vh;
        opacity: 1;
        @media (max-width: 1350px) {
            position: static;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 16px;
        }
    }
    &__submenu-links {
        position: absolute;
        top: 100vh;
        display: flex;
        flex-direction: column;
        gap: 18px;
        justify-content: flex-start;
        align-items: flex-start;
        opacity: 0;
        transition: .3s all;
        width: 100%;
        transform: scaleX(.5);
        @media (max-width: 1350px) {
            position: static;
            opacity: 1;
            transform: none;
            padding-left: 30px;
        }
    }
    &__submenu-link {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #A7A7A7;
        transition: .3s all;
        text-decoration: none;
        padding: 5px 0;
        width: 100%;
        text-align: left;
        padding-left: 20px;
        &:hover {
            color: #E83D46;
        }
        &_active {
            color: #E83D46;
        }
    }
    &__bottom {
        background: #E83D46;
        transition: .3s all;
    }
    &__mobile {
        background: #E83D46;
        transition: .3s all;
        height: calc(100vh - 70px);
        flex-direction: column;
        justify-content: space-between;
        gap: 85px;
        z-index: 99999;
        transition: .3s all;
        transform: translateY(-100%);
        position: absolute;
        width: 100%;
        top: 70px;
        left: 0;
        overflow: auto;
        &_active {
            transform: none;
        }
    }
    &__top {
        position: relative;
        z-index: 999990;
        background: #E83D46;
        transition: .3s all;
        @media (max-width: 1350px) {
            display: flex;
            justify-content: space-between;
            // padding: 7px 15px;
            padding: 7px 20px;
            align-items: center;
            gap: 5px;
        }
    }
    &__burger {
        width: 40px;
        min-width: 40px;
        height: 28px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: relative;
        &::before, &::after {
            content: '';
            width: 40px;
            height: 4px;
            background: #fff;
            display: block;
            transition: .3s all;
            transform-origin: 0% 15%;
        }
        &_active {
            & .menu__burger-line {
                opacity: 0;
                height: 0;
            }
            &::before, &::after {
                transform: rotate(45deg) translate(-50%, -50%);
                position: absolute;
                top: 50%;
                left: 50%;
            }
            &::after {
                transform: rotate(-45deg) translate(-50%, -50%);
            }
        }
    }
    &__burger-line {
        width: 100%;
        height: 4px;
        background: #fff;
        transition: .3s all;
    }
    &__log-out {
        background: transparent;
        border: none;
        cursor: pointer;
    }
    &__account-icon {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        & img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover;
        }
    }
    &__account-info {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    &__account-name {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        color: #FFFFFF;
    }
    &__account-position {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 120%;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        opacity: 0.5;
    }
    &__account {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        margin: 25px auto 0;
        max-width: calc(100% - 40px);
        border-top: 1px solid rgba(255,255,255,.25);
    }
    &__admin-link {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;
        margin-bottom: 10px;
    }
    &__dark-mode {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;
    }
    &__dark-mode-text {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 155%;
        color: #FFFFFF;
        transition: .3s all;
        display: flex;
        align-items: center;
        gap: 10px;
        & span {
            margin-top: 5px;
        }
    }
    &__filial-selector {
        width: 200px;
        height: 56px;
        display: block;
        margin: 20px auto 30px;
        cursor: pointer;
        border: none;
        background: #D52F38;
        transition: .3s all;
        border-radius: 10px;
        @media (max-width: 1350px) {
            margin: 0;
            width: 170px;
        }
    }
    &__logo {
        display: flex;
        justify-content: center;
        margin: 23px auto 0;
        // width: 95px;
        width: 100%;
        max-width: 170px;
        // padding: 0px 15px;
        @media (max-width: 1350px) {
            // width: 53px;
            max-width: 150px;
            margin: 0;
        }
        // @media (max-width: 500px) {
        //     display: none;
        // }
        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__links {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: -1;
        @media (max-width: 1350px) {
            // padding-top: 25px;
            padding-top: 56px;
        }
    }
    &__link {
        display: flex;
        align-items: center;
        gap: 15px;
        position: relative;
        padding: 10px 30px;
        text-decoration: none;
        transition: .3s all;
        border: none;
        background: transparent;
        cursor: pointer;
        z-index: -2;
        &:before {
            content: '';
            display: block;
            height: 100%;
            width: 3px;
            background: #F9A620;
            position: absolute;
            left: -3px;
            top: 0;
            transition: .3s all;
        }
        &:hover {
            background: rgba(38, 36, 36, .1);
            &:before {
                left: 0;
            }
            & .menu__link-name {
                color: #FFFFFF;
            }
        }
        &_active {
            &:before {
                left: 0;
                background: #fff;
            }
            & .menu__link-name {
                color: #FFFFFF;
            }
        }
        @media (max-width: 1350px) {
            padding: 10px 20px;
            &::before {
                display: none;
            }
            &:hover {
                background: transparent;
            }
        }
    }
    &__link-name {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 155%;
        color: rgba(255, 255, 255, .66);
        text-decoration: none;
        transition: .3s all;
    }
}

.admin-link {
    &__text {
        text-decoration: none;
    }
}

.dark-mode {
    .menu {
        background: #262424;
        &__bottom {
            background: #262424;
        }
        &__mobile {
            background: #262424;
        }
        &__top {
            background: #262424;
        }
        &__dark-mode-text {
            color: #A7A7A7;
        }
        &__filial-selector {
            background: #2C2C2C;
        }
        &__submenu-inner {
            background: #3C3A3A;
            @media (max-width: 1350px) {
                background: #262424;
            }
        }
        &__submenu-link {
            color: rgba(255,255,255,.5);
            &:hover {
                color: #fff;
            }
            &_active {
                color: #fff;
            }
            @media (max-width: 1350px) {
                color: #fff;
            }
        }
        &__submenu-back {
            & rect {
                fill: #fff;
            }
        }
    }
}
</style>
