import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import tables from './components/registers/tables'
import yandex from './components/registers/yandex'
import titles from './components/registers/titles'
import globals from './components/registers/globals'
import charts from './components/registers/charts'
import blocks from './components/registers/blocks'
import helpers from './components/registers/helpers'
import preloaders from './components/registers/preloaders'

import clickOutside from "@/directives/click-outside"
import VueTheMask from 'vue-the-mask'
// import AOS from 'aos'
// import 'aos/dist/aos.css'
// import AOSconfig from "@/assets/script/AOSconfig";

// window.AOS = AOS

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    // AOS.init(AOSconfig)
  }
})

yandex(Vue);
tables(Vue);
titles(Vue);
globals(Vue);
charts(Vue);
blocks(Vue);
helpers(Vue);
preloaders(Vue);

Vue.use(VueTheMask)

clickOutside(Vue)

Vue.prototype.$screen = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight
});

window.addEventListener('resize', () => {
  Vue.prototype.$screen.width = window.innerWidth;
  Vue.prototype.$screen.height = window.innerHeight;
});

app.$mount('#app')
