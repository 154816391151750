<template>
    <div class="calendar">
        <div class="calendar__menu">
            <div class="calendar__menu-info desktop">
                <button class="calendar__arrow-prev">
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5" d="M6 11L2 6L6 1" stroke="#626262" stroke-width="2"/>
                    </svg>
                </button>
                <div class="calendar__menu-date">{{ period.length === 2 ? period[0] + "-" + period[1] : 'Выберите дату' }}</div>
                <button class="calendar__arrow-next">
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 11L5 6L1 1" stroke="#626262" stroke-width="2"/>
                    </svg>
                </button>
            </div>
            <button 
                @click="openCalendar"
                class="calendar__menu-btn">
                <svg v-if="!calendarOpened" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.3335 5.49992H5.50016V4.58325H3.66683V7.33325H18.3335V4.58325H16.5002V5.49992H14.6668V4.58325H7.3335V5.49992ZM18.3335 9.16658H3.66683V18.3333H18.3335V9.16658ZM14.6668 2.74992H7.3335V1.83325H5.50016V2.74992H3.66683C2.65431 2.74992 1.8335 3.57073 1.8335 4.58325V18.3333C1.8335 19.3458 2.65431 20.1666 3.66683 20.1666H18.3335C19.346 20.1666 20.1668 19.3458 20.1668 18.3333V4.58325C20.1668 3.57073 19.346 2.74992 18.3335 2.74992H16.5002V1.83325H14.6668V2.74992ZM6.41683 12.8333V10.9999H8.25016V12.8333H6.41683ZM10.0835 12.8333H11.9168V10.9999H10.0835V12.8333ZM15.5835 12.8333H13.7502V10.9999H15.5835V12.8333ZM6.41683 14.6666V16.4999H8.25016V14.6666H6.41683ZM11.9168 16.4999H10.0835V14.6666H11.9168V16.4999Z" fill="#E83D46"/>
                </svg>
                <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.2929L10 13.5858L7.70713 11.2929L6.29291 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.2929Z" fill="#E83D46"/>
                </svg>
            </button>
        </div>
        <div :class="['calendar__calendar', {'calendar__calendar_active': calendarOpened}]">
            <div class="calendar__header">
                <div class="calendar__header-left">
                    <div class="calendar__chooses-month">{{ monthToText }}</div>
                    <button @click="openYearChoose" class="calendar__choose-month">
                        {{ currentYear }} г
                        <svg :style="{transform: `rotate(${yearChooseOpened ? 180 + 'deg' : 0})`}" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 1L4 4L1 1" stroke="#101010" stroke-width="2"/>
                        </svg>
                    </button>
                </div>
                <div class="calendar__header-arrows">
                    <button class="calendar__arrow-prev">
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" d="M6 11L2 6L6 1" stroke="#626262" stroke-width="2"/>
                        </svg>
                    </button>
                    <button class="calendar__arrow-next">
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 11L5 6L1 1" stroke="#626262" stroke-width="2"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="calendar__row">
                <div class="calendar__cell" v-for="day in dayWeeks" :key="day">
                    {{ day }}
                </div>
            </div>
            <div class="calendar__container">
                <button 
                    :class="['calendar__cell', {
                        'calendar__cell_disabled': !day,
                        'calendar__cell_current': day === today,
                        'calendar__cell_picked': firstPicked === day || lastPicked === day,
                        'calendar__cell_in-period': day > firstPicked && day < lastPicked,
                        'calendar__cell_first-picked' : firstPicked === day && lastPicked,
                        'calendar__cell_last-picked' : lastPicked === day && firstPicked,
                    }]"
                    :disabled="!day"
                    @click="pickDay(day)"
                    v-for="day in computedDays" 
                    :key="day">
                    {{ day }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'calendar-block',
    data() {
        return {
            calendarOpened: false,
            yearChooseOpened: false,
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
            today: new Date().getDate(),
            firstPicked: null,
            lastPicked: null,
            period: []
        }
    },
    computed: {
        choosesMonth() {
            return this.currentMonth;
        },
        startWeekDay() {
            const periodSlugIndex = this.$store.getters['getGlobalFilterIndex']
            let month = null

            switch (periodSlugIndex) {
                case 0:
                    month = new Date().getMonth()
                    break;
            
                default:
                    break;
            }

            console.log(periodSlugIndex)
            // return new Date(new Date().getFullYear(), this.month, 0).getDay();
            return new Date(new Date().getFullYear(), month).getDay();
        },
        computedDays() {
            const arr = [];
            for (let i = 1; i < this.startWeekDay; i++) {
                arr.push('')
            }
            for (let i = 1; i <= new Date(new Date().getFullYear(), this.choosesMonth, 0).getDate(); i++) {
                arr.push(i)
            }
            return arr
        },
        months() {
            return this.$store.state.calendarStore.months
        },
        dayWeeks() {
            return this.$store.state.calendarStore.weekDays
        },
        monthToText() {
            return this.months[this.choosesMonth]
        },
    },
    methods: {
        addZeroToNumber(number) {
            if (number.toString().length === 1)
                return '0' + number.toString()
            else 
                return number
        },
        openCalendar() {
            this.calendarOpened = !this.calendarOpened;
            if (!this.calendarOpened) {
                this.period[0] = `${this.addZeroToNumber(this.firstPicked)}.${this.addZeroToNumber(this.choosesMonth + 1)}.${this.currentYear.toString().substring(2)}`;
                this.period[1] = `${this.addZeroToNumber(this.lastPicked)}.${this.addZeroToNumber(this.choosesMonth + 1)}.${this.currentYear.toString().substring(2)}`;

                this.yearChooseOpened = false;
            }
        },
        openYearChoose() {
            this.yearChooseOpened = !this.yearChooseOpened
        },
        createDayPeriod() {

        },
        pickDay(day) {
            
            if (!this.firstPicked) this.firstPicked = day;
            else if (!this.lastPicked) {
                if (day > this.firstPicked) {
                    this.lastPicked = day;
                } else {
                    this.lastPicked = this.firstPicked;
                    this.firstPicked = day;
                }
                
            } else {
                this.firstPicked = day;
                this.lastPicked = null
            }
            // else this.firstPicked = day;
        }
    },
}
</script>

<style lang="scss" scoped>
.calendar {
    width: 300px;
    position: relative;
    height: 50px;
    @media (max-width: 1350px) {
        width: 50px;       
    }
    &__container {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        justify-items: center;
        margin-top: 10px;
        row-gap: 10px;
        padding: 0 10px;
        & .calendar__cell {
            background-color: #fff;
            transition: .3s all;
            /* padding: 10px; */
            padding: 0px;
            /* margin: 10px; */
            color: #000000;
            /* width: calc((300px - 60px) / 7);
            height: calc((300px - 60px) / 7); */
            width: 100%;
            height: 34px;
            border: 1px solid transparent;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
                border: 1px solid #C83E47;
            }
            &[disabled] {
                border: 0px;
                cursor: auto;
                &:hover {
                    border: 0px;
                }
            }
            &_current {
                font-weight: 700;
            }
            &_picked {
                background: #C83E47;
                color: #fff;
                border-radius: 100%;
                border: 1px solid #C83E47;
                margin: 0;
                font-weight: 500;
            }
            &_in-period {
                margin: 0;
                background: rgba(200, 62, 71, .3);
                color: #fff;
                border-radius: 0%;
                border: none;
                font-weight: 500;
            }
            &_last-picked {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            &_first-picked {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
    &__row {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        justify-items: center;
    }
    &__cell {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #A7A7A7;
        display: flex;
        align-items: center;
        text-align: center;
    }
    &__header-arrows {
        display: flex;
        gap: 25px;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
    }
    &__header-left {
        display: flex;
        font-family: 'Mail Sans Roman';
        font-style: normal;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 155%;
        color: #262424;
        gap: 5px;
    }
    &__choose-month {
        background: #fff;
        border: none;
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 155%;
        cursor: pointer;
        color: #262424;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        & svg {
            transition: .3s all;
        }
    }
    &__calendar {
        padding: 20px;
        position: absolute;
        bottom: -5px;
        transform: translateY(100%);
        opacity: 0;
        pointer-events: none;
        transition: .3s all;
        width: 100%;
        background: #FFFFFF;
        border-radius: 10px;
        &_active {
            opacity: 1;
            pointer-events: all;
        }
        @media (max-width: 1350px) {
            bottom: unset;
            width: 300px;
            top: -5px;
            right: 0;
            transform: translateY(-100%);
        }
    }
    &__menu {
        height: 50px;
        display: flex;
        background: #fff;
        border-radius: 10px;
        justify-content: space-between;
    }
    &__menu-info {
        display: flex;
        justify-content: center;
        gap: 30px;
        align-items: center;
        border-right: 1px solid #D3D3D3;
        width: 100%;
    }
    &__menu-date {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 155%;
        text-align: center;
        color: #262424;
    }
    &__arrow-next, &__arrow-prev {
        cursor: pointer;
        background: #fff;
        border: none;
        margin-top: 3px;
    }
    &__menu-btn {
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: .3s all;
        border: none;
        background: #fff;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        max-width: 50px;
        &:hover {
            background: rgba(232, 61, 70, .15);
        }
        @media (max-width: 1350px) {
            border-radius: 10px;
            &:hover {
                background: #fff;
            }
        }
    }
}
</style>