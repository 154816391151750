<template>
    <div class="table">
        <table>
            <tr>
                <th v-for="(item, i) of header" :style="{ textAlign: i !== 1 ? 'center' : 'left' }">{{ item }}</th>
            </tr>
            <!-- count:549
            difference:94
            :"- с собой пицца"
            prev_count:1524 -->
            <tbody class="table__tbody" v-if="emptyData">
                <p class="table__notification notification">Нет данных</p>
            </tbody>
            <tbody class="table__tbody" v-else>
                <tr v-for="(tr, i) in data" :key="i">
                    <!-- :class="{'max-width': maxWidth === j}" -->
                    <!-- :style="{textAlign: j !== 1 ? 'center' : 'left'}" -->
                    <td :style="{ textAlign: 'center' }">
                        {{ i + 1 }}
                    </td>
                    <td :style="{ textAlign: 'left' }" class="max-width">
                        {{ tr.name }}
                    </td>
                    <td :style="{ textAlign: 'center' }">
                        <span class="value">{{ tr.count }} </span>
                        <span class="value__prev">(Предыдущее: {{ tr.prev_count }})</span>
                    </td>
                    <td :style="{ textAlign: 'center' }">
                        <increase-value :success="tr.count > tr.prev_count" :value="tr.difference">
                        </increase-value>
                    </td>
                    <!-- <component 
                    :is="i === 0 ? 'th' : 'td'" 
                    :class="{'max-width': maxWidth === j}"
                    v-for="(td, j) in tr" 
                    :key="returnKey(td)"
                    :style="{textAlign: j !== 1 ? 'center' : 'left'}"
                >
                    <template v-if="td.label">
                        {{ td.label }} <span>(Предыдущее: {{ td.prev }})</span>
                    </template>
                    <template v-else-if="td.percent">
                        <increase-value
                            :success="td.status"
                            :value="td.percent"
                        >
                        </increase-value>
                    </template>
                    <template v-else>
                        {{ td }}
                    </template>
                </component> -->
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'dynamic-table',
    props: ['data', 'maxWidth'],
    data() {
        return {
            header: ['Ранжирование', 'Предмет', 'Заказы, шт.', 'Динамика'],
        }
    },
    computed: {
        emptyData() {
            return this.data !== null && this.data !== undefined ? this.data.length === 0 : true
        },
    },
    methods: {
        returnKey() {
            return Math.random()
        },
    },
}
</script>

<style lang="scss" scoped>
.table {
    @media (max-width: 1350px) {
        overflow-x: scroll;
        margin-top: 20px;
        overflow-y: hidden;
    }

    @media (max-width: 796px) {
        position: relative;
        width: calc(100vw - 16px);
    }

    &__tbody {
        &:has(.notification) {
            height: 350px;
            position: relative;
        }
    }

    & table {
        background: #FFFFFF;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
        border-collapse: collapse;
        max-width: 1050px;
        width: 100%;
        min-width: 1050px;

        & td,
        & th {
            border: 1px solid rgba(0, 0, 0, .1);
            padding: 8px;
            min-width: 70px;
            font-weight: 600;
            font-size: 12px;
            line-height: 110%;
            color: #262424;
            white-space: nowrap;
            text-align: left;
            transition: .3s all;
            position: relative;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }

            &.max-width {
                width: 100%;
                // max-width: 120px;
            }

            & span {
                // color: #A7A7A7;
            }
        }

        & th {
            padding-top: 15px;
            padding-bottom: 15px;
        }

        & td {
            font-weight: 400;
            font-size: 12px;
            color: #101010;
        }

        & tr {
            width: 100%;

            &:first-child {

                & th,
                & td {
                    border-top: none;
                }
            }

            &:last-child {

                & th,
                & td {
                    border-bottom: none;
                }
            }

            &.use-bg {
                &:hover {

                    & td,
                    & th {
                        background: rgba(249, 166, 32, 0.2);

                        &.expand {
                            background: rgba(249, 166, 32, .3);
                        }
                    }
                }
            }
        }
    }

    &__notification {
        font-size: 48px;
    }
}

.dark-mode {
    .table {
        & table {
            background: #262424;

            & td,
            & th {
                border-color: rgba(255, 255, 255, .1);
                color: #fff;

                &:before {
                    background: rgba(232, 61, 70);
                    color: #fff;
                }
            }

            & td {
                color: #a7a7a7;
            }

            & tr {
                &.use-bg {
                    &:hover {

                        & td,
                        & th {
                            background: rgba(232, 61, 70, 0.2);

                            &.expand {
                                background: rgba(232, 61, 70, .3);
                            }
                        }
                    }
                }
            }
        }
    }

    .value {
        color: #FFFFFF;
    }
}

.value {
    // font-family: 'Commissioner';
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
    color: #101010;

}

.value__prev {
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
    color: #A7A7A7;
}</style>