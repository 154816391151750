<template>
  <div :class="[ 'map', theme ]">
    <yandex-map
      :key="theme"
      @map-was-initialized="mapInit"
      :settings="mapSettings"
      :behaviors="behaviors"
      :coords="[41.303917, 69.270971]"
      :zoom="12"
      :controls="[]"
      :show-all-markers="false"
    >
    </yandex-map>
  </div>
</template>

<script>
// import { loadYmap } from 'vue-yandex-maps'

export default {
  name: 'HeatmapBlock',
  props: {
      heatPoints: { type: Array },
      markers: { type: Array },
      showMarkers: { type: Boolean, required: false, default: false },
      theme: { 
          type: String, 
          required: false, 
          default: 'light',
          validator: function (value) {
              return ['light', 'dark'].includes(value)
          }
      },
  },
  data() {
      return {
        map: null,
      }
  },
  computed: {
    mapSettings() {
        return this.$store.state.mapStore.mapSettings
    },
    behaviors() {
        if (this.$screen.width > 1350) return ['default']
        else return ['dblClickZoom', 'multiTouch']
    },
    heatPointsFormatted() {
      if (this.heatPoints) {
        return this.heatPoints.map(item => [item.latitude, item.longitude])
      }
      else {
        return null
      }
    },
  },
  watch: {
    showMarkers() {
      if (this.showMarkers) {
        this.initMarkers(this.map, this.markers)
      }
      else {
        this.removeMarkers(this.map)
      }
    }
  },
  methods: {
    async mapInit(map) {
      this.map = map
      const heatPoints = this.heatPointsFormatted
      this.initMarkers(map, this.markers)
      this.initHeatmap(map, heatPoints)
      this.setMapStyle(map, this.theme)
      window.myMap = map
    },
    initMarkers(map, markers) {
      if (!this.showMarkers || !this.markers || !this.markers.length > 0) return

      const myMarkers = markers
      const markerImg = this.markerImg

      const placemarkColors = [
        ...myMarkers.map(item => item.is_build ? '#E83D46' : '#F9A620')
      ]

      const clusterer = new ymaps.Clusterer({
        // Макет метки кластера pieChart.
        clusterIconLayout: 'default#pieChart',
        // Радиус диаграммы в пикселях.
        clusterIconPieChartRadius: 25,
        // Радиус центральной части макета.
        clusterIconPieChartCoreRadius: 16,
        // Ширина линий-разделителей секторов и внешней обводки диаграммы.
        clusterIconPieChartStrokeWidth: 0,
        // Определяет наличие поля balloon.
        hasBalloon: false
      })

      const points = [
        ...myMarkers.map(item => [item.lat, item.long])
      ]

      const geoObjects = [];

      for (var i = 0, len = points.length; i < len; i++) {
        geoObjects[i] = new ymaps.Placemark(points[i], { iconContent: String(i+1) }, markerImg(myMarkers[i], i));
      }

      clusterer.add(geoObjects);
      map.geoObjects.add(clusterer);

      // map.setBounds(clusterer.getBounds(), {
      //   checkZoomRange: true
      // });
    },
    removeMarkers(map) {
      myMap.geoObjects.each(geoObject => {
        if (geoObject.options._name === 'clusterer') {
          geoObject.removeAll()
        }
      })
    },
    initHeatmap(map, points) {
      const script = this.loadHeatmap()

      script.onload = function () {
        ymaps.modules.require(['Heatmap'], function (Heatmap) {
          let data = [];
          for (let i = 0; i < points.length; i++) {
            data.push([Number(points[i][0]), Number(points[i][1])])
          }

          let heatmap = new Heatmap(data, {
            // Радиус влияния.
            radius: 15,
            // Нужно ли уменьшать пиксельный размер точек при уменьшении зума. False - не нужно.
            dissipating: false,
            // Прозрачность тепловой карты.
            opacity: 0.8,
            // Прозрачность у медианной по весу точки.
            intensityOfMidpoint: 0.2,
            // JSON описание градиента.
            gradient: {
              0.1: 'rgba(128, 255, 0, 0.7)',
              0.2: 'rgba(255, 255, 0, 0.8)',
              0.7: 'rgba(234, 72, 58, 0.9)',
              1.0: 'rgba(162, 36, 25, 1)'
            }
          });
          heatmap.setMap(map);
        });
      };
    },
    loadHeatmap() {
      let script = document.createElement('script')
      script.setAttribute('src', 'https://yastatic.net/s3/mapsapi-jslibs/heatmap/0.0.1/heatmap.min.js')
      document.body.appendChild(script)
      return script
    },
    setMapStyle(map, theme) {
        const copyrightsPane = map.panes._array[2].pane._element
        copyrightsPane.style.display = 'none'
        const groundPane = map.panes._array[1].pane._element.firstElementChild
        
        if (theme === 'dark') {
            groundPane.style.filter = 'grayscale(1) brightness(1) invert(0.888)'
            console.log(groundPane)
        }
        else if (theme === 'light') {
            // groundPane.style.filter = 'sepia(34%) contrast(100%) grayscale(39%) invert(8%)'
            groundPane.style.filter = 'brightness(97%) contrast(97%) sepia(44%) saturate(73%) grayscale(35%) invert(4%)'
            console.log(groundPane)
        }
    },
    markerImg(options, index) {

      const iconContentLayout = ymaps.templateLayoutFactory.createClass(`
        <div style="position: relative; width: 24px; height: 28px;">
            <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 29.7706L12.877 29.0048C20.2516 22.5655 24 16.9428 24 12.0005C24 5.00137 18.5373 0.000488281 12 0.000488281C5.46274 0.000488281 0 5.00137 0 12.0005C0 16.9428 3.74841 22.5655 11.123 29.0048L12 29.7706Z" fill="${options.is_build ? '#E83D46' : '#F9A620'}"/>
                <path d="M12 29.7701L12.877 29.0043C20.2516 22.565 24 16.9423 24 12C24 5.00089 18.5373 0 12 0V29.7701Z" fill="${options.is_build ? '#E83D46' : '#F9A620'}"/>
            </svg>
            <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); color: #FFFFFF; font-size: 18px; font-weight: 700;">$[properties.iconContent]</div>
        </div>
      `)

      return {
          iconLayout : 'default#imageWithContent',
          iconImageSize: [24, 30],
          iconImageOffset: [0, 0],
          imageHref: '',
          content: 1,
          contentOffset: [-12, -15],
          iconColor: options.is_build ? '#E83D46' : '#F9A620',
          iconContentLayout : iconContentLayout,
      }
    },
    stringifiedKey(data) {
      return JSON.stringify(data)
    },
  },
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 420px;
  background-blend-mode: color, normal;
  border: 1px solid #E4DFD5;
  /*border-radius: 10px;*/

  &.dark {
      border: 1px solid #262424;
  }

  @media (max-width: 1350px) {
      width: 100vw;
      position: relative;
      left: -16px;
      max-height: 600px;
  }
  & .ymap-container {
      height: 420px;
      border-radius: 10px !important;
      &>* {
          border-radius: 10px !important;
          height: 100% !important;
          &>* {
              height: 100% !important;
              border-radius: 10px !important;
          }
      }
  }
}
</style>