<template>
    <div class="table">
        <table>
            <thead class="table__thead">
                <tr>
                    <th v-for="th of tHead">{{ th }}</th>
                </tr>
            </thead>
            <tbody class="table__tbody" v-if="branchUnselected">
                <p class="table__notification notification">Выберите филиал</p>
            </tbody>
            <tbody class="table__tbody" v-else-if="emptyData">
                <p class="table__notification notification">Нет данных</p>
            </tbody>
            <tbody class="table__tbody" v-else>
                <tr :class="{ 'use-bg': params.bg }" v-for="(tr, i) in data" :key="i">
                    <td v-for="(td, j) in tr"
                        :class="{ 'expand': j === 0 ? expandClass(td.data, 66).expand : j === 3 ? expandClass(td.data, 15).expand : expandClass(td.data, 60).expand, 'max-width': params.maxWidth === j }"
                        :key="td.data" :data-expand="td.data" :style="{
                            width: params.widths ? params.widths[j] + '%' : '',
                            paddingLeft: td.level ? td.level * 20 + 'px' : ''
                        }">
                        <!-- {{ j === 0 ? expandClass(td.data, 66).text : j === 3 ? expandClass(td.data, 15).text : expandClass(td.data, 60).text }} -->
                        <span class="td__inner">{{ expandClass(td.data, 60).text }}</span>
                    </td>
                    <!-- <component 
                    :is="i === 0 || td.header ? 'th' : 'td'" 
                    :class="{'expand': expandClass(td.data).expand, 'max-width': params.maxWidth === j}"
                    v-for="(td, j) in tr" 
                    :key="td.data"
                    :data-expand="td.data"
                    :style="{width: params.widths ? params.widths[j] + '%' : '',
                            paddingLeft: td.level ? td.level * 20 + 'px' : ''}">
                    {{ expandClass(td.data).text}}
                </component> -->
                </tr>
            </tbody>
            <tfoot class="table__tfoot">
                <tr>
                    <td v-for="th of tFoot" :class="{ 'semi-bold': th.bold }">{{ th.data }}</td>
                </tr> 
            </tfoot>
        </table>
    </div>
</template>

<script>
import separateText from '@/assets/script/separateText';
export default {
    props: ['data', 'params', 'tHead', 'tFoot'],
    computed: {
        selectedBranch() {
            return this.$store.getters['getBranchId']
        },
        branchUnselected() {
            return this.selectedBranch === null
        },
        emptyData() {
            return this.data !== null ? this.data.length === 0 : true
        },
    },
    methods: {
        expandClass(text, maxLength) {
            return text.length > maxLength && this.params.expand ? {
                expand: true,
                text: this.separateText(text, maxLength)
            } : {
                expand: false,
                text: text
            }
        },
        separateText
    },
}
</script>

<style lang="scss" scoped>
.table {

    @media (max-width: 1350px) {
        overflow-x: scroll;
        margin-top: 20px;
        overflow-y: hidden;
    }

    @media (max-width: 796px) {
        position: relative;
        width: calc(100vw - 16px);
    }

    &__notification {
        font-size: 48px;
    }

    & table {
        background: #FFFFFF;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
        border-collapse: collapse;
        max-width: 1050px;
        width: 100%;
        min-width: 1050px;

        & td,
        & th {
            border: 1px solid rgba(0, 0, 0, .1);
            padding: 8px;
            min-width: 70px;
            font-weight: 600;
            font-size: 12px;
            line-height: 110%;
            color: #262424;
            white-space: nowrap;
            text-align: left;
            transition: .3s all;
            position: relative;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }

            &:before {
                content: attr(data-expand);
                position: absolute;
                width: 445px;
                height: auto;
                padding: 15px;
                background: #FEEDD2;
                box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
                border-radius: 10px;
                opacity: 0;
                pointer-events: none;
                transition: .3s all;
                white-space: break-spaces;
                z-index: 10;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-weight: 400;
                font-size: 12px;
                line-height: 155%;
                color: #101010;
            }

            &.expand {
                &:hover {
                    &::before {
                        opacity: 1;
                        pointer-events: all;
                    }
                }
            }
        }

        & th {
            padding-top: 15px;
            padding-bottom: 15px;
        }

        & td {
            font-weight: 400;
            font-size: 12px;
            color: #101010;
        }

        & tr {
            width: 100%;

            &:first-child {

                & th,
                & td {
                    border-top: none;
                }
            }

            &:last-child {

                & th,
                & td {
                    border-bottom: none;
                }
            }

            &.use-bg {
                &:hover {

                    & td,
                    & th {
                        background: rgba(249, 166, 32, 0.2);

                        &.expand {
                            background: rgba(249, 166, 32, .3);
                        }
                    }
                }
            }
        }
    }

    &__tbody {

        &:has(.notification) {
            height: 350px;
            position: relative;
        }

        & tr {
            & td {}

            & td:nth-child(3) {
                max-width: 100px;
                white-space: break-spaces;
            }

            & td:nth-child(4) {
                max-width: 75px;
                // white-space: break-spaces;
                // overflow-x: hidden;

            }
        }
    }

    &__tfoot {
        & .semi-bold {
            font-weight: 600;
        }
    }
}

.dark-mode {
    .table {
        & table {
            background: #262424;

            & td,
            & th {
                border-color: rgba(255, 255, 255, .1);
                color: #fff;

                &:before {
                    background: rgba(232, 61, 70);
                    color: #fff;
                }
            }

            & td {
                color: #a7a7a7;
            }

            & tr {
                &.use-bg {
                    &:hover {

                        & td,
                        & th {
                            background: rgba(232, 61, 70, 0.2);

                            &.expand {
                                background: rgba(232, 61, 70, .3);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>