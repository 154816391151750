<template>
    <div :class="[ 'map', theme ]">
        <!-- :show-all-markers="true" -->
        <!-- :cluster-options="clusterOptions" -->
        <yandex-map
            :key="theme"
            @map-was-initialized="mapInit"
            :settings="mapSettings"
            :behaviors="behaviors"
            :coords="[41.303917, 69.270971]"
            :zoom="12"
            :controls="[]"
        >
            <!-- cluster-name="mark" -->
            <!-- <yandex-map-marker 
                v-for="(marker, i) in markers"
                :key="i"
                :coords="marker.coords || [marker.lat, marker.long]" 
                :markerId="i"
                :icon="markerImg(marker)">
            </yandex-map-marker> -->
        </yandex-map>
    </div>
</template>

<script>
export default {
    props: {
        markers: { type: Array },
        theme: { 
            type: String, 
            required: false, 
            default: 'light',
            validator: function (value) {
                return ['light', 'dark'].includes(value)
            }
        },
    },
    data() {
        return {
            // clusterOptions: {
            //     mark: {
            //         clusterIconLayout: "default#pieChart",
            //         // clusterIconColor: '#E83D46',
            //         clusterIconPieChartRadius: 25,
            //         // Радиус центральной части макета.
            //         clusterIconPieChartCoreRadius: 15,
            //         // Ширина линий-разделителей секторов и внешней обводки диаграммы.
            //         clusterIconPieChartStrokeWidth: 0,
            //         // Определяет наличие поля balloon.
            //         hasBalloon: false
            //     }
            // }
        }
    },
    computed: {
        mapSettings() {
            return this.$store.state.mapStore.mapSettings
        },
        behaviors() {
            if (this.$screen.width > 1350) return ['default']
            else return ['dblClickZoom', 'multiTouch']
        },
        // markers() {
        //     return this.$store.getters['getMarkers']
        // },
    },
    methods: {
        mapInit(map) {
            this.setMapStyle(map, this.theme)
            const myMarkers = this.markers
            const markerImg = this.markerImg

            const placemarkColors = [
                ...myMarkers.map(item => item.is_build ? '#E83D46' : '#F9A620')
            ]

            const clusterer = new ymaps.Clusterer({
                // Макет метки кластера pieChart.
                clusterIconLayout: 'default#pieChart',
                // Радиус диаграммы в пикселях.
                clusterIconPieChartRadius: 25,
                // Радиус центральной части макета.
                clusterIconPieChartCoreRadius: 16,
                // Ширина линий-разделителей секторов и внешней обводки диаграммы.
                clusterIconPieChartStrokeWidth: 0,
                // Определяет наличие поля balloon.
                hasBalloon: false
            })
    
            const points = [
                ...myMarkers.map(item => [item.lat, item.long])
            ]

            const geoObjects = [];

            // for (var i = 0, len = points.length; i < len; i++) {
            //     geoObjects[i] = new ymaps.Placemark(points[i], {}, {
            //         iconColor: placemarkColors[i]
            //     });
            // }
            for (var i = 0, len = points.length; i < len; i++) {
                geoObjects[i] = new ymaps.Placemark(points[i], { iconContent: String(i+1) }, markerImg(myMarkers[i], i));
            }

            clusterer.add(geoObjects);
            map.geoObjects.add(clusterer);

            map.setBounds(clusterer.getBounds(), {
                checkZoomRange: true
            });
        },
        setMapStyle(map, theme) {
            const copyrightsPane = map.panes._array[2].pane._element
            copyrightsPane.style.display = 'none'
            const groundPane = map.panes._array[1].pane._element
            
            if (theme === 'dark') {
                groundPane.style.filter = 'grayscale(1) brightness(1) invert(0.888)'
            }
            else if (theme === 'light') {
                // groundPane.style.filter = 'sepia(34%) contrast(100%) grayscale(39%) invert(8%)'
                groundPane.style.filter = 'brightness(97%) contrast(97%) sepia(44%) saturate(73%) grayscale(35%) invert(4%)'
            }
        },
        markerImg(options, index) {

            const iconContentLayout = ymaps.templateLayoutFactory.createClass(`
            <div style="position: relative; width: 24px; height: 28px;">
                <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 29.7706L12.877 29.0048C20.2516 22.5655 24 16.9428 24 12.0005C24 5.00137 18.5373 0.000488281 12 0.000488281C5.46274 0.000488281 0 5.00137 0 12.0005C0 16.9428 3.74841 22.5655 11.123 29.0048L12 29.7706Z" fill="${options.is_build ? '#E83D46' : '#F9A620'}"/>
                    <path d="M12 29.7701L12.877 29.0043C20.2516 22.565 24 16.9423 24 12C24 5.00089 18.5373 0 12 0V29.7701Z" fill="${options.is_build ? '#E83D46' : '#F9A620'}"/>
                </svg>
                <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); color: #FFFFFF; font-size: 18px; font-weight: 700;">$[properties.iconContent]</div>
            </div>
            `)

            return {
                iconLayout : 'default#imageWithContent',
                iconImageSize: [24, 30],
                iconImageOffset: [0, 0],
                imageHref: '',
                content: 1,
                contentOffset: [-12, -15],
                iconColor: options.is_build ? '#E83D46' : '#F9A620',
                iconContentLayout : iconContentLayout,
            }
        },
        // markerImg(options) {
        //     return {
        //         layout: 'default#imageWithContent',
        //         imageHref: '',
        //         imageSize: [24, 30],
        //         imageOffset: [0, 0],
        //         content: 1,
        //         contentOffset: [-12, -15],
        //         iconColor: options.is_build ? '#E83D46' : '#F9A620',
        //         contentLayout: `
        //             <div style="position: relative; width: 24px; height: 28px;">
        //                 <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                     <path fill-rule="evenodd" clip-rule="evenodd" d="M12 29.7706L12.877 29.0048C20.2516 22.5655 24 16.9428 24 12.0005C24 5.00137 18.5373 0.000488281 12 0.000488281C5.46274 0.000488281 0 5.00137 0 12.0005C0 16.9428 3.74841 22.5655 11.123 29.0048L12 29.7706Z" fill="${options.is_build ? '#E83D46' : '#F9A620'}"/>
        //                     <path d="M12 29.7701L12.877 29.0043C20.2516 22.565 24 16.9423 24 12C24 5.00089 18.5373 0 12 0V29.7701Z" fill="${options.is_build ? '#E83D46' : '#F9A620'}"/>
        //                 </svg>
        //                 <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); color: #FFFFFF; font-size: 18px; font-weight: 700;">$[properties.iconContent]</div>
        //             </div>
        //         `,
        //     }
        // },
    },
}
</script>

<style lang="scss" scoped>
.map {
    width: 100%;
    height: 420px;
    background-blend-mode: color, normal;
    border: 1px solid #E4DFD5;
    /*border-radius: 10px;*/

    &.dark {
        border: 1px solid #262424;
    }

    @media (max-width: 1350px) {
        width: 100vw;
        position: relative;
        left: -16px;
        max-height: 600px;
    }
    & .ymap-container {
        height: 420px;
        border-radius: 10px !important;
        &>* {
            border-radius: 10px !important;
            height: 100% !important;
            &>* {
                height: 100% !important;
                border-radius: 10px !important;
            }
        }
    }
}
</style>