var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['chart', { dark: _vm.theme === 'dark' }]},[(!_vm.isMobile)?_c('div',{staticClass:"chart__wrapper"},[_c('div',{staticClass:"side-block"},[_vm._l((_vm.data),function(item,i){return _c('div',{staticClass:"block",class:{ last: i + 1 === _vm.data.length }},[_c('p',{staticClass:"block__filial"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"block__fact"},[_c('div',{staticClass:"legend__color",style:({ background: _vm.legendColors[2] })}),_c('p',{staticClass:"block__fact-text"},[_vm._v(_vm._s(_vm.sumFormatted(item.fact.value)))])]),_c('div',{staticClass:"block__plan"},[_c('div',{staticClass:"legend__color",style:({ background: _vm.legendColors[0] })}),_c('p',{staticClass:"block__plan-text"},[_vm._v(_vm._s(_vm.sumFormatted(item.plan.value)))])])])}),_c('div',{staticClass:"legend"},[_c('div',{staticClass:"legend__item"},[_c('div',{staticClass:"legend__color",style:({ background: _vm.legendColors[2] })}),_c('span',[_vm._v("- факт")])]),_c('div',{staticClass:"legend__item"},[_c('div',{staticClass:"legend__color",style:({ background: _vm.legendColors[0] })}),_c('span',[_vm._v("- план")])])])],2),_c('div',{ref:"chart-chart",staticClass:"chart__chart"},[_c('div',{ref:"canvas-wrapper",staticClass:"canvas__wrapper"},[_c('canvas',{ref:"canvas",staticClass:"canvas"}),_vm._l((_vm.data),function(item,index){return _c('div',{staticClass:"chart__bars"},[_c('div',{staticClass:"chart__bar",style:({
              background: `linear-gradient(90deg, ${_vm.barColors[0][0]} 0%, ${_vm.barColors[0][1]} 50.52%)`,
              width: _vm.calcWidth(item.plan.value) + '%'
            })},[_c('span',{staticClass:"chart__bar-percent"},[_vm._v("100%")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.plan.value > 0),expression:"item.plan.value > 0"}],staticClass:"chart__bar",style:({
              background: `linear-gradient(90deg, ${_vm.barColors[1][0]} 1.54%, ${_vm.barColors[1][1]} 96.34%)`,
              width: _vm.calcWidth(item.fact.value) + '%'
            })},[_c('span',{staticClass:"chart__bar-percent"},[_vm._v(_vm._s(_vm.factPercent(index)))])])])}),(_vm.isEmptyData)?_c('div',{staticClass:"chart__notification"},[_c('p',{staticClass:"notification"},[_vm._v("Нет данных")])]):_vm._e()],2),(_vm.formattedValues)?_c('div',{ref:"horizontalBar",staticClass:"axis-x"},_vm._l((_vm.formattedValues),function(dig){return _c('span',{staticClass:"axis-x__item"},[_vm._v(_vm._s(dig))])}),0):_vm._e()])]):_c('BarChartMobile',{attrs:{"data":_vm.data,"legendColors":_vm.legendColors,"barColors":_vm.barColors,"counter":_vm.counter,"isEmpty":_vm.isEmptyData,"theme":_vm.theme}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }