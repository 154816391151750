<template>
  <div class="donut" @mouseleave="selectedSlice = null">
    <div class="donut__top">
      <app-title :title="title" level="5"></app-title>
      <div @mouseleave="selectedSlice = null" class="donut__canvas" v-if="validData">
        <div class="donut__inner" :style="{ width: (canvasScreen * .8) + 'px', height: (canvasScreen * .8) + 'px' }">
          <span class="donut__inner-value">{{ innerValue }}</span>
          <span class="donut__inner-counter" v-if="type === 'currency' && currencySimbol !== '$'">{{
            currencySimbol
          }}</span>
          <span class="donut__inner-counter" v-if="type === 'currency' && currencySimbol === '$'">{{
            shortenNumRu(Math.trunc(text)).split(' ')[1]
          }}</span>
          <span class="donut__inner-counter" v-else>{{ counter }}</span>
        </div>
        <chart-dot :isActive="selectedSlice !== null" :top="animation.dot.top" :left="animation.dot.left"
          :color="'#000000'" anim="opacity"></chart-dot>
        <canvas :width="canvasScreen" :height="canvasScreen" @mousemove="hoverOnPieSlice($event)"
          @mouseleave="selectedSlice = null" ref="donut">
        </canvas>
      </div>
      <p v-else class="donut__no-data">нет данных</p>
    </div>
    <div class="donut__bottom" v-if="validData">
      <div class="donut__category">
        <template v-for="(item, key, i) in chartData">
          <div class="donut__category-item" :class="{ 'donut__category-item_selected': selectedSlice === i }" :key="key"
            @mouseenter="selectSlice(i)" @mouseleave="removeSelect()">
            <div class="donut__color" :style="{ background: colors[i] }"></div>
            <span class="donut__category-name">- {{ separateText(key, 10) }}</span>
            <span class="donut__category-value">{{ categoryValue(rawData[i].data.value) }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import separateText from '@/assets/script/separateText';
import spacesInDigit from '@/assets/script/spacesInDigit';

export default {
  name: 'donut-chart-percentage',
  props: ['rawData', 'title', 'counter', 'type'],
  data() {
    return {
      chartSlices: [],
      selectedSlice: null,
      animation: {
        dot: {
          top: 0,
          left: 0
        },
        slice: {
          radius: 0,
          animId: null
        }
      },
      text: ''
    }
  },
  // created() {
  //     console.log('Donught created')
  // },
  // watch: {
  //     chartData: function() {
  //         console.log('watch chartData')
  //         console.log(this)
  //         this.$forceUpdate()
  //     }
  // },
  methods: {
    selectSlice(i) {
      this.selectedSlice = i

      this.pieChart({
        canvas: this.canvas,
        data: this.chartData,
        colors: this.colors,
        donutHoleSize: 0.8,
        text: this.counter,
        needText: true,
        animate: true,
        reverse: false
      })
    },
    removeSelect() {
      this.selectedSlice = null

      this.pieChart({
        canvas: this.canvas,
        data: this.chartData,
        colors: this.colors,
        donutHoleSize: 0.8,
        text: this.counter,
        needText: true,
        animate: true,
        reverse: false
      })
    },
    drawPieSlice(ctx, centerX, centerY, radius, startAngle, endAngle, color, animate, reverse) {

      const canvas = this.$refs.donut;
      let totalValue = 0;

      for (let key in this.chartData) {
        totalValue += this.chartData[key];
      }

      function draw(radius, color = '#ffffff') {
        if (Array.isArray(color)) {
          const grd = ctx.createConicGradient(
            startAngle,
            centerX,
            centerY);
          grd.addColorStop(0, color[0]);
          grd.addColorStop(0.5, color[1]);
          grd.addColorStop(1, color[0]);
          ctx.fillStyle = grd;
        } else {
          ctx.fillStyle = color;
        }
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(centerX, centerY, radius, startAngle, endAngle, false);
        ctx.closePath();
        ctx.fill();
      }

      if (animate) {
        if (this.animation.slice.animId) return
        this.animation.slice.animId = setInterval(() => {

          if (this.animation.slice.radius < radius * .95) this.animation.slice.radius = radius * .95
          if (this.animation.slice.radius < radius * 1.2) this.animation.slice.radius++
          ctx.clearRect(centerX / 2, centerY / 2, radius, radius);
          draw(this.animation.slice.radius, color)
          this.text = this.selectedSlice !== null ? this.chartSlices[this.selectedSlice].count : totalValue

          if (this.animation.slice.radius >= radius) {
            clearInterval(this.animation.slice.animId)
            this.animation.slice.radius = 0
            this.animation.slice.animId = null
          }
        }, 36);
        const centerAngle = (endAngle + startAngle) / 2;

        this.animation.dot.top = Math.sin(centerAngle) * (radius - 17) + canvas.width / 2
        this.animation.dot.left = Math.cos(centerAngle) * (radius - 17) + canvas.width / 2
      } else {
        draw(radius, color)
      }
    },
    pieChart(options) {
      const canvas = options.canvas;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.chartSlices = []
      const colors = options.colors;
      let totalValue = 0;
      for (let key in options.data) {
        totalValue += options.data[key];
      }
      let colorIndex = 0;

      let startAngle = 0;
      let counter = -1;
      for (let category in options.data) {
        counter++;
        const sliceAngle = 2 * Math.PI * options.data[category] / totalValue;
        if (this.selectedSlice === counter) {
          this.drawPieSlice(
            ctx,
            canvas.width / 2,
            canvas.height / 2,
            (Math.min(canvas.width / 2, canvas.height / 2)),
            startAngle,
            startAngle + sliceAngle,
            colors[colorIndex % colors.length],
            options.animate,
            options.reverse
          );
        } else {
          this.drawPieSlice(
            ctx,
            canvas.width / 2,
            canvas.height / 2,
            Math.min(canvas.width / 2, canvas.height / 2) - 6,
            startAngle,
            startAngle + sliceAngle,
            colors[colorIndex % colors.length]
          );
        }

        this.chartSlices.push({
          value: category,
          count: options.data[category],
          startAngle,
          endAngle: startAngle + sliceAngle,
          radius: Math.min(canvas.width / 2, canvas.height / 2),
          color: colors[colorIndex % colors.length]
        })
        startAngle += sliceAngle;
        colorIndex++;
      }

      counter = -1;

      if (options.donutHoleSize) {
        this.drawPieSlice(
          ctx,
          canvas.width / 2,
          canvas.height / 2,
          options.donutHoleSize * Math.min(canvas.width / 2, canvas.height / 2),
          0,
          2 * Math.PI,
          "#ffffff",
          false
        );
      }

      if (options.text.length && options.needText) {
        this.text = totalValue
      }
    },
    separateText,
    hoverOnPieSlice(e) {

      const canvas = this.$refs.donut;
      const radius = Math.min(canvas.width / 2, canvas.height / 2)
      var chartStartAngle = 0;
      var rect = e.target.getBoundingClientRect();
      var mouseX = e.pageX - rect.left - window.scrollX;
      var mouseY = e.pageY - rect.top - window.scrollY;

      var xFromCenter = mouseX - canvas.width / 2;
      var yFromCenter = mouseY - canvas.height / 2;
      var distanceFromCenter = Math.sqrt(Math.pow(Math.abs(xFromCenter), 2)
        + Math.pow(Math.abs(yFromCenter), 2));
      if (distanceFromCenter <= radius
        && distanceFromCenter >= radius * .8) {
        var clickAngle = Math.atan2(yFromCenter, xFromCenter) - chartStartAngle;

        if (clickAngle < 0) clickAngle = 2 * Math.PI + clickAngle;
        for (var i in this.chartSlices) {
          if ((clickAngle >= this.chartSlices[i]['startAngle']
            && clickAngle <= this.chartSlices[i]['endAngle'])
            && this.selectedSlice !== +i) {

            clearInterval(this.animation.slice.animId);
            this.animation.slice.animId = null;
            this.animation.slice.radius = 0;

            this.selectedSlice = +i
            this.pieChart({
              canvas: this.$refs.donut,
              data: this.chartData,
              colors: this.colors,
              donutHoleSize: 0.8,
              text: this.counter,
              animate: true,
              reverse: false
            })

            return;
          }
        }
      } else {
        clearInterval(this.animation.slice.animId);
        this.animation.slice.animId = null;
        this.animation.slice.radius = 0;
        this.selectedSlice = null
        this.pieChart({
          canvas: this.$refs.donut,
          data: this.chartData,
          colors: this.colors,
          donutHoleSize: 0.8,
          text: this.counter,
          animate: true,
          reverse: true,
          needText: true
        })


      }
    },
    shortenNumRu(num) {
      if (isNaN(num)) throw new Error(num + " is not a Number!");

      let digitСapacity = {
        "0": "",
        "1": " тыс.",
        "2": " млн",
        "3": " млрд",
      };

      let thousands = Math.floor((("" + num).length - 1) / 3);

      let coef = 1000 ** thousands;

      if (digitСapacity[thousands] > 2) {
        return (num / coef).toFixed(1) + digitСapacity[thousands]
      } else {
        return Math.trunc((num / coef).toFixed(1)) + digitСapacity[thousands]
      }

    },
    rewriteChart() {
      this.pieChart({
        canvas: this.canvas,
        data: this.chartData,
        colors: this.colors,
        donutHoleSize: 0.8,
        text: this.counter,
        needText: true
      })
    },
    categoryValue(data) {
      const currencySimbol = this.currencySimbol
      const sumFormatted = spacesInDigit(Math.round(data))

      if (currencySimbol === '$') {
        return `${currencySimbol} ${sumFormatted}`
      }
      else {
        return `${sumFormatted} ${currencySimbol}`
      }
    },
  },
  computed: {
    chartData() {
        if (!Array.isArray(this.rawData)) return null
        const parsedData = {}
        this.rawData.forEach((item, index, array) => parsedData[item.name] = item.data.percent)
        return parsedData
    },
    currencySimbol() {
      const currencySimbol = this.$store.getters['getCurrencySimbol']
      const currencyCode = this.$store.getters['getCurrencyCode']
      return currencySimbol ? currencySimbol : currencyCode
    },
    innerValue() {
      if (this.type === 'currency') {
        const currencySimbol = this.currencySimbol
        const sumFormatted = this.shortenNumRu(Math.trunc(this.text)).split(' ')

        if (currencySimbol === '$') {
          return `${currencySimbol} ${sumFormatted[0]}`
        }
        else {
          return `${sumFormatted[0]} ${sumFormatted[1]}`
        }
      }
      else if (this.type === '%') {
        return `${this.shortenNumRu(Math.trunc(this.text))} %`
      }
      else {
        return `${this.shortenNumRu(Math.trunc(this.text))}`
      }
    },
    validData() {
      for (let key in this.chartData) {
        return true
      }
      return null
    },
    canvasScreen() {
      if (this.$screen.width > 1350)
        return 200
      else
        return 135
    },
    colors() {
      return this.$store.state.chartStore.donutChartColors
    },
    innerText() {
      return `${this.text}<span>${this.counter}</span>`
    },
    canvas() {
      return this.$refs.donut
    }
  },
  mounted() {
    if (this.validData) {
      window.addEventListener('resize', this.rewriteChart)
      //   console.log(this.chartData);
      this.pieChart({
        canvas: this.canvas,
        data: this.chartData,
        colors: this.colors,
        donutHoleSize: 0.8,
        text: this.counter,
        needText: true
      })
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.rewriteChart)
  }
}
</script>

<style lang="scss" scoped>
.donut {
  width: 240px;
  padding: 15px;
  background: #FFFFFF;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  transition: .3s all;
  min-height: 357px;

  @media (max-width: 1350px) {
    flex-direction: row;
    width: auto;
    max-width: 400px;
    gap: 30px;
    min-height: unset;
  }

  &:has(.donut__no-data) {
    min-height: 357px;
    position: relative;
  }

  &__no-data {
    font-family: 'Mail Sans Roman';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 155%;
    text-align: center;
    text-transform: uppercase;
    color: #E6E6E6;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__title {
    font-family: 'Mail Sans Roman';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 155%;
    color: #A7A7A7;
    margin-bottom: 7px;
    transition: .3s all;
  }

  &__canvas {
    padding: 5px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-self: start;
    position: relative;
    transform-origin: center;
    top: 0;
    left: 0px;
    z-index: 1;
  }

  &__inner {
    position: absolute;
    z-index: 2;
    background: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: calc(215px * .8);
    height: calc(215px * .8);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
    font-family: 'Mail Sans Roman';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 155%;
    color: #262424;
    transition: .3s all;

    & span {
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
      color: #262424;
      transition: .3s all;
    }

    & span.donut__inner-value {
      font-size: unset;
    }

    @media (max-width: 1350px) {
      font-size: 24px;
      line-height: 100%;

      & span {
        font-size: 12px;
      }
    }
  }

  & canvas {
    position: relative;
    z-index: 1;
  }

  &__category {
    // margin-top: 25px;
    // display: grid;
    // grid-template-rows: repeat(3, 1fr);
    // grid-auto-columns: auto;
    // grid-auto-flow: column;
    // gap: 14px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    &_second {
      margin-top: 14px;
      grid-template-rows: repeat(2, 1fr);
    }

    @media (max-width: 1350px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__category-item {
    display: flex;
    align-items: center;
    gap: 7px;
    opacity: .3;
    transition: .3s all;
    cursor: pointer;

    &_selected {
      opacity: 1;
    }

    &:nth-child(1) {
      grid-column: span 1;
      grid-row-start: 1;
    }

    &:nth-child(2) {
      grid-column: span 1;
      grid-row-start: 2;
    }

    &:nth-child(3) {
      grid-column: span 1;
      grid-row-start: 3;
    }
  }

  &__color {
    width: 11px;
    height: 11px;
    flex-shrink: 0;
    border-radius: 100%;
  }

  &__category-name {
    font-family: 'Mail Sans Roman';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #101010;
    transition: .3s all;
  }

  &__category-value {
    font-family: 'Mail Sans Roman';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #101010;
    transition: .3s all;
    display: inline-block;
    margin-left: auto;
  }
}

.dark-mode {
  .donut {
    background: #262424;

    &__title {
      color: #ECECEC;
    }

    &__inner {
      background: #262424;
      color: #fff;

      & span {
        color: #fff;
      }
    }

    &__category-name {
      color: #fff;
    }
  }
}
</style>
