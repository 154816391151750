export default function formatDate(dates) {
  const from = {
    yyyy: dates[0].getFullYear(),
    mm: (dates[0].getMonth() + 1) < 10 ? '0' + (dates[0].getMonth() + 1) : dates[0].getMonth() + 1,
    dd: dates[0].getDate() < 10 ? '0' + dates[0].getDate() : dates[0].getDate(),
  }
  const to = {
    yyyy: dates[1].getFullYear(),
    mm: (dates[1].getMonth() + 1) < 10 ? '0' + (dates[1].getMonth() + 1) : dates[1].getMonth() + 1,
    dd: dates[1].getDate() < 10 ? '0' + dates[1].getDate() : dates[1].getDate(),
  }
  const fromFormatted = `${from.yyyy}-${from.mm}-${from.dd}`
  const toFormatted = `${to.yyyy}-${to.mm}-${to.dd}`
  return { fromFormatted, toFormatted }
}