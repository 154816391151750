import { AuthAPI } from "@/api/AuthAPI";
import { pageDataAPI } from "@/api/pageDataAPI/index";

// export default {
//     state: {
//         loggedIn: false
//     },
//     mutations: {
//         changeLoggedStatus(state, value) {
//             state.loggedIn = value;
//         }
//     }
// }


export default {
  state: () => ({
    credentials: {
      token: localStorage.getItem('token') || null,
      err: null,
      message: null,
    },
    user: JSON.parse(localStorage.getItem('user')) || null,
    // permissions: JSON.parse(localStorage.getItem('permissions')) || null,
    permissions: {},
  }),

  getters: {
    isLoggedIn: (state) => !!state.credentials.token,
    getCredentials: (state) => state.credentials,
    getToken: (state) => state.credentials.token,
    getErrorMsg: (state) => state.credentials.message,
    getUser: (state) => state.user,
    getPermissions: (state) => state.permissions,
  },

  mutations: {
    setToken(state, token) {
      // console.log(`setToken ${token}`)
      state.credentials.token = token
      localStorage.setItem('token', token)
    },
    deleteToken(state) {
      state.credentials.token = null
      localStorage.removeItem('token')
    },
    setError(state, { err, message }) {
      state.credentials.err = err
      state.credentials.message = message
    },
    clearError(state) {
      state.credentials.err = null
      state.credentials.message = null
    },
    setUser(state, data) {
      state.user = data
      localStorage.setItem('user', JSON.stringify(data))
    },
    clearUser(state) {
      state.user = null
      localStorage.removeItem('user')
    },
    setPermissions(state, data) {
      state.permissions = data
      // localStorage.setItem('permissions', JSON.stringify(data))
    },
  },

  actions: {
    async onLogin({ commit, dispatch }, { login, password }) {
      console.log(`onLogin ${login} ${password}`)

      try {
        await AuthAPI.login(login, password).then(res => {
          console.log(res)
          if (res.data.token) {
            commit('setToken', res.data.token)
            dispatch('fetchUserInfo')
            // dispatch('fetchPermissions')
            commit('clearError')
          } else {
            commit('setError', { err: res.data.error, message: res.data.message })
          }
        })
      } catch (error) {
        if (error.response.data.email) {
          commit('setError', { err: true, message: error.response.data.email[0] })
        }
      }

    },
    onLogout({ commit }) {
      commit('deleteToken')
      commit('clearUser')
    },
    async fetchUserInfo({ commit, rootGetters }) {
      const token = rootGetters.getToken
      let data

      try {
        data = await pageDataAPI.get({
          url: '/user',
          token: token,
        })
      } catch (error) {
        if (error.message === 'Unauthenticated') {
          commit('deleteToken')
          router.push('/')
        }
      }
      commit('setUser', data)
    },
    async fetchPermissions({ commit, rootGetters }) {
      const token = rootGetters.getToken
      let data

      try {
        data = await pageDataAPI.get({
          url: '/user/permissions',
          token: token,
        })
      } catch (error) {
        if (error.message === 'Unauthenticated') {
          commit('deleteToken')
          router.push('/')
        }
      }
      commit('setPermissions', data)
    }
  }
}