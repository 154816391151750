function decompose(array) {
    return Array.isArray(array) ?
        [].concat(...array.map(decompose)) :
        [array];
}

function globalFilterReviver() {
    const globalFilter = localStorage.getItem('globalFilter')
    if (globalFilter === 'null') return null
    else if (isFinite(Number(globalFilter))) return Number(globalFilter)
    else return 5
}

export default {
    state: {
        globalFilter: globalFilterReviver(),
        selector: Number(localStorage.getItem('selector')) ?? 0,
        timeRange: localStorage.getItem('timeRange') ?
            JSON.parse(localStorage.getItem('timeRange'), function (key, value) {
                if (isFinite(key) && key !== '') {
                    return new Date(value)
                }
                return value
            })
            :
            [new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() - 7),
            new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate())],
        selectedFilial: null,
        filials: [
            {
                name: 'Россия',
                label: require('@/assets/icons/ru.png'),
                filials: [
                    {
                        name: 'RONI RU',
                        city: 'Тюмень',
                        status: 'building'
                    },
                ]
            },
            {
                name: 'Россия',
                label: require('@/assets/icons/ru.png'),
                filials: [
                    {
                        name: 'RONI RU',
                        city: 'Тюмень',
                        status: 'opened'
                    },
                ]
            },
            {
                name: 'Казахстан',
                label: require('@/assets/icons/kz.png'),
                filials: [
                    {
                        name: 'RONI RU',
                        city: 'Тюмень',
                        status: 'opened'
                    },
                ]
            },
            {
                name: 'Узбекистан',
                label: require('@/assets/icons/uz.png'),
                filials: [
                    {
                        name: 'RONI RU',
                        city: 'Тюмень',
                        status: 'opened'
                    },
                ]
            }
        ],
    },
    getters: {
        getTimeRange(state) {
            return state.timeRange
        },
        getCurrentSelector(state) {
            return state.selector
        },
        getGlobalFilterIndex(state) {
            return state.globalFilter
        },
        getGlobalFilterSlug(state) {
            const filter = state.globalFilter;

            switch (filter) {
                case 0:
                    return 'day'
                    break;
                case 1:
                    return 'week'
                    break;
                case 2:
                    return 'month'
                    break;
                case 3:
                    return 'quarter'
                    break;
                case 4:
                    return 'year'
                    break;
                case 5:
                    return null
                    break;
                default:
                    break;
            }
        },
        getGlobalFilter(state, getters) {
            const filter = state.globalFilter;
            switch (null) {
                case 0:
                    return 'За день'
                    break;
                case 1:
                    return 'За неделю'
                    break;
                case 2:
                    return 'За месяц'
                    break;
                case 3:
                    return 'За квартал'
                    break;
                case 4:
                    return 'За год'
                    break;
                case 5:
                    return 'За все время'
                    break;
                case null:
                    const timeRange = getters.getTimeRange
                    // const firstDate = `${timeRange[0].getDate()}.${timeRange[0].getMonth() + 1}.${String(timeRange[0].getFullYear()).slice(2, 4)}`
                    // const secondDate = `${timeRange[1].getDate()}.${timeRange[1].getMonth() + 1}.${String(timeRange[1].getFullYear()).slice(2, 4)}`
                    // return `${firstDate} - ${secondDate}`
                    const firstDate = {
                        dd: timeRange[0].getDate() > 9 ? timeRange[0].getDate() : '0' + timeRange[0].getDate(),
                        mm: timeRange[0].getMonth() + 1 > 9 ? timeRange[0].getMonth() + 1 : '0' + (timeRange[0].getMonth() + 1),
                        yy: String(timeRange[0].getFullYear()).slice(2, 4),
                    }
                    const secondDate = {
                        dd: timeRange[1].getDate() > 9 ? timeRange[1].getDate() : '0' + timeRange[1].getDate(),
                        mm: timeRange[1].getMonth() + 1 > 9 ? timeRange[1].getMonth() + 1 : '0' + (timeRange[1].getMonth() + 1),
                        yy: String(timeRange[1].getFullYear()).slice(2, 4),
                    }
                    return `${firstDate.dd}.${firstDate.mm}.${firstDate.yy} - ${secondDate.dd}.${secondDate.mm}.${secondDate.yy}`
                    break;
                default:
                    break;
            }
        },
        getAllFilials(state) {
            return state.filials
        },
        getBuildingFilials(state) {
            const arr = state.filials.map(item => {
                return item.filials.map(filial => {
                    if (filial.status === 'building')
                        return {
                            countryName: item.name,
                            countryLabel: item.label,
                            filialName: filial.name,
                            filialCity: filial.city
                        }
                    else
                        return []
                })
            })
            return decompose(arr)
        },
        getOpenedFilials(state) {
            const arr = state.filials.map(item => {
                return item.filials.map(filial => {
                    if (filial.status === 'opened')
                        return {
                            countryName: item.name,
                            countryLabel: item.label,
                            filialName: filial.name,
                            filialCity: filial.city
                        }
                    else
                        return []
                })
            })
            return decompose(arr)
        },
        getSelectedFilial(state) {
            const filial = state.selectedFilial;
            if (!filial) return 'Все филиалы'
            return filial;
        }
    },
    mutations: {
        setSelector(state, payload) {
            state.selector = payload
            localStorage.setItem('selector', payload)
        },
        setGlobalFilter(state, payload) {
            state.globalFilter = payload
            localStorage.setItem('globalFilter', payload)
        },
        setTimeRange(state, payload) {
            state.timeRange = payload
            const serializedTimeRange = JSON.stringify(payload)
            localStorage.setItem('timeRange', serializedTimeRange)
        },
        toggleFilter(state, payload) {
            state.globalFilter = payload
            localStorage.setItem('globalFilter', payload)
            state.selector = 0
            localStorage.setItem('selector', 0)

            if (payload === 0) {
                state.timeRange = [new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() - 1),
                new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() - 1)];

                    // state.timeRange = [new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate()),
                    //     new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate())];
            }
            else if (payload === 1) {
                let lastMonday = new Date();
                let day = lastMonday.getDay() || 7;
                let timeWeek1
                let timeWeek2

                if (day === 1 || day === 2) {
                    console.log('case 1(week)')
                    const backDays = day === 1 ? 7 : 8
                    const mondayLastWeek = lastMonday.setHours(-24 * backDays)
                    const sundayLastWeek = mondayLastWeek + 864e5 * 6
                    timeWeek1 = new Date(mondayLastWeek)
                    timeWeek2 = new Date(sundayLastWeek)
                    // state.timeRange = [new Date(), sundayLastWeek]
                }
                else {
                    timeWeek1 = new Date(lastMonday.setHours(-24 * (day - 1)))
                    timeWeek2 = new Date(new Date() - 864e5)
                    // state.timeRange = [lastMonday, new Date(new Date() - 864e5)]
                }

                state.timeRange = [timeWeek1, timeWeek2]

                // state.timeRange = [new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate()-7),
                //     new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate())];
            }
            else if (payload === 2) {
                const todayDay = new Date().getDate()
                let timeMonth1 = null
                let timeMonth2 = null

                if (todayDay < 3) {
                    timeMonth1 = new Date((new Date()).getFullYear(), (new Date()).getMonth()-1, 1)
                    timeMonth2 = new Date((new Date()).getFullYear(), (new Date()).getMonth(), 0)
                }
                else {
                    timeMonth1 = new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)
                    timeMonth2 = new Date(Date.now() - 864e5)
                }

                state.timeRange = [timeMonth1, timeMonth2]
                // const timeMonth2 = new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate())

                // state.timeRange = [new Date((new Date()).getFullYear(), (new Date()).getMonth()-1, (new Date()).getDate()),
                //     new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate())];
            }
            else if (payload === 3) {
                const quarters = [
                    [0, 1, 2],
                    [3, 4, 5],
                    [6, 7, 8],
                    [9, 10, 11]
                ]

                let time1 = null
                let time2 = null

                const today = new Date()
                const todayDay = today.getDate()
                const todayMonth = today.getMonth()
                let isFirstMonth = null
                const currentQuarterIndex = quarters.findIndex((quarter, index, array) => {
                    if (quarter.includes(todayMonth)) {
                        isFirstMonth = quarter.findIndex(item => item === todayMonth)
                        return true
                    }
                })

                if (isFirstMonth === 0 && todayDay < 3) {
                    const previousQuarter = currentQuarterIndex === 0 ? 3 : [currentQuarterIndex - 1]
                    const year = currentQuarterIndex === 0 ? (new Date()).getFullYear() - 1 : (new Date()).getFullYear()
                    // Вернет квартал, предшествующий кварталу, в кором находится текущий месяц.
                    // Числа будут выставлены c 1 числа 1 квартала по последнее число последнего квартала
                    time1 = new Date(year, quarters[previousQuarter][0], 1)
                    time2 = new Date((quarters[previousQuarter][2] === 11 ? year + 1 : year), (quarters[previousQuarter][2] === 11 ? 0 : quarters[previousQuarter][2] + 1), 0)
                }
                else {
                    time1 = new Date(new Date().getFullYear(), quarters[currentQuarterIndex][0], 1)
                    time2 = new Date(Date.now() - 864e5)
                }

                state.timeRange = [time1, time2]
            }
            else if (payload === 4) {
                const today = new Date()
                const todayDay = today.getDate()
                const todayMonth = today.getMonth()
                let time1 = null
                let time2 = null

                if (todayDay < 3 && todayMonth === 0) {
                    time1 = new Date((new Date()).getFullYear() - 1, 0, 1)
                    time2 = new Date((new Date()).getFullYear(), 0, 0)
                }
                else {
                    time1 = new Date((new Date()).getFullYear(), 0, 1)
                    time2 = new Date(Date.now() - 864e5)
                }

                state.timeRange = [time1, time2]

                // state.timeRange = [new Date((new Date()).getFullYear()-1, (new Date()).getMonth(), (new Date()).getDate()),
                //     new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate())];
            }
            else if (payload === 5) {

                const timeAll1 = new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() - 7)
                const timeAll2 = new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate())

                state.timeRange = [new Date("2020-01-01"), timeAll2]
            }

            const serializedTimeRange = JSON.stringify(state.timeRange)
            localStorage.setItem('timeRange', serializedTimeRange)
        },
        toggleFilial(state, payload) {
            state.selectedFilial = payload
        }
    },
    actions: {

    }
}
