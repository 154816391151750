export default function spacesInDigit(value, nowrap = false) {
    let val = value.toString();
    if (+val) {
        if (nowrap) {
            return val.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + '\u00A0');
        }
        else {
            return val.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ' ');
        }
    }
    else return value
}
