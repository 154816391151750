import { LoginAPIInstance, DefaultAPIInstance } from "@/api";

export const AuthAPI = {
  login(login, password) {
    const url = '/login';
    // const data = { login, password };
    let data = new FormData();
    data.append('email', login)
    data.append('password', password)
    return LoginAPIInstance.post(url, data)
  }, 

  logout() {
    const url = '/logout';
    return DefaultAPIInstance.post(url);
  }
}