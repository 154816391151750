<template>
    <div class="sells" :style="{height: showUnset ? '195px' : 'auto'}">
        <app-title level="5" :title="title"></app-title>
        <!-- <div v-if="nonCurrency" class="sells__sum">{{ sum ? spacesInDigit(sum) : 'Нет данных' }}</div>
        <div v-else class="sells__sum">{{ sum ? `${counter} ` + spacesInDigit(sum) : 'Нет данных' }}</div> -->
        <div class="sells__sum">{{ sumFormatted(sum) }}</div>
        <!-- <div v-if="!decor" class="sells__subsum">{{ subsum ? '$ ' + spacesInDigit(subsum) : showUnset ? 'Нет данных' : '' }}</div> -->

        <div v-if="subsum" class="sells__subsum">{{ sumFormatted(subsum) }}</div>
        <!-- <div v-if="subsum" class="sells__subsum">{{ subsum ? `${counter} ` + spacesInDigit(subsum) : showUnset ? 'Нет данных' : '' }}</div> -->
        <increase-value
            class="sells__increase"
            :value="increase.increase"
            :success="increase.success"
            :tooltip="increaseTooltip"
            >
        </increase-value>
        <div class="sells__descr" v-if="sum && !decor && showUnset">В сравнении с прошлым годом</div>
        <div class="sells__decor" v-if="decor">
            <img v-if="increase.success" src="@/assets/icons/charts/decor_chart_green.svg" alt="Decor">
            <img v-else src="@/assets/icons/charts/decor_chart_red.svg" alt="Decor">
        </div>
    </div>
</template>

<script>
import spacesInDigit from '@/assets/script/spacesInDigit';
export default {
    name: 'sells-block',
    // props: ['title', 'sum', 'subsum', 'increase', 'decor', 'counter', 'showUnset', 'nonCurrency'],
    props: {
        title: { type: String, requried: false, default: null },
        sum: { type: Number, requried: true, default: null },
        subsum: { type: Number, requried: false, default: null },
        increase: { type: Object, requried: true, default: null },
        increaseTooltip: { type: String, requried: false, default: '' },
        decor: { type: Boolean, requried: false, default: true },
        counter: { type: String, requried: false, default: '' },
        showUnset: { type: Boolean, requried: false, default: false },
        nonCurrency: { type: Boolean, requried: false, default: false },
    },
    methods: {
        spacesInDigit,
        sumFormatted(sum) {
            if (isNaN(parseFloat(sum))) return 'Нет данных'

            if (this.counter === '$') {
                return `${this.counter} ` + spacesInDigit(sum)
            }
            else {
                return spacesInDigit(sum) + ` ${this.counter}`
            }
        },
    },
    computed: {
        // currencySimbol() {
        //     const currencySimbol = this.$store.getters['getCurrencySimbol']
        //     const currencyCode = this.$store.getters['getCurrencyCode']
        //     return currencySimbol ? currencySimbol : currencyCode
        // }
    },
}
</script>

<style lang="scss" scoped>
.sells {
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    height: min-content;
    display: inline-block;
    // width: 240px;
    height: 195px;
    transition: .3s all;
    // min-width: 180px;
    min-width: 240px;
    position: relative;
    @media (max-width: 1350px) {
        height: auto;
        max-width: 240px;
        width: 100%;
        padding: 15px 18px;
    }
    &__decor {
        display: block;
        margin: 0 0 0 auto;
        height: 60px;
        position: absolute;
        right: 20px;
        bottom: 20px;
        @media (max-width: 1350px) {
            max-width: 100%;
        }
        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__sum {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 600;
        // font-size: 26px;
        line-height: 100%;
        transition: .3s all;
        color: #262424;
        font-size: 25px;
        margin: 15px 0px;
        // @media (max-width: 1350px) {
        //     font-size: 32px;
        // }
    }
    // &__sum.empty {
    //     font-size: 34px;
    // }
    &__subsum {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 155%;
        transition: .3s all;
        color: #A7A7A7;
    }
    &__increase {
        margin-top: 10px;
    }
    &__descr {
        margin-top: 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 155%;
        transition: .3s all;
        color: #A7A7A7;
    }
}

.dark-mode {
    .sells {
        background: #262424;
        &__sum {
            color: #fff;
        }
        &__subsum {
            color: #A7A7A7;
        }
        &__descr {
            color: #A7A7A7;
        }
    }
}
</style>
