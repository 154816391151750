<template>
    <div class="percents">
        <app-title level="5" :title="title"></app-title>
        <div class="percents__inner" v-for="(item, index) in data" :key="item.name">
            <app-title level="6" :title="item.name" class="percents__name"></app-title>
            <div class="percents__value">{{ getValue(item.value) }}</div>
            <div class="percents__line-container">
                <div class="percents__line-current" :style="{width: lineWidth(item.value, allValues), backgroundColor: colors[index]}"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'percent-block',
    props: ['title', 'data'],
    data() {
        return {
            colors: [ '#E83D46', '#F9A620', '#00BFB9', '#005FA7' ]
        }
    },
    methods: {
        lineWidth(current, all) {
            if (!current || !all) {
                return 0;
            }
            return current / all * 100 + '%'
        },
        getValue(data) {
            return !isNaN(parseFloat(data)) ? data : 'Нет данных'
        }
    },
    computed: {
        allValues() {
            return this.data.reduce((acc, item, index, arr) => acc += Number(item.value), 0)
        }
    }
}
</script>

<style lang="scss" scoped>
.percents {
    width: 780px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 18px 28px 34px;
    height: min-content;
    transition: .3s all;
    @media (max-width: 1350px) {
        width: 100%;
    }
    &__name {
        width: 30%;
        @media (max-width: 1350px) {
            width: 100%;
            margin-top: 20px;
        }
    }
    &__inner {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        gap: 25px;
        margin-top: 15px;
        @media (max-width: 1350px) {
           flex-direction: column;
           justify-content: flex-start;
           align-items: flex-start;
           gap: 5px;
        }
    }
    &__value {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 155%;
        color: #101010;
        width: 30%;
        text-align: right;
        transition: .3s all;
        @media (max-width: 1350px) {
            text-align: left;
            width: 100%;
        }
    }
    &__line-container {
        /* margin-left: 40px; */
        width: 100%;
        background: #ECECEC;
        border-radius: 5px;
        height: 6px;
        transition: .3s all;
    }
    &__line-current {
        background: #E83D46;
        border-radius: 5px;
        height: 6px;
        width: 0;
        transition: .3s all;
    }
}

.dark-mode {
    .percents {
        background: #262626;
        &__value {
            color: #fff;
        }
        &__line-container {
            background: #a7a7a7;
        }
        &__line-current {
            background: #E83D46;
        }
    }
}
</style>