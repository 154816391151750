<template>
  <div :class="[ 'preloader', { dark: darkMode } ]">
    <div class="preloader__inner">
      <div class="preloader__img">
        <img :src="require(`@/assets/images/${img}`)" alt="">
      </div>
      <!-- <div class="preloader__text" style="font-family:'Organetto'">ЗАГРУЗКА...</div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // img: 'preloader_basic.svg',
    }
  },
  computed: {
    darkMode() {
      return this.$store.getters['getDarkMode']
    },
    theme() {
      return this.darkMode ? 'dark' : 'light'
    },
    img () {
      return this.theme === 'dark' ? 'preloader_white.svg' : 'preloader_basic.svg'
    }
  },
}
</script>
<style lang='scss' scoped>
.preloader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 90px;
  z-index: 100;
  pointer-events: none;
  transition: opacity 0.4s ease;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  font-size: 36px;
  background-color: #FAF6EE;
  
  &.dark {
    background-color: #2C2C2C;
  }

  &__inner {
    max-width: 300px;
    position: absolute;
    top: calc(50% - 90px);
    left: calc(50% + 240px);
    transform: translate(calc(-50% - 120px), calc(-50% + 45px));

    @media (max-width: 1350px) {
      top: calc(50% - 134px);
      left: 50%;
      transform: translate(-50%, calc(-50% + 67px));
    }
  }

  &__img {
    max-width: 206px;
    // height: 206px;
    aspect-ratio: 1 / 1;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

// .preloader {
//   &--show {
//     position: fixed;
//     background: white;
//     width: 100%;
//     height: 100%;
//     left: 0;
//     top: 0;
//     z-index: 2;
//     opacity: 0.93;
//     pointer-events: all;
//   }
// }
</style>