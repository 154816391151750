<template>
    <div class="toggler" :style="{marginTop: options ? '25px' : '0'}">
        <!-- :class="['toggler__option', {'toggler__option_active': !isActive}]"> -->
        <div 
            v-if="options" 
            :class="['toggler__option', {'toggler__option_active': !activate}]">
            {{ newOption(options[0]) }}
        </div>
        <!-- :class="['toggler__btn', {'toggler__btn_active': isActive}]" -->
        <button 
            :class="['toggler__btn', {'toggler__btn_active': activate}]"
            @click="toggle($event)">
        </button>
        <!-- :class="['toggler__option', {'toggler__option_active': isActive}]"> -->
        <div 
            v-if="options" 
            :class="['toggler__option', {'toggler__option_active': activate}]">
            {{ newOption(options[1]) }}
        </div>
        <div class="toggler__line" v-if="line"></div>
    </div>
</template>

<script>
export default {
    name: 'app-toggler',
    props: ['options', 'line', 'activate'],
    emits: ['toggle'],
    data() {
        return {
            // isActive: this.activate !== 'undefined' ? this.activate : false
        }
    },
    methods: {
        toggle(e) {
            // this.isActive = !this.isActive;
            // this.$emit('toggle', { event: e, active: this.isActive })
            this.$emit('toggle', { event: e, active: !this.activate })
        },
        newOption(opt) {
            return opt.replace(/ /g, '\u00a0')
        }
    },
}
</script>

<style lang="scss" scoped>
.toggler {
    display: flex;
    gap: 13px;
    align-items: center;
    &__line {
        width: 100%;
        height: 1px;
        background: #e4dfd5;
        transition: .3s all;
    }
    &__option {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 155%;
        color: #A7A7A7;
        transition: .3s all;
        &_active {
            color: #626262;
            transition: .3s all;
        }
        @media (max-width: 1350px) {
            font-size: 14px;
        }
    }
    &__btn {
        min-width: 33px;
        height: 20px;
        background: #D42F38;
        border-radius: 10px;
        position: relative;
        border: none;
        cursor: pointer;
        transition: .3s all;
        &:before {
            content: '';
            display: block;
            width: 11px;
            height: 11px;
            border-radius: 100%;
            background: #F9A620;
            position: absolute;
            left: 5px;
            top: 4px;
            transition: .3s all;
        }
        &_active {
            &::before {
                left: 17px;
            }
        }
    }
}

.dark-mode {
    .toggler {
        &__line {
            background: #515151;
        }
        &__option {
            color: #626262;
            &_active {
                color: #A7A7A7;
            }
        }
        &__btn {
            background: #000;
        }
    }
}
</style>