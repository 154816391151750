export default function shortenNumRu(num) {
  if (isNaN(num)) throw new Error(num + " is not a Number!");
  if (num < 1000) return num + ' '

  let digitСapacity = {
      "0": "",
      "1": " тыс.",
      "2": " млн",
      "3": " млрд",
  };

  let thousands = Math.floor((("" + num).length - 1) / 3);
  // console.log(thousands)

  let coef = 1000 ** thousands;
  // console.log(coef)

  if (digitСapacity[thousands] > 2) {
      return (num / coef).toFixed(1) + digitСapacity[thousands]
  } else {
      const numLenght = String(Math.trunc((num / coef).toFixed(1))).length
      if (numLenght === 1) {
        return Number((num / coef).toFixed(1)) + digitСapacity[thousands]
      }
      else {
          return Math.trunc((num / coef).toFixed(1)) + digitСapacity[thousands]
      }
  }

}