<template>
  <div class="table">
    <table>
      <thead class="table__thead">
        <tr>
          <th>Дата и время</th>
          <th>Подразделение</th>
          <th>Пользователь</th>
          <th>№ Заказа</th>
          <th>Сумма</th>
          <th>Комментарий</th>
          <th>Список позиций заказа</th>
        </tr>
      </thead>
      <tbody class="table__tbody" v-if="branchUnselected">
        <p class="table__notification notification">Выберите филиал</p>
      </tbody>
      <tbody class="table__tbody" v-else-if="emptyData">
        <p class="table__notification notification">Нет данных</p>
      </tbody>
      <tbody class="table__tbody" v-else>
        <tr v-for="event of data" :key="event.date + event.order_number" class="use-bg">
          <td>{{ event.date }}</td>
          <td>{{ event.branch }}</td>
          <td>{{ event.user }}</td>
          <td
            :class="{ 'expand': expandClass(event.order_number, 10).expand }"
            :data-expand="event.order_number"
          >{{ expandClass(event.order_number, 10).text }}
          </td>
          <td>{{ event.sum }}</td>
          <td
            :class="{ 'expand': expandClass(event.comment, 10).expand }"
            :data-expand="event.comment"
          >{{ expandClass(event.comment, 10).text }}
          </td>
          <td
            :class="{ 'expand-list': expandClass(event.dishes, 60).expand, 'max-width': true }"
            :data-expand="event.dishes"
          >{{ truncString(event.dishes, 60) }}
            <span v-if="expandClass(event.dishes, 60).expand" class="expand-list__tooltip">
              <ul class="expand-list__list">
                <li v-for="dish of event.dishes.split(';')" class="expand-list__list-item">
                  {{ dish }}
                </li>
              </ul>
            </span>
          </td> 
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import separateText from '@/assets/script/separateText';
export default {
  props: ['data', 'params'],
  methods: {
    truncString(str, count) {
      if (str.length <= count) return str
      let strArr = str.split('')
      strArr.splice(count, str.length-count, '…')
      return strArr.join('')
    },
    expandClass(text, maxLength) {
      return text.length > maxLength && this.params.expand ? {
        expand: true,
        text: this.separateText(text, maxLength)
      } : {
        expand: false,
        text: text
      }
    },
    separateText
  },
  computed: {
    selectedBranch() {
        return this.$store.getters['getBranchId']
    },
    branchUnselected() {
      return this.selectedBranch === null
    },
    emptyData() {
      return this.data !== null ? this.data.length === 0 : true
    },
  },
}
</script>

<style lang="scss" scoped>
.table {

  &:has(.notification) {
    min-height: 350px;
    position: relative;
  }

  &__notification {
    font-size: 48px;
  }
  
  @media (max-width: 1350px) {
    overflow-x: scroll;
    margin-top: 20px;
    overflow-y: hidden;
  }

  @media (max-width: 796px) {
    position: relative;
    width: calc(100vw - 16px);
  }

  &__tbody {

    &:has(.notification) {
      height: 350px;
      position: relative;
    }

    & tr {
      & td {
        
      }
      & td:nth-child(3) {
        max-width: 100px;
        white-space: break-spaces;
      }
      & td:nth-child(4) {
        max-width: 75px;
        // white-space: break-spaces;
        // overflow-x: hidden;
        
      }
    }
  }

  & table {
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    border-collapse: collapse;
    max-width: 1050px;
    width: 100%;
    min-width: 1050px;

    & td,
    & th {
      border: 1px solid rgba(0, 0, 0, .1);
      padding: 8px;
      min-width: 70px;
      font-weight: 600;
      font-size: 12px;
      line-height: 110%;
      color: #262424;
      white-space: nowrap;
      text-align: left;
      transition: .3s all;
      position: relative;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      &:before {
        content: attr(data-expand);
        position: absolute;
        // width: 445px;
        min-width: 200px;
        height: auto;
        padding: 15px;
        background: #FEEDD2;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
        opacity: 0;
        pointer-events: none;
        transition: .3s all;
        white-space: break-spaces;
        z-index: 10;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 12px;
        line-height: 155%;
        color: #101010;
      }

      &.expand {
        &:hover {
          &::before {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }

    & th {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    & td {
      font-weight: 400;
      font-size: 12px;
      color: #101010;
    }

    & tr {
      width: 100%;

      &:first-child {

        & th,
        & td {
          border-top: none;
        }
      }

      &:last-child {

        & th,
        & td {
          border-bottom: none;
        }
      }

      &.use-bg {
        &:hover {

          & td,
          & th {
            background: rgba(249, 166, 32, 0.2);

            &.expand {
              background: rgba(249, 166, 32, .3);
            }
          }
        }
      }
    }
  }
}

.dark-mode {
  .table {
    & table {
      background: #262424;

      & td,
      & th {
        border-color: rgba(255, 255, 255, .1);
        color: #fff;

        &:before {
          background: rgba(232, 61, 70);
          color: #fff;
        }
      }

      & td {
        color: #a7a7a7;
      }

      & tr {
        &.use-bg {
          &:hover {

            & td,
            & th {
              background: rgba(232, 61, 70, 0.2);

              &.expand {
                background: rgba(232, 61, 70, .3);
              }
            }
          }
        }
      }
    }
  }
  .expand-list {
    &__tooltip {
      background: rgba(232, 61, 70, 1);
    }
  }
}
.expand-list {
  &:hover {
    & .expand-list__tooltip {
      opacity: 1;
      pointer-events: all;
    }
  }
  &__tooltip {
    position: absolute;
    // width: 445px;
    min-width: 200px;
    height: auto;
    padding: 15px;
    background: #FEEDD2;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    opacity: 0;
    pointer-events: none;
    transition: .3s all;
    white-space: break-spaces;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
    color: #101010;
  }
  &__list {
    margin-left: 13px;
  }
  &__list-item {
    white-space: nowrap;
  }
}
</style>