<template>
    <div id="app" :class="{'dark-mode': darkMode}">
        <app-menu
            v-if="userLogged && countries"
            :darkMode="darkMode"
            :countries="countries"
            @toggleMenu="toggleOverflow"
            @toggleDarkMode="toggleDarkMode">
        </app-menu>
        <app-header v-if="userLogged && countries" />
        <router-view v-if="!userLogged" />
        <router-view v-else-if="userLogged && countries" />
        <global-preloader v-else />
    </div>
</template>

<script>
export default {
    data() {
        return {
            // darkMode: false,
            overflow: true,
            // countries: null,
        }
    },
    computed: {
        userLogged() {
            return this.$store.getters['isLoggedIn']
            // state.userInfo.loggedIn;
        },
        darkMode() {
            return this.$store.getters['getDarkMode']
        },
        countries() {
            const branches = this.$store.getters['getBranches']
            return branches.countries
        }
    },
    async beforeCreate() {
        await this.$store.dispatch('fetchBranches')
        // const branches = this.$store.getters['getBranches']
        // this.countries = branches.countries
    },
    beforeMount() {
        if (localStorage.getItem('dark-mode') === '1') {
            // this.darkMode = true
        }
    },
    methods: {
        toggleDarkMode(status) {
            // this.darkMode = status
            this.$store.commit('setDarkMode', Number(status))
            // localStorage.setItem('dark-mode', args ? '1' : '0')
        },
        toggleOverflow(args) {
            this.overflow = args ? 'hidden' : 'auto'
            document.querySelector('html').style.overflow = this.overflow
        }
    },
}
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Mail Sans Roman';
}

.page-inner {
    &>* {
        // opacity: 0;
        // transition: 1s opacity;
    }
}

@font-face {
    font-family: 'Mail Sans Roman';
    src: url('./assets/fonts/MailSansRoman-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Mail Sans Roman';
    src: url('./assets/fonts/MailSansRoman-DemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Mail Sans Roman';
    src: url('./assets/fonts/MailSansRoman-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Mail Sans Roman';
    src: url('./assets/fonts/MailSansRoman-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Mail Sans Roman';
    src: url('./assets/fonts/MailSansRoman-Light.ttf');
    font-weight: 300;
}

// .dark-mode {
//     & *::-webkit-scrollbar {
//         // width: 5px;
//         // height: 8px;
//         background-color: #aaa; /* or add it to the track */
//     }
//     // & *::-webkit-scrollbar-track {
//     //     background-color: #262424; /*Цвет скролла*/
//     // } 
//     & *::webkit-scrollbar-track-piece {
//         background-color: #262424; /*Цвет скролла*/
//     } 
//     & *::-webkit-scrollbar-thumb {
//         background-color: #1e1e1e;
//     }
//     & *::-webkit-scrollbar-button {
//         background-color: #262424;
//     }
// }


.page {
    background: #FAF6EE;
    min-height: 100vh;
    padding: 115px 30px 75px 270px;
    transition: .3s all;
    @media (max-width: 1350px) {
        // padding: 89px 16px 104px 16px;
        padding: 164px 16px 104px 16px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        flex-wrap: wrap;
    }

    &__empy {
        background-color: #ffffff;
        min-height: 350px;
        position: relative;
        box-shadow: 0px 5px 20px rgb(0 0 0 / 7%);
        border-radius: 10px;
        
        & .notification {
            font-size: 48px;            
        }
    }
}

.container {
    // max-width: 1350px;
    max-width: 1050px;
    width: 100%;
    @media (max-width: 1350px) {
        max-width: 100%;
    }
}

.page-inner {
    display: flex;
    flex-direction: column;
    gap: 30px;

    & h2 {
        margin-top: 20px;
    }
}

.page-flex-row {
    display: flex;
    gap: 30px;

    &.no-gap {
        gap: 0;
    }

    &.small-gap {
        gap: 0;
        justify-content: space-between;
        overflow: auto;
    }

    @media (max-width: 1350px) {
        flex-direction: column;
        gap: 15px;
        &.small-gap {
            flex-direction: row;
            gap: 15px;
        }
    }
}

.page-block {
    &-small {
        max-width: 240px;
        width: 100%;
        @media (max-width: 1350px) {
            max-width: 100%;
        }
    }
}

.page-flex-column {
    display: flex;
    flex-direction: column;
    gap: 25px;

    &.no-gap {
        gap: 0;
    }

    @media (max-width: 1350px) {
        flex-direction: column;
        gap: 15px;
    }
}

.page-filter {
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: 'Mail Sans Roman';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    color: #626262;
}

.mobile {
    display: none !important;
}

@media (max-width: 1350px) {
    .mobile {
        display: flex !important;
    }
    .desktop {
        display: none !important;
    }
}

.dark-mode {
    .page {
        background: #2C2C2C;
    }

    .page__empy {
        background-color: #262424;
        transition: 0.3s background-color;
    }
}

.notification {
    font-family: 'Mail Sans Roman';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 155%;
    text-align: center;
    text-transform: uppercase;
    color: #E6E6E6;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.d-flex {
    display: flex;
}

.gap-10 {
    gap: 10px;
}

.gap-30 {
    gap: 30px;
}

.d-flex-column {
    display: flex;
    flex-direction: column;
}

.flex-grow-1 {
    flex-grow: 1;
}

.justify-content-between {
    justify-content: space-between;
}

.line-b {
    border-bottom: 1px solid #E4DFD5;
    padding-bottom: 35px;
}

.dark-mode .line-b {
    border-color: #515151;
}
</style>
