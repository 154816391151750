import donutChart from '../charts/DonutChart'
import graphChart from '../charts/GraphChart'
import speedometerChart from '../charts/SpeedometerChart'
import LineChart from '../charts/LineChart/LineChart'
import DonutChartPercentage from '../charts/DonutChartPercentage'
import BarChart from '../charts/BarChart/BarChart'
import LineChartWeather from '../charts/LineChart/LineChartWeather'

export default function charts(Vue) {
    Vue.component('donut-chart', donutChart)
    Vue.component('graph-chart', graphChart)
    Vue.component('speedometer-chart', speedometerChart)
    Vue.component('line-chart', LineChart)
    Vue.component('line-chart-weather', LineChartWeather)
    Vue.component('donut-chart-percentage', DonutChartPercentage)
    Vue.component('bar-chart', BarChart)
    Vue.component('line-chart-weather', LineChartWeather)
}
