<template>
    <div class="count">
        <div class="count__block">
            <app-title level="5" :title="title"></app-title>
            <increase-value
                v-if="increase && increase.increase"
                class="count__increase"
                :value="increase.increase"
                :success="increase.success"
                :inversion="increase.inversion"
                :tooltip="increaseTooltip">
            </increase-value>
            <!-- <div class="count__sum">{{ sum ? (counter || '') + ' ' + spacesInDigit(sum) : 'Нет данных' }}</div> -->
            <div class="count__sum">{{ sumFormatted(sum) }}</div>
            <div class="count__subsum" v-if="(typeof subsum) === 'number'">{{ subsum ? spacesInDigit(subsum) : 'Нет данных' }}</div>
            <div class="count__subsum" v-else-if="(typeof subsum) === 'string'">{{ subsum }}</div>
        </div>
        <div class="count__legend">
            <!-- <div class="count__legend-item">
                <div :style="{background: '#E83D46'}" class="count__legend-color"></div>
                <span>- Факт</span>
            </div>
            <div class="count__legend-item">
                <div :style="{background: '#DADADA'}" class="count__legend-color"></div>
                <span v-if="periodIndex">- План</span>
                <span v-else>- Like for like</span>
            </div> -->
            <div class="count__legend-item" v-for="(item, index) of legend">
                <div :style="{background: legendColors[index]}" class="count__legend-color"></div>
                <span>- {{ item }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import spacesInDigit from '@/assets/script/spacesInDigit'
export default {
    name: 'count-block',
    // props: ['title', 'increase', 'sum', 'subsum', 'periodIndex', 'counter'],
    props: {
        title: { type: String, requried: false, default: null },
        sum: { type: Number, requried: true, default: null },
        subsum: { type: [Number, String], requried: false, default: null },
        increase: { type: Object, requried: true, default: null },
        increaseTooltip: { type: String, requried: false, default: '' },
        periodIndex: { type: Number, requried: false, default: 0 },
        counter: { type: String, requried: false, default: '' },
        legend: {
            type: Array,
            requried: false,
            default: function () {
                return ['Факт', 'Like for like']
            }
        },
    },
    data() {
        return {
            legendColors: ['#E83D46', '#DADADA', '#F9A620', '#005FA7', '#FD2D91', '#00BFB9']
        }
    },
    methods: {
        spacesInDigit,
        sumFormatted(sum) {
            if (sum === false) return 'Нет данных'

            if (this.counter === '$') {
                return `${this.counter} ` + spacesInDigit(sum)
            }
            else {
                return spacesInDigit(sum) + ` ${this.counter}`
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.count {
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 5px 0px 0px 5px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 350px;
    transition: .3s all;
    /* max-width: 780px; */
    @media (max-width: 1350px) {
        width: 100%;
        min-width: 200px;
    }
    /* height: min-content; */
    &__legend {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &__legend-item {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: -0.04em;
        color: #626262;
        display: flex;
        gap: 5px;
        transition: .3s all;
    }
    &__legend-color {
        border-radius: 100px;
        width: 11px;
        height: 11px;
    }
    &__increase {
        margin-top: 11px;
    }
    &__sum {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 600;
        // font-size: 38px;
        font-size: 26px;
        line-height: 155%;
        color: #262424;
        transition: .3s all;
    }
    &__subsum {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 155%;
        color: #A7A7A7;
        margin-bottom: 15px;
    }
}

.dark-mode {
    .count {
        background: #262424;
        &__legend-item {
            color: #A7A7A7;
        }
        &__sum {
            color: #fff;
        }
    }
}
</style>
