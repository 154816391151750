<template>
    <div class="repeat-order">
        <div class="repeat-order__left-inform">
            <div class="repeat-order__title">Процент повторного  заказа</div>
            <div class="repeat-order__descr">Процент повторного заказа показывает процент ваших текущих заказов от клиентов,  которые заказывали раньше.</div>
            <div class="repeat-order__orders">
                Повторные заказы: <span>{{ orders ? orders : 'Нет данных' }}</span>
            </div>
        </div>
        <div class="repeat-order__canvas-container">
            <canvas ref="percent"></canvas>
            <div :class="[ 'repeat-order__canvas-inner', { 'no-data': !percent } ]">
                {{ percent ? percent + '%' : 'Нет данных' }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'repeat-order-block',
    props: ['percent', 'orders'],
    data() {
        return {
            isMounted: false
        }
    },
    computed: {
        canvas() {
            if (!this.isMounted) return;
            return this.$refs.percent;
        },
        angle() {
            return 2 * Math.PI * this.percent / 100;
        }
    },
    mounted() {
        this.isMounted = true;
        this.initCanvas()
    },
    methods: {
        drawPieSlice(ctx,centerX, centerY, radius, startAngle, endAngle, color){           
            function draw(radius, color = '#ffffff') {
                if (Array.isArray(color)) {
                    const grd = ctx.createConicGradient(
                        startAngle,
                        centerX, 
                        centerY);
                        grd.addColorStop(0, color[0]);
                        grd.addColorStop(0.5, color[1]);
                        grd.addColorStop(1, color[0]);
                        ctx.fillStyle = grd;
                    } else {
                        ctx.fillStyle = color;
                    }
                    ctx.beginPath();
                    ctx.moveTo(centerX,centerY);
                    ctx.arc(centerX, centerY, radius, startAngle, endAngle);
                    ctx.closePath();
                    ctx.fill();
            }
            draw(radius, color)
        },
        initCanvas() {
            this.canvas.width = 220;
            this.canvas.height = 220;
            this.drawPieSlice(
                this.canvas.getContext('2d'),
                this.canvas.width / 2,
                this.canvas.height / 2,
                Math.min(this.canvas.width/2, this.canvas.height/2) - 10,
                0,
                2 * Math.PI,
                '#F6E2E3'
            )
            if (!this.orders) return
            this.drawPieSlice(
                this.canvas.getContext('2d'),
                this.canvas.width / 2,
                this.canvas.height / 2,
                Math.min(this.canvas.width/2, this.canvas.height/2),
                -45,
                this.angle - 45,
                ['#DD9622', '#F9A620']
            )
            this.drawPieSlice(
                this.canvas.getContext('2d'),
                this.canvas.width / 2,
                this.canvas.height / 2,
                (Math.min(this.canvas.width/2, this.canvas.height/2) - 10) * .8,
                0,
                2 * Math.PI,
                '#ffffff'
            )
        }
    },
}
</script>

<style lang="scss" scoped>
.repeat-order {
    padding: 17px 10px 17px 20px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    height: min-content;
    display: flex;
    gap: 20px;
    width: fit-content;
    transition: .3s all;
    flex-wrap: wrap;
    &__canvas-container {
        position: relative;
        height: 220px;
    }
    &__canvas-inner {
        position: absolute;
        width: 160px;
        height: 160px;
        border-radius: 100%;
        background: #fff;
        transition: .3s all;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 120%;
        color: #262424;
    }
    &__canvas-inner {
        &.no-data {
            line-height: 100%;
            text-align: center;
            font-size: 33px;
        }
    }
    & canvas {
        width: 220px;
        height: 220px;
    }
    &__left-inform {
        max-width: 240px;
    }
    &__title {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 101.5%;
        color: #101010;
        transition: .3s all;
    }
    &__descr {
        margin-top: 13px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 135%;
        color: #A7A7A7;
    }
    &__orders {
        margin-top: 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        color: #101010;
        transition: .3s all;
        & span {
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.dark-mode {
    .repeat-order {
        background: #262626;
        &__canvas-inner {
            background: #262626;
            color: #fff;
        }
        &__title {
            color: #fff;
        }
        &__orders {
            color: #fff;
        }
    }
}
</style>