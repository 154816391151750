<template>
    <div :class="node.children ? 'structure-table__block' : 'structure-table__row'">
        <template v-if="node.children">
            <div class="structure-table__row structure-table__expand structure-table__expand_active">
                <div :class="['structure-table__cell', { 'structure-table__cell_bold': node.bold }]" :style="{'padding-left': padding + 'px'}">
                    {{ node.data[0] }}
                    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.45905 0.5L10 5L0.918107 5L5.45905 0.5Z" fill="black"/>
                    </svg>
                </div>
                <div class="structure-table__cell"></div>
                <div class="structure-table__cell"></div>
            </div>
            <structure-table-block
                class="structure-table__block"
                :padding="padding + 20"
                v-for="child in node.children"
                :key="returnKey(child)" 
                :node="child">
            </structure-table-block>
        </template>
        <div
            v-else
            v-for="(item, i) in node.data" 
            :key="returnKey(item)" 
            :class="['structure-table__cell', { 'structure-table__cell_bold': node.bold }]" 
            :style="{'padding-left': i === 0 ? padding + 'px' : ''}" 
        >
            {{ item }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'structure-table-block',
    props: ['node', 'padding'],
    methods: {
        returnKey() {
            return Math.random()
        }
    },
    mounted() {
        
    },
}
</script>

<style lang="scss" scoped>

</style>