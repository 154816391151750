<template>
    <component
        :is="`h${level}`"
        :class="{'line': line, 'border': topBorder}">
        <span>{{ newTitle }}</span>
        <div class="line__block" v-if="line"></div>
        <h5 class="subtitle" v-if="subtitle">{{ subtitle }}</h5>
    </component>
</template>

<script>
export default {
    props: {
        title: {},
        level: {
            default: '1'
        },
        line: {
            default: false
        },
        counter: {},
        nbsp: true,
        topBorder: false,
        subtitle: { type: String, requied: false }
    },
    name: 'appTitle',
    computed: {
        newTitle() {
            if (this.$screen.width > 1350 && this.nbsp)
                return this.title.replace(/ /g, '\u00a0')
            else
                return this.title
        }
    }
}
</script>

<style lang="scss" scoped>
h1, h2, h3, h4, h5, h6 {
    transition: .3s all;
    position: relative;
    font-family: 'Mail Sans Roman';
    font-style: normal;
    line-height: 100%;
}
h1, h2 {
    font-weight: 600;
    font-size: 32px;
    color: #262424;
    @media (max-width: 1350px) {
        margin-bottom: 10px;
    }
}
h4 {
    font-weight: 500;
    font-size: 16px;
    color: #626262;
}
h5 {
    font-weight: 500;
    font-size: 14px;
    color: #A7A7A7;
    line-height: 1.3;
    margin: 3px 0;
    @media (max-width: 1350px) {
        /* font-size: 12px; */
    }
}
h6 {
    font-weight: 400;
    font-size: 14px;
    color: #A7A7A7;
}
.line {
    display: inline-flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    justify-content: flex-start;
    flex: auto;
    overflow: hidden;
    &__block {
        width: 100%;
        display: inline-block;
        background: #E4DFD5;
        transition: .3s all;
        height: 1px;
        position: relative;
        margin-right: calc(-100% - 15px) ;
    }
}

.border {
    border-top: 1px solid #E4DFD5;
    padding-top: 20px;
}

.dark-mode {
    h1, h2, h3, h4 {
        color: #fff;
    }
    h5 {
        color: #626262;
    }
    h6 {
        color: #626262;
    }
    .line {
        &__block {
            background: #515151;
        }
    }
}
</style>
