import Vue from 'vue'
import VueRouter from 'vue-router'
import isAuth from "@/router/middleware/isAuth";
import isGuest from "@/router/middleware/isGuest";
import middlewarePipeline from "@/router/middlewarePipeline";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        name: 'auth',
        component: () => import('../views/Auth/Auth'),
        meta: {
            middleware: [isGuest]
        }
    },
    {
        path: '/kit',
        name: 'components',
        component: () => import('../views/Components'),
    },
    {
        path: '/general',
        name: 'general',
        component: () => import('../views/general/General'),
        meta: {
            middleware: [isAuth]
        }
    },
    {
        path: '/plan-fact',
        name: 'plan-fact',
        component: () => import('../views/plan-fact/PlanFact'),
        meta: {
            middleware: [isAuth]
        }
    },
    {
        path: '/opu',
        name: 'opu',
        component: () => import('../views/opu/Opu'),
        meta: {
            middleware: [isAuth]
        }
    },
    {
        path: '/events-log',
        name: 'events-log',
        component: () => import('../views/reviews/EventsLog'),
        meta: {
            middleware: [isAuth]
        }
    },
    {
        path: '/delete-review',
        name: 'delete-review',
        component: () => import('../views/reviews/DeleteReview'),
        meta: {
            middleware: [isAuth]
        }
    },
    {
        path: '/report',
        name: 'report',
        component: () => import('../views/reviews/Report'),
        meta: {
            middleware: [isAuth]
        }
    },
    {
        path: '/deviations',
        name: 'deviations',
        component: () => import('../views/deviations/Deviations'),
        meta: {
            middleware: [isAuth]
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/analytics/Dashboard'),
        meta: {
            middleware: [isAuth]
        }
    },
    {
        path: '/top-orders',
        name: 'top-orders',
        component: () => import('../views/analytics/TopOrders'),
        meta: {
            middleware: [isAuth]
        }
    },
    {
        path: '/refusals',
        name: 'refusals',
        component: () => import('../views/analytics/Refusals'),
        meta: {
            middleware: [isAuth]
        }
    },
    {
        path: '/weather-conditions',
        name: 'weather-conditions',
        component: () => import('../views/analytics/WeatherConditions'),
        meta: {
            middleware: [isAuth]
        }
    },
    {
        path: '/client-base',
        name: 'client-base',
        component: () => import('../views/analytics/ClientBase'),
        meta: {
            middleware: [isAuth]
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next,
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

export default router
