<template>
    <div class="structure-table" @click="toggleExpand($event)">
        <div class="structure-table__table">
            <structure-table-block
                :padding="10" 
                :class="{'structure-table__header': true}" 
                :node="{ data: header }" 
                :key="returnKey()">
            </structure-table-block>
            <div class="structure-table__body" v-if="branchUnselected">
                <p class="structure-table__notification notification">Выберите филиал</p>
            </div>
            <div class="structure-table__body" v-else-if="emptyData">
                <p class="structure-table__notification notification">Нет данных</p>
            </div>
            <div class="structure-table__body" v-else>
                <structure-table-block
                    v-for="(item, i) in data" 
                    :node="data[i]" 
                    :key="returnKey(item)"
                    :padding="10">
                </structure-table-block>
            </div>
        </div>
    </div>
</template>

<script>
import StructureTableBlock from './StructureTableBlock.vue';
export default {
    name: 'structure-table',
    components: {StructureTableBlock},
    props: {
        data: { type: Array, required: true },
        currency: { type: String, required: true },
    },
    data() {
        return {
            // data: [
            //     // {
            //     //     data: ['Прибыль от основной деятельности', '% / Выручка', 'Итого, сум']
            //     // },
            //     {
            //         data: ['Выручка', '', ''],
            //         children: [
            //             {
            //                 data: ['Торговая выручка', '', ''],
            //                 children: [
            //                     {
            //                         data: ['Предоставленные скидки', '7,16', '31 639 200,00']
            //                     },
            //                     {
            //                         data: ['Торговая выручка без учета скидок', '92,84', '410 407 800,00'],
            //                     }
            //                 ]
            //             },
            //             {
            //                 data: ['Итого Торговая выручка', '100', '442 047 000,00']
            //             }
            //         ]
            //     },
            //     {
            //         data: ['Итого Выручка', '100', '442 047 000,00']
            //     },
            //     {
            //         data: ['', '', '']
            //     },
            //     {
            //         data: ['Себестоимость'],
            //         children: [
            //             {
            //                 data: ['Брак', '0,14', '613 176,00']
            //             },
            //             {
            //                 data: ['Порча', '0,61', ' 2 690 277,27']
            //             },
            //             {
            //                 data: ['Проработка', '0', '10 829,09']
            //             },
            //             {
            //                 data: ['Списание на виновных лиц', '0,01', '53 323,00']
            //             },
            //             {
            //                 data: ['Пиар', '0,3', '1 309 456,36']
            //             },
            //             {
            //                 data: ['Расход продуктов', '26,12', '115 479 593,26']
            //             },
            //             {
            //                 data: ['Удаление блюд со списанием', '0', '14 483,09']
            //             },
            //             {
            //                 data: ['Недостача инвентаризации', '0,52', '1 309 456,36']
            //             },
            //             {
            //                 data: ['Излишки инвентаризации', '-0,99', '115 479 593,26']
            //             }
            //         ]
            //     },
            //     {
            //         data: ['Итого Себестоимость', '26,71', '118 073 657,86']
            //     }
            // ]
        }
    },
    created() {
    },
    computed: {
        header() {
            return ['Прибыль от основной деятельности', '% / Выручка', 'Итого, ' + this.currencySimbol]
        },
        currencySimbol() {
            const currencySimbol = this.$store.getters['getCurrencySimbol']
            const currencyCode = this.$store.getters['getCurrencyCode']
            return currencySimbol ? currencySimbol : currencyCode
        },
        emptyData() {
            return this.data === null || this.data === undefined
        },
        branchUnselected() {
            const selectedBranch = this.$store.getters['getBranchId']
            return selectedBranch === null
        },
    },
    methods: {
        toggleExpand(e) {
            if (!e.target.closest('.structure-table__expand')) return;

            e.target.closest('.structure-table__expand').classList.toggle('structure-table__expand_active')
        },
        returnKey() {
            return Math.random()
        }
    },
}
</script>

<style lang="scss">
.structure-table {
    width: 100%;
    max-width: 780px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    border-collapse: collapse;
    transition: .3s background-color;
    overflow: auto;

    &__body {
        &:has(.notification) {
            position: relative;
            min-height: 315px;
        }
    }
    
    &__notification {
        font-size: 48px;
    }

    & .structure-table__table {
        width: 780px;
        background: #FFFFFF;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
        border-collapse: collapse;
        transition: .3s background-color;
        overflow: auto;
    }
    .structure-table__row {
        display: grid;
        grid-template-columns: 3.7fr 1fr 1fr;
        height: auto;
        border-bottom: 1px solid rgba(0,0,0,.1);
        transition: .3s border-color;
        &:last-child {
            border: none;
        }
    }
    & .structure-table__cell {
        padding: 8px 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 155%;
        color: #101010;
        height: 35px;
        border-left: 1px solid rgba(0,0,0,.1);
        transition: .3s color border-color;
        &:first-child {
            border: none;
            padding-right: 10px;
        }
        &:nth-child(n + 2) {
            text-align: center;
        }
    }
    & .structure-table__cell_bold {
        font-weight: 700;
    }
    & .structure-table__header {
        & .structure-table__cell {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 110%;
            color: #262424;
            padding: 20px;
            height: 50px;
            transition: .3s color;
        }
    }
    & .structure-table__row.structure-table__expand ~ .structure-table__block {
        transform: scaleY(0);
        height: 0;
    }
    & .structure-table__row.structure-table__expand {
        background: rgba(249, 166, 32, .1);
        transition: .3s background-color;
    }
    & .structure-table__row.structure-table__expand .structure-table__cell:first-child {
        cursor: pointer;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & svg {
            transition: .3s all;
            & path {
                transition: .3s all;
            }
        }
    }
    & .structure-table__row.structure-table__expand.structure-table__expand_active .structure-table__cell:first-child {
        & svg {
            transform: rotate(180deg);
        }
    }
    & .structure-table__row.structure-table__expand.structure-table__expand_active {
        & ~ .structure-table__block {
            transform: none;
            height: auto;
            border-bottom: 1px solid rgba(0,0,0,.1);
            transition: .3s border-color;
        }
    }
}

.dark-mode {
    .structure-table {
        .structure-table__table {
            background: #262424;
        }
        background: #262424;
        .structure-table__row {
            border-color: rgba(255,255,255,.1);
        }
        & .structure-table__cell {
            color: #fff;
            border-color: rgba(255,255,255,.1);
        }
        & .structure-table__header {
            & .structure-table__cell {
                color: #a7a7a7;
            }
        }
        & .structure-table__row.structure-table__expand {
            background: rgba(232, 61, 70, .2);
        }
        & .structure-table__row.structure-table__expand.structure-table__expand_active {
            & ~ .structure-table__block {
                border-color: rgba(255,255,255,.1);
            }
        }
        & .structure-table__row.structure-table__expand .structure-table__cell:first-child {
            & svg {
                & path {
                    fill: #fff;
                }
            }
        }
    }
}
</style>