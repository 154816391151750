import appButton from '../buttons/AppButton'
import appMenu from '../menu/AppMenu'
import appHeader from '../header/AppHeader'
import appToggler from '../buttons/AppToggler'
import appContentToggler from '../buttons/AppContentToggler';

export default function globals(Vue) {
    Vue.component('app-button', appButton)
    Vue.component('app-header', appHeader)
    Vue.component('app-toggler', appToggler)
    Vue.component('app-menu', appMenu)
    Vue.component('app-content-toggler', appContentToggler)
}