<template>
    <div class="selector" :class="{ selector_active: modalOpened }" v-click-outside="closeModal">
        <div class="selector__inner" @click="toggleModal">
            <div class="selector__value">
                <p class="selector__title">Филиал</p>
                <p class="selector__filial">{{ selected }}</p>
            </div>
            <div class="selector__arrows">
                <div class="selector__arrow selector__arrow_top" :class="{ selector__arrow_active: modalOpened }">
                    <img class="selector__arrow_top-img" src="@/assets/icons/filial-arrow.svg" alt="Arrow">
                </div>
                <div class="selector__arrow selector__arrow_bot" :class="{ selector__arrow_active: modalOpened }">
                    <img class="selector__arrow_bot-img" src="@/assets/icons/filial-arrow.svg" alt="Arrow">
                </div>
            </div>
        </div>
        <transition name="fade">
            <div class="selector__modal" v-show="modalOpened">
                <div class="selector__modal-inner">
                    <p class="selector__filial" @click="selectAll">Все филиалы</p>
                    <div class="selector__block">
                        <div class="selector__block-header">
                            <div class="selector__title">Страны</div>
                        </div>
                        <button @click="selectCountry(country)" v-for="country in data" :key="country.name"
                            class="selector__filial">
                            {{ country.name }}
                            <img :src="country.flag" alt="">
                        </button>
                    </div>
                    <div class="selector__block" v-for="country in data" :key="country.name">
                        <div class="selector__block-header">
                            <div class="selector__title">{{ country.name }}</div>
                            <img :src="country.flag" alt="">
                        </div>
                        <button @click="selectBranch(branch, country)" v-for="branch in country.branches" class="selector__city-container">
                            <img src="@/assets/icons/pin.svg" alt="">
                            <span class="selector__city">
                                <span class="selector__filial">{{ branch.name }}</span>
                                <span class="selector__title">{{ branch.city }}</span>
                            </span>
                            <img :src="country.flag" alt="" />
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "FilialSelector",
    props: {
        data: { type: Array, required: true },
    },
    data() {
        return {
            // branches: null,
            // selected: 'Все филиалы',
            modalOpened: false,
            // countries: [
            //     {
            //         name: 'Россия',
            //         flag: require('@/assets/icons/ru.png')
            //     },
            //     {
            //         name: 'Казахстан',
            //         flag: require('@/assets/icons/kz.png')
            //     },
            //     {
            //         name: 'Узбекистан',
            //         flag: require('@/assets/icons/uz.png')
            //     },
            // ],
            // filials: [
            //     {
            //         country: 'Россия',
            //         flag: require('@/assets/icons/ru.png'),
            //         data: [
            //             {
            //                 name: 'RONI ADP',
            //                 city: 'Тюмень'
            //             }
            //         ]
            //     },
            //     {
            //         country: 'Казахстан',
            //         flag: require('@/assets/icons/kz.png'),
            //         data: [
            //             {
            //                 name: 'RONI ADP',
            //                 city: 'Нур-Султан'
            //             }
            //         ]
            //     },
            //     {
            //         country: 'Узбекистан',
            //         flag: require('@/assets/icons/uz.png'),
            //         data: [
            //             {
            //                 name: 'RONI ADP',
            //                 city: 'Нур-Султан'
            //             }
            //         ]
            //     },
            // ]
        }
    },
    methods: {
        openModal() {
            this.modalOpened = true;
        },
        closeModal() {
            this.modalOpened = false;
        },
        // select(name) {
        //     this.closeModal();
        //     this.selected = name;
        // },
        toggleModal() {
            this.modalOpened = !this.modalOpened;
        },
        selectCountry(country) {
            this.$store.commit('setCountryId', country.id)
            // this.select(country.name)
            this.closeModal()
            this.fetchData()
        },
        selectBranch(branch, country) {
            this.$store.commit('setBranchId', { branchId: branch.id, countryId: country.id })
            // this.select(branch.name)
            this.closeModal()
            this.fetchData()
        },
        selectAll() {
            this.$store.commit('setAll')
            this.closeModal()
            this.fetchData()
        },
        async fetchData() {
            const pageAPInames = this.$store.getters['getPageAPInames']
            const url = pageAPInames[this.$route.name]
            await this.$store.dispatch('fetchMainData', { url: '/page/' + url })
        },
    },
    computed: {
        selected() {
            return this.$store.getters['getSelectedName']
        }
    }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.selector {
    background: #D52F38;
    border-radius: 10px;
    width: 200px;
    min-width: 200px;
    margin: 20px auto 32px;
    cursor: pointer;
    padding: 7.5px 15px;
    position: relative;
    transition: .3s all;

    &_active {
        border-radius: 10px 10px 0 0;
    }

    &__modal {
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
        left: 0;
        width: 100%;
        background: #D52F38;
        border-radius: 0 0 10px 10px;
        padding: 0 15px 0 0;
        transition: .3s all;

        &-inner {
            max-height: 350px;
            overflow: auto;
            padding: 0 15px;
            margin: 0 0 15px 0;

            &::-webkit-scrollbar {
                width: 1px;
            }

            &::-webkit-scrollbar-track {
                width: 1px;
                background: rgba(38, 36, 36, 0.33);
            }

            &::-webkit-scrollbar-thumb {
                width: 2px;
                background: #F9A620;
            }
        }

    }

    &__city-container {
        display: flex;
        border: none;
        background: transparent;
        cursor: pointer;
        gap: 10px;
        width: 100%;
        padding: 10px 0px;

        &:not(:last-child) {
            border-bottom: 1px solid rgba(38, 36, 36, 0.33);
        }
    }

    &__city {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: auto;

        & .selector__filial {
            margin: 0;
            text-align: start;
        }
    }

    &__block {
        border-top: 1px solid rgba(38, 36, 36, 0.33);
        padding: 9px 0 10px;
        transition: .3s all;
    }

    &__filial {
        border: none;
        cursor: pointer;
        background: transparent;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 7px;
    }

    &__block-header {
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & .selector__filial {
            margin-bottom: 0;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 155%;
        color: #ECECEC;
    }

    &__filial {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 155%;
        color: #FFFFFF;
    }

    &__arrows {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        justify-content: center;
    }

    &__arrow {
        height: 24px;
        transition: .3s all;
        transform-origin: center;
        display: flex;
        align-items: self-end;

        &_bot {
            transform: rotateX(180deg);
            //transform: rotate(180deg);
        }

        &_active {
            &.selector__arrow_bot {
                //transform: scaleY(-1);
            }

            &.selector__arrow_top {
                //transform: rotate(180deg) scaleY(-1);
            }
        }
    }
}

.dark-mode {
    & .selector {
        background: #2C2C2C;

        &__modal {
            background: #2C2C2C;
        }

        &__block {
            border-color: #a7a7a7;
        }
    }
}
</style>
