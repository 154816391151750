// import { pageDataAPI } from "@/api/pageDataAPI/index";
import { DefaultAPIInstance } from "@/api";
import router from '@/router';

function reviveVariable(prop) {
  if (prop === 'null' || prop === null) return null
  if (isFinite(prop)) return Number(prop)
}

export default {
  state: () => ({
    branches: [],
    // countryId: Number(localStorage.getItem('countryId')) ?? null,
    // branchId: Number(localStorage.getItem('branchId')) ?? null,
    countryId: reviveVariable(localStorage.getItem('countryId')) ?? null,
    branchId: reviveVariable(localStorage.getItem('branchId')) ?? null,
  }),

  getters: {
    getBranches: (state) => state.branches,
    getCountries: (state) => state.branches?.countries,
    getCountryId: (state) => state.countryId,
    getBranchId: (state) => state.branchId,
    getCurrencyId: (state, getters) => {
      if (!state.countryId || state.branches.length === 0) return null
      const currentBranch = state.branches?.countries.find(item => item.id === state.countryId)
      return currentBranch.currency_id
    },
    getSelectedName: (state, getters) => {
      const countryId = getters.getCountryId
      const branchId = getters.getBranchId

      if (!countryId && !branchId) {
        return 'Все филиалы'
      }
      else if (countryId && !branchId) {
        return state.branches.countries.find(country => country.id === countryId).name
      }
      else if (countryId && branchId) {
        const country = state.branches.countries.find(country => country.id === countryId)
        return country.branches.find(branch => branch.id === branchId).name
      }
    },
  },

  mutations: {
    setBranches(state, payload) {
      state.branches = payload
    },
    setCountryId(state, id) {
      state.countryId = id
      state.branchId = null
      localStorage.setItem('countryId', id)
      localStorage.setItem('branchId', null)
    },
    setBranchId(state, { branchId, countryId }) {
      state.branchId = branchId
      state.countryId = countryId
      localStorage.setItem('branchId', branchId)
      localStorage.setItem('countryId', countryId)
    },
    setAll(state) {
      state.branchId = null
      state.countryId = null
      localStorage.setItem('branchId', null)
      localStorage.setItem('countryId', null)
    },
  },

  actions: {
    async fetchBranches({ rootState, rootGetters, commit, dispatch }) {
      const token = rootGetters.getToken

      if (!token) return

      try {
        const response = await DefaultAPIInstance.get('/menu/countries', {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });

        const data = response.data
        commit('setBranches', data)
      } catch (error) {
        if (error.message === 'Unauthenticated' || error.response?.data?.message === 'Unauthenticated.') {
          dispatch('onLogout')
          router.push('/')
        }
      }
    },
  }
}