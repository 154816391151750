import Vue from 'vue'
import Vuex from 'vuex'
import chartStore from './modules/charts';
import calendarStore from './modules/calendar';
import headerStore from './modules/header';
import mapStore from './modules/map';
import userInfo from "@/store/modules/userInfo";
import pageData from "@/store/modules/pageData";
import branches from "@/store/modules/branches";
import global from "@/store/modules/global";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    chartStore,
    calendarStore,
    headerStore,
    mapStore,
    userInfo,
    pageData,
    branches,
    global,
  }
})
