<template>
    <div class="weather">
        <app-title level="5" :title="date" class="weather__title"></app-title>
        <div class="weather__weather">
            <div class="weather__img">
                <!-- <img v-if="imgLowerCase === 'clear'" src="@/assets/icons/weather/sun.png" :alt="img">
                <img v-else-if="imgLowerCase === 'partly-cloudy'" src="@/assets/icons/weather/cloudSun.png" :alt="img">
                <img v-else-if="imgLowerCase === 'rain'" src="@/assets/icons/weather/rainy.png" :alt="img">
                <img v-else-if="imgLowerCase === 'clouds'" src="@/assets/icons/weather/cloudy.png" :alt="img">
                <img v-else-if="imgLowerCase === 'snow'" src="@/assets/icons/weather/snow.png" :alt="img">
                <img v-else-if="imgLowerCase === 'thunder'" src="@/assets/icons/weather/thunder.png" :alt="img"> -->
                <img v-if="img" :src="img" :alt="img">
                <img v-else src="@/assets/icons/weather/none.png" :alt="img">
            </div>
            <div class="weather__counter-container">
                <div class="weather__counter weather__counter_max">{{ weatherDegree.max }}</div>
                <div class="weather__counter weather__counter_min">{{ weatherDegree.min }}</div>
            </div>
        </div>
        <div class="weather__subtitle">Выручка</div>
        <div class="weather__system-forecast" v-if="forecast">
            <span>{{ systemForecast[0] }}</span>
            <span>{{ systemForecast[1] }}</span>
        </div>
        <div class="weather__system-forecast" v-else>
            Нет данных
        </div>
        <div class="weather__subtitle">Средний чек</div>
        <div class="weather__average" v-if="averageBill">
            <span>{{ average[0] }}</span>
            <span>{{ average[1] }}</span>
        </div>
        <div class="weather__average empty" v-else>
            Нет данных
        </div>
        <div class="weather__subtitle">Количество чеков</div>
        <div class="weather__count-container">
            <div class="weather__count-container-inner" v-if="countOfBills">
                <div class="weather__count">{{ spacesInDigit(countOfBills) }}</div>
                <increase-value
                    :success="increase.success"
                    :value="increase.value">
                </increase-value>
            </div>
            <div class="weather__count-container-inner" v-else>
                <p class="weather__count empty">нет данных</p>
            </div>
        </div>
    </div>
</template>

<script>
import spacesInDigit from '@/assets/script/spacesInDigit'
export default {
    name: 'weather-block',
    props: [ '', '', '', '', '', '', ''],
    props: {
        date: { type: String, required: false, default: ' ' },
        weather: { type: Object, required: false, default: function() {
            return {
                max: null,
                min: null,
            }
        }},
        forecast: { type: Number, required: false, default: null },
        averageBill: { type: Number, required: false, default: null },
        countOfBills: { type: Number, required: false, default: null },
        increase: { type: Object, required: false, default: function() {
            return {
                success: false,
                value: null,
            }
        }},
        img: { type: String, required: false, default: null },
        counter: { type: String, required: false, default: ' ' },
    },
    methods: {
        spacesInDigit,
    },
    computed: {
        weatherDegree() {
            return {
                max: this.weather.max != null ? this.weather.max + '°' : '- -',
                min: this.weather.min != null ? this.weather.min + '°' : '- -'
            }
        },
        // imgLowerCase() {
        //     return String(this.img).toLocaleLowerCase()
        // },
        systemForecast() {
            if (!this.forecast) return null

            if (this.counter === '$') {
                return [this.counter, spacesInDigit(this.forecast)]
            } else {
                return [spacesInDigit(this.forecast), this.counter]
            }
        },
        average() {
            if (!this.averageBill) return null

            if (this.counter === '$') {
                return [this.counter, spacesInDigit(this.averageBill)]
            } else {
                return [spacesInDigit(this.averageBill), this.counter]
            }
        }
    },
}
</script>

<style lang="scss" scoped>



.weather {
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 15px 20px;
    width: calc((100% / 7) - 6px);
    // height: min-content;
    transition: .3s all;


    @media (max-width: 1024px) {
      min-width: 137px;
    }
    &__title {
        font-weight: 600;
        color: #626262;
        text-align: center;
        transition: .3s all;
    }
    &__system-forecast {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 155%;
        color: #626262;
        justify-content: center;
        margin-bottom: 6px;
        transition: .3s all;
        display: flex;
        gap: 5px;

        & span {
            white-space: nowrap;
            text-align: center;
        }
    }
    &__count-container {

    }
    &__count-container-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        margin-top: 2px;
    }
    &__count {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 155%;
        color: #626262;
        transition: .3s all;
    }
    &__average {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 155%;
        color: #626262;
        margin-bottom: 15px;
        justify-content: center;
        transition: .3s all;
        display: flex;
        gap: 5px;

        &.empty {
            line-height: 105%;
        }

        & span {
            white-space: nowrap;
            text-align: center;
        }
    }
    &__subtitle {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 155%;
        text-align: center;
        color: #A7A7A7;
        transition: .3s all;
    }
    &__weather {
        display: flex;
        align-items: center;
        gap: 7px;
        margin-top: 8px;
        margin-bottom: 6px;
    }
    &__img {
        width: 60px;
        height: 60px;

        & img {
            width: 100%;
            height: 100%;
        }
    }
    &__counter {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 100%;
        text-align: center;
        &_max {
            color: #E83D46;
            transition: .3s all;
        }
        &_min {
            color: #A7A7A7;
            transition: .3s all;
        }
    }
}

.dark-mode {
    .weather {
        background: #262626;
        &__title {
            color: #a7a7a7;
        }
        &__system-forecast {
            color: #a7a7a7;
        }
        &__count {
            color: #a7a7a7;
        }
        &__average {
            color: #a7a7a7;
        }
        &__subtitle {
            color: #fff;
        }
        &__counter {
            &_max {
                color: #E83D46;
            }
            &_min {
                color: #fff;
            }
        }
    }
}
</style>
