import planTable from '../tables/PlanTable'
import structureTable from '../tables/StructureTable'
import hoverTable from '../tables/HoverTable'
import dynamicTable from '../tables/DynamicTable'
import eventLogTable from '../tables/EventLogTable'

export default function tables(Vue) {
    Vue.component('plan-table', planTable)
    Vue.component('structure-table', structureTable)
    Vue.component('hover-table', hoverTable)
    Vue.component('dynamic-table', dynamicTable)
    Vue.component('event-log-table', eventLogTable)
}