<template>
    <div class="content-toggler">
        <div 
            v-for="(item, i) in data"
            :class="['content-toggler__toggler', {'content-toggler__toggler_active': i === tabId}]" 
            :key="item"
            @click="toggle(item, i)"
        >
            {{ item }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'content-toggler',
    emits: ['toggle'],
    props: ['data', 'tabId'],
    data() {
        return {
            activeToggler: 0
        }
    },
    methods: {
        toggle(content, i) {
            this.activeToggler = i
            this.$emit('toggle', {content, index: i})
        }
    },
}
</script>

<style lang="scss" scoped>
.content-toggler {
    display: flex;
    align-items: center;
    height: 41px;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    &__toggler {
        background: #ECECEC;
        padding: 5px 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 155%;
        cursor: pointer;
        transition: .3s all;
        color: #A7A7A7;
        user-select: none;
        &:first-child {
            border-radius: 10px 0px 0px 10px;
        }
        &:last-child {
            border-radius: 0px 10px 10px 0px;
        }
        &:first-child:last-child {
            border-radius: 10px;
        }
        &_active {
            color: #626262;
            font-weight: 500;
            cursor: default;
            padding: 8px 18px;
            background: #fff;
            transition: .3s all;
            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
        }
    }
}

.dark-mode {
    .content-toggler {
        &__toggler {
            background: #262424;
            color: #A7A7A7;
            &:first-child {
                border-radius: 10px 0px 0px 10px;
            }
            &:last-child {
                border-radius: 0px 10px 10px 0px;
            }
            &_active {
                color: #fff;
                font-weight: 500;
                cursor: default;
                padding: 8px 18px;
                // background: #000;
                background: #1e1e1e;
            }
        }
    }
}
</style>