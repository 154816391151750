<template>
    <div :class="['subtitles', {'subtitles_no-gap': options.nogap}]">
        <app-title 
            v-for="title in titles" 
            :key="title" 
            :title="title" 
            level="5" 
            :line="true"
            :counter="titles.length * 2">
        </app-title>
    </div>
</template>

<script>
export default {
    name: 'subtitles',
    props: {
        titles: {
            default: [],
            required: true
        },
        options: {
            type: Object,
            default() {
                return {
                    nogap: false,
                    required: false
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.subtitles {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 25px;
    @media (max-width: 1350px) {
        margin-bottom: 0;
    }
    &_no-gap {
        margin: 0;
    }
}
</style>