import { pageDataAPI } from "@/api/pageDataAPI/index";
import formatDate from "@/assets/script/formatDate";
import router from '@/router';

export default {
  state: () => ({
    pageAPInames: {
      'general': 'main',
      'plan-fact': 'plan-fact',
      'opu': 'opu',
      'events-log': 'event-log',
      'delete-review': 'report/removals',
      'report': 'report/write-offs',
      'deviations': 'deviations',
      'dashboard': 'dashboard',
      'top-orders': 'top-orders',
      'refusals': 'failures',
      'weather-conditions': 'weather',
      'client-base': 'client-base',
    },
    mainData: null,
    currency: null,
    isLoaded: false,
  }),

  getters: {
    getMainData: (state) => state.mainData,
    getPageAPInames: (state) => state.pageAPInames,
    getLoadingStatus: (state) => state.isLoaded,
    getCurrencySimbol: (state) => {
      if (!state.currency) return ' '
      return state.currency.symbol
    },
    getCurrencyCode: (state) => {
      if (!state.currency) return ' '
      return state.currency.code
    },
  },

  mutations: {
    setMainData(state, {data, currency}) {
      state.mainData = data
      state.currency = currency
      state.isLoaded = true
    },
    setLoadingState(state, status) {
      state.isLoaded = status
    },
  },
  actions: {
    async fetchMainData({ rootState, rootGetters, commit, dispatch }, options) {
      commit('setLoadingState', false)
      const { fromFormatted, toFormatted } = formatDate(rootGetters.getTimeRange)
      const countries = rootGetters.getCountries
      const currencyId = rootGetters.getCurrencyId
      const token = rootGetters.getToken
      const periodSlug = rootGetters.getGlobalFilterSlug
      const filterIndex = rootGetters.getGlobalFilterIndex
      const isCalendarSelector = rootGetters.getCurrentSelector
      const countryId = rootGetters.getCountryId
      const branchId = rootGetters.getBranchId

      const params = {
        date: filterIndex === 5 ? periodSlug : null,
        from: filterIndex === 5 ? null : fromFormatted,
        to: filterIndex === 5 ? null : toFormatted,
      }

      // const params = {
      //   date: isCalendarSelector ? null : periodSlug,
      //   from: isCalendarSelector ? fromFormatted : null,
      //   to: isCalendarSelector ? toFormatted : null,
      // }

      let data
      let currencies

      try {
        data = await pageDataAPI.get({
          url: options.url,
          params: { 
            date: params.date,
            from: params.from,
            to: params.to,
            countryId: countryId,
            branchId: branchId,
          },
          token: token,
        })

        if (options.url === '/page/main') {
          commit('setMarkers', data?.general_summary?.map)
        }
  
        currencies = await pageDataAPI.get({
          url: '/currencies',
          params: { 
            date: params.date,
            from: params.from,
            to: params.to,
            countryId: countryId,
            branchId: branchId,
          },
          token: token,
        })
        const currency = countryId === null ? currencies[0] : currencies.find(item => item.id === currencyId)
        commit('setMainData', {data, currency})
      } catch (error) {
        if (error.message === 'Unauthenticated' || error.response?.data?.message === 'Unauthenticated.') {
          dispatch('onLogout')
          router.push('/')
        }
      }
    },
  }
}  