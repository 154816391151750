<template>
    <button @click="$emit('btn-click', $event)" class="btn">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'app-button',
    emits: ['btn-click']
}
</script>

<style lang="scss" scoped>
.btn {
    font-family: 'Mail Sans Roman';
    background: #E83D46;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    transition: .3s all;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #FFFFFF;
    width: 220px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border: none;
    &:hover {
        box-shadow: 0px 5px 20px rgba(232, 61, 70, 0.45);
    }
}
</style>