export default {
    state: {
        mapSettings: {
            apiKey: '1d1d09fc-6066-49a2-b402-d8dab7ddfdc1',
            lang: 'ru_RU',
            coordorder: 'latlong',
            enterprise: false,
            version: '2.1'
        },
        markers: null,
        markersFallback: [
            {
                coords: [41.2924305, 69.2850994],
                count: 2,
                building: true,
                built: true
            },
            {
                coords: [41.3078267, 69.2824454],
                count: 1,
                building: false,
                built: true
            },
            {
                coords: [41.2750663, 69.1564441],
                count: 1,
                building: true,
                built: false
            }
        ],
    },
    getters: {
        // getMarkers: (state) => state.markers !== null ? state.markers : state.markersFallback,
        getMarkers: (state) => state.markers,
        getOpenedBranches: (state) => {
            const obj = { count: 0, countries: [] }
            for (const marker of state.markers) {
                marker.is_build ? obj.count : obj.count++
                obj.countries.includes(marker.country) ? null : obj.countries.push(marker.country)
            }

            return obj
        },
        getBuildingBranches: (state) => {
            const obj = { count: 0, countries: [] }

            for (const marker of state.markers) {
                if (marker.is_build) {
                    obj.count++
                    obj.countries.includes(marker.country) ? null : obj.countries.push(marker.country)
                }
            }

            return obj
        },
    },
    actions: {

    },
    mutations: {
        setMarkers(state, markers) {
            if (markers) state.markers = markers
        },
    }
}
