import percentBlock from '../blocks/PercentBlock'
import weatherBlock from '../blocks/WeatherBlock'
import countBlock from '../blocks/CountBlock'
import sellsBlock from '../blocks/SellsBlock'
import calendarPlanBlock from '../blocks/CalendarPlanBlock'
import repeatOrderBlock from '../blocks/RepeatOrderBlock'
import calendarBlock from '../blocks/CalendarBlock'
import mapBlock from '../blocks/MapBlock'
import openedFilialsBlock from '../blocks/OpenedFilialsBlock'
import MyDatePicker from "../blocks/MyDatePicker"
import HeatmapBlock from "../blocks/HeatmapBlock"

export default function blocks(Vue) {
    Vue.component('percent-block', percentBlock)
    Vue.component('weather-block', weatherBlock)
    Vue.component('count-block', countBlock)
    Vue.component('sells-block', sellsBlock)
    Vue.component('calendar-plan-block', calendarPlanBlock)
    Vue.component('calendar-block', calendarBlock)
    Vue.component('repeat-order-block', repeatOrderBlock)
    Vue.component('map-block', mapBlock)
    Vue.component('opened-filials-block', openedFilialsBlock)
    Vue.component('my-date-picker', MyDatePicker)
    Vue.component('heatmap-block', HeatmapBlock)
}