<template>
    <div class="table">
        <table v-if="validData">
            <tr v-for="(tr, i) in data" :key="i">
                <component
                    :class="{'use-bg': td.bg}"
                    :is="i === 0 ? 'th' : 'td'"
                    v-for="(td, i) in tr"
                    :key="td.data + i">
                    {{ td.data }}
                </component>
            </tr>
        </table>
        <table v-else class="table_dis">
            <!-- <tr>
                <th
                    :class="{'use-bg': td.bg}"
                    v-for="(td, i) in data[0]"
                    :key="td.data + i">
                    {{ td.data }}
                </th>
            </tr> -->
            <p class="table__no-data">нет данных</p>
        </table>
    </div>
</template>

<script>
export default {
    props: ['data'],
    computed: {
        validData() {
            return Array.isArray(this.data) && this.data.length > 1
        },
    }
}
</script>

<style lang="scss" scoped>
.table {
    @media (max-width: 1350px) {
        overflow-x: scroll;
    }
    @media (max-width: 796px) {
        position: relative;
        width: calc(100vw - 16px);
    }
    &_dis {
        height: 100%;
        position: relative;
        & th {
            height: 37px;
        }
        & tr {
            border-bottom: 1px solid rgba(38, 36, 36, .1);
        }
    }
    &__no-data {
        font-family: 'Mail Sans Roman';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 155%;
        text-align: center;
        text-transform: uppercase;
        color: #E6E6E6;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    & table {
        background: #FFFFFF;
        box-shadow: 0px 3.71429px 14.8571px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
        border-collapse: collapse;
        width: 100%;
        min-width: 780px;
        transition: .3s all;
        max-width: 780px;
    }
    & tr {
        width: 100%;
        &:first-of-type {
            & th, td {
                border-top: none;
            }
        }
        &:last-of-type {
            & th, td {
                border-bottom: none;
                font-weight: 600;
                color: #101010;
            }
        }
    }
    & th, td {
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 155%;
        text-align: center;
        color: #101010;
        border: 1px solid rgba(38, 36, 36, .1);
        padding: 14px 6px;
        transition: .3s all;
        &:first-child {
            border-left: none;
        }
        &:last-child {
            border-right: none;
        }
        &.use-bg {
            background: rgba(249, 166, 32, .2);
        }
    }
    & th {
        font-weight: 600;
        color: #262424;
    }
}

.dark-mode {
    .table {
        & table {
            background: #262424;
        }
        & tr {
            &:last-of-type {
                & th, td {
                    color: #A7A7A7;
                }
            }
        }
        & th, td {
            color: #A7A7A7;
            border-color: rgba(255, 255, 255, .1);
            &:nth-child(3), &:nth-child(8) {
                background: rgba(232, 61, 70, .2);
            }
        }
        & th {
            color: #FFFFFF;
        }
    }
}
</style>
