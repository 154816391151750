import axios from "axios"

const loginConfig = {
  // baseURL: 'https://api.roni-info.2dit-dev.ru/api/auth',
  baseURL: 'https://api365.roni.pizza/api/auth',
}

export const LoginAPIInstance = axios.create(loginConfig)

const defaultConfig = {
  // baseURL: 'https://api.roni-info.2dit-dev.ru/api',
  baseURL: 'https://api365.roni.pizza/api',
}

export const DefaultAPIInstance = axios.create(defaultConfig)