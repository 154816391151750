<template>
    <div class="calendar-plan" v-if="validData">
        <div class="calendar-plan__month" v-if="monthToText">{{ monthToText }}</div>
        <div class="calendar-plan__month" v-else>нет данных</div>
        <div class="calendar-plan__row">
            <div v-for="item in weekDays" :key="item" class="calendar-plan__weekday calendar-plan__cell">
                {{ item }}
            </div>
        </div>
        <div class="calendar-plan__days">
            <!-- <div
                class="calendar-plan__cell calendar-plan__day"
                v-for="(day, i) in computedDays"
                :key="day"
                :style="{background: backgroundColor(day)}">
                {{ day ? i + 1 - startWeekDay : null }}
            </div> -->
            <div
                class="calendar-plan__cell calendar-plan__day"
                v-for="(item, i) in computedDays"
                :key="i"
                :style="{ background: backgroundColor(item), borderColor: 'rgba(0, 0, 0, 0.05)', color: defineColor(item) }"
            >
                {{ item !== null ? item.day : null }}
            </div>
            <!--  -->
        </div>
        <div class="calendar-plan__legend">
            <ul class="calendar-plan__legend-list legend-list">
                <li class="legend-list__item">
                    <i class="legend-list__item-marker green"></i>
                    <span class="legend-list__item-text">- План выполнен</span>
                </li>
                <li class="legend-list__item">
                    <i class="legend-list__item-marker red"></i>
                    <span class="legend-list__item-text">- План не выполнен</span>
                </li>
            </ul>
        </div>
    </div>
    <div class="calendar-plan" v-else>
        <p class="calendar-plan__no-data">нет данных</p>
    </div>
</template>

<script>
export default {
    name: 'calendar-plan-block',
    props: ['month', 'data', 'plan', 'calendarData'],
    data() {
        return {

        }
    },
    computed: {
        validData() {
            // return (Array.isArray(this.data) && this.data.length > 1) || (this.month !== null && isFinite(this.month) && (this.month >= 0 && this.month <= 11)) || isFinite(this.plan)
            return (Array.isArray(this.calendarData?.days) && this.calendarData?.days.length > 1)
        },
        startWeekDay() {
            const months = this.$store.state.calendarStore.months
            const monthText = this.calendarData.month.toLowerCase()
            const monthIndex = months.indexOf(monthText)
            // const monthDigit = monthIndex !== -1 ? monthIndex + 1 : monthIndex

            // return new Date(new Date().getFullYear(), monthDigit, 0).getDay();
            return new Date(new Date().getFullYear(), monthIndex).getDay()
        },
        computedDays() {
            const arr = [];
            for (let i = 1; i < this.startWeekDay; i++) {
                arr.push(null)
            }
            // arr.push(...this.data)
            arr.push(...this.calendarData?.days)
            return arr
        },
        months() {
            return this.$store.state.calendarStore.months
        },
        weekDays() {
            return this.$store.state.calendarStore.weekDays
        },
        monthToText() {
            // return this.months[this.month]
            return this.calendarData.month || null
        },
        darkMode() {
            return this.$store.getters['getDarkMode']
        },
        theme() {
            return this.darkMode ? 'dark' : 'light'
        },
    },
    methods: {
        // backgroundColor(value) {
        //     if (value === null) {
        //         return
        //     }
        //     const color = value - this.plan >= 0 ? '#51C83E' : '#C83E47';
        //     let opacity = 1;
        //     return color;
        // },
        backgroundColor(item) {
            if (item === null) return null
            const color = item.plan_complete == true ? '81, 200, 62' : '200, 62, 71'
            console.log(item,"item");
            console.log(color,"color");
            let hue = item.difference / 100 * 6;
            if(item.plan_complete == false){
              hue = item.difference / 100 * 5;
            }
            if(item.difference == null){
               hue = 1;
            }
            return `rgba(${color}, ${hue})`
        },
        defineColor(item) {
            if (!item) return null
            return this.darkMode && item.plan_complete === null ? '#a7a7a7' : null
        }
    },
}
</script>

<style lang="scss" scoped>
.calendar-plan {
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 0 0 16px 0;
    width: 240px;
    transition: .3s all;
    // min-height: 378px;
    min-height: 275px;

    &:has(.calendar-plan__no-data) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__no-data {
        font-family: "Mail Sans Roman";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 155%;
        text-align: center;
        text-transform: uppercase;
        color: #E6E6E6;
    }

    &__month {
        text-align: center;
        padding: 7px;
        border-bottom: 1px solid rgba(0,0,0,.05);
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
        color: #626262;
        transition: .3s all;
    }
    &__row {
        display: flex;
    }
    &__cell {
        width: 100%;
        height: 35px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #262424;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(0,0,0,.05);
        transition: .3s all;
    }
    &__weekday {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
        color: #a7a7a7;
    }
    &__days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: 1fr;
        grid-auto-rows: auto;
    }
    &__day {
        color: #262424;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__legend {
        padding: 16px 20px 0px;
    }
    &__legend-list {
    }
}

.dark-mode {
    .calendar-plan {
        background: #262626;
        &__month {
            color: #a7a7a7;
            border-color: rgba(167, 167, 167, .1);
        }
        &__cell {
            border-color: rgba(167, 167, 167, .1);
        }
    }
}

.legend-list {
    &__item {
        list-style: none;
    }

    &__item-marker {
        display: inline-block;
        border-radius: 100%;
        width: 11px;
        height: 11px;
        margin-right: 7px;

        &.green {
            background-color: #51C83E;
        }
        &.red {
            background-color: #E83D46;
        }
    }

    &__item-text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 155%;
        letter-spacing: -0.04em;
        color: #A7A7A7;
    }
}
</style>
