<template>
  <div :class="[ 'preloader', { dark: darkMode } ]">
    <div class="preloader__inner">
      <div class="preloader__img">
        <img :src="require(`@/assets/images/${img}`)" alt="">
      </div>
      <div class="preloader__text" style="font-family:'Organetto'">ЗАГРУЗКА...</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // img: '../../assets/images/preloader_basic.svg',
      // img: 'preloader_basic.svg',
    }
  },
  computed: {
    darkMode() {
      return this.$store.getters['getDarkMode']
    },
    theme() {
      return this.darkMode ? 'dark' : 'light'
    },
    img () {
      return this.theme === 'dark' ? 'preloader_white.svg' : 'preloader_basic.svg'
    }
  },
}
</script>
<style lang='scss' scoped>
.preloader {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 50000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FAF6EE;

  &.dark {
    background-color: #2C2C2C;
  }

  &__inner {
    max-width: 300px;
  }

  &__img {
    max-width: 206px;
    // height: 206px;
    aspect-ratio: 1 / 1;
  }

  &__text {
    font-family: 'Organetto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    color: #E83D46;
    position: absolute;
    left: 50%;
    bottom: 8%;
    transform: translateX(-50%);
  }
}
</style>