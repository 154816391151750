<template>
  <div class="chart" v-if="isEmpty">
    <BarChartMobileBlock
      v-for="item of 1"
      :item="null" 
      :legendColors="legendColors" 
      :barColors="barColors"
      :counter="counter"
      :theme="theme"
    />
  </div>
  <div class="chart" v-else>
    <BarChartMobileBlock
      v-for="item of data"
      :item="item" 
      :legendColors="legendColors" 
      :barColors="barColors"
      :counter="counter"
      :theme="theme"
    />
  </div>
</template>
<script>
import BarChartMobileBlock from '@/components/charts/BarChart/BarChartMobileBlock.vue'

export default {
  name: 'BarChartMobile',
  components: { BarChartMobileBlock },
  props: {
    data: { type: Array, requried: false, default: null },
    counter: { type: String, requried: false, default: '' },
    legendColors: { type: Array, requried: true, default: null },
    barColors: { type: Array, requried: true, default: null },
    isEmpty: { type: Boolean, requried: true, default: false },
    theme: { 
      type: String, 
      required: false, 
      default: 'light',
      validator: function (value) {
        return ['light', 'dark'].includes(value)
      }
    },
  },
  data() {
    return {
    }
  },

}
</script>
<style lang='scss' scoped>
  .chart {
    & > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }
</style>